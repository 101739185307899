<template>
    <table>
        <colgroup>
            <col/>
            <col/>
        </colgroup>
        
        <tr>
            <th colspan="2">이용약관</th>
        </tr>
        <tr>
            <td>
                <div class="flex">
                    <span>서비스 이용 약관 동의</span>
                </div>
            </td>
            <td>
                <div class="flex">
                    <CustomButton
                        :title="'자세히보기'"
                        :addClass="'checked box-sm'"
                        @click="() => openServiceTab()"
                        />

                    <div class="td-item item-checkbox">
                        <CustomCheckbox
                            :isChecked="isAgreeServiceTerms"
                            :onChange="onChangeService"
                        />
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div class="flex">
                    <span>개인정보 처리방침 동의</span>
                </div>
            </td>
            <td>
                <div class="flex">
                    <CustomButton
                        :title="'자세히보기'"
                        :addClass="'checked box-sm'"
                        @click="() => openPersonalTab()"
                        />

                    <div class="td-item item-checkbox">
                        <CustomCheckbox
                            :isChecked="isAgreePersonalTerms"
                            :onChange="onChangePersonal"
                        />
                    </div>
                </div>
            </td>
        </tr>
    </table>
</template>

<script>
import CustomButton from './CustomButton.vue';
import CustomCheckbox from './CustomCheckbox.vue';

export default {
    name: 'SignUpTerms',
    props: {
        isAgreeServiceTerms: Boolean,
        onChangeService: Function,
        isAgreePersonalTerms: Boolean,
        onChangePersonal: Function,
    },
    components: { CustomCheckbox, CustomButton },
    methods: {
        openServiceTab() {
            const routeData = this.$router.resolve({name: 'service-terms'});
            window.open(routeData.href, '_blank');
        },
        openPersonalTab() {
            const routeData = this.$router.resolve({name: 'personal-terms'});
            window.open(routeData.href, '_blank');
        },
    },
}
</script>

<style lang="scss" scoped>
table {
    margin-top: 66px;
    width: 100%;
    max-width: 650px;
    colgroup {
        col {
            &:nth-child(1) {
                width: 180px;
            }
        }
    }
    th {
        font-weight: 800;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #008ED5;
        padding: 15px 0;
        text-align: start;
    }
    td {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        text-align: start;
        padding: 10px 0;
        .flex {
            display: flex;
            gap: 30px;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}

@media (max-width: 500px) {
    table {
        colgroup {
            col {
                &:nth-child(1) {
                    width: 110px;
                }
            }
        }
        th {
            font-weight: 800;
            font-size: 13px;
            line-height: 1.2;
            text-align: center;
            color: #008ED5;
            padding: 15px 0;
            text-align: start;
        }
        td {
            font-weight: 700;
            font-size: 10px;
            line-height: 1.2;
            padding: 5px 0;
            .flex {
                gap: 10px;
            }
        }
    }
}
</style>