import axios from 'axios'
import api from "./api"

const AUTH_URL = `${process.env.VUE_APP_IS_LOCAL === 'true' ? process.env.VUE_APP_LOCAL_SERVER_URL : process.env.VUE_APP_RELEASE_SERVER_URL}/api/auth/`

class AuthService {
  signIn(params) {
    const {type, u_id} = params
    const isSnsLogin = type && u_id
    const requestUrl = isSnsLogin ? AUTH_URL + 'login/sns/' : AUTH_URL + 'login/'
    console.log(`requestUrl: ${requestUrl}`);

    return axios
      .post(requestUrl, params, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token)
        }
        if (response.data.refresh_token) {
          localStorage.setItem('refresh_token', response.data.refresh_token)
        }
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.user))
        }

        return response?.data?.user ?? null
      })
  }

  signout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  }

  signup(payload) {
    return api.post('/view_set/user/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  requestCheckEmail(email) {
    return api.post(`/auth/check_email/`, {
      'email': email,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  changePassword(password, newPassword) {
    return api.post(`/auth/change_password/`, {
      'password': password,
      'new_password': newPassword,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  requestCheckPhone(phone) {
    return api.post(`/auth/check_phone/`, {
      'phone': phone,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  findEmail({
    name,
    phone,
  }) {
    return api.post(`/accounts/change/email/find_email`, {
      'name': name,
      'phone': phone,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  verifyEmail({
    email,
  }) {
    return api.post(`/accounts/change/password/verify_email`, {
      'email': email,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  checkEmail({
    email,
  }) {
    return api.post(`/accounts/change/password/email_check`, {
      'email': email,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  changePasswordFromFindPasswordView({
    email,
    code,
    password,
  }) {
    return api.post(`/accounts/change/password`, {
      'email': email,
      'code': code,
      'password': password,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  addUsersByExcel({
    academyInfoId,
    excelFile,
  }) {
    return api.post(`/auth/add_users_by_excel/`, {
      'academy_info_id': academyInfoId,
      'excel_file': excelFile,
    }, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
}

export default new AuthService()
