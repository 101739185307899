<template>
    <div class="mypage-teacher-side-bar-wrap">
        <div :class="['mypage-tab-wrap', sidebarData.tabAddClass]">
            <div 
                v-for="(tab, index) in sidebarData.tabs" 
                :key="index"
                :class="['tab-item', {'active': sidebarData.selectTabIndex == index}]"
                @click="() => { onTabClick(index) }">
                {{ tab.title }}
            </div>
        </div>
        <div class="mypage-tab-body-wrap round-top-none enable-scroll">
            <div
                v-for="(tabBody, index) in sidebarData.tabBodyList" 
                :key="index"
                :class="['side-item', {'item-expand': tabBody.isExpand}]"
                >
                <div 
                    class="si-head"
                    @click="() => { onExpandItem(index, !tabBody.isExpand) }"
                    >
                    <img class="head-arrow" src="@/assets/images/mypage-sidebar-arrow.png" alt=""/>
                    <h1 class="text-line-1">{{ tabBody.headTitle }}</h1>
                    <h2 class="text-line-1">{{ tabBody.headSubTitle }}</h2>
                </div>
                <div class="si-items">
                    <div 
                        v-for="(item, itemIndex) in tabBody.items" 
                        :key="itemIndex"
                        :class="['sii-item', item.addClass ?? '', {'is-select': item.isSelect}]"
                        @click="item.onClick"
                        >
                        <span>{{ item.value }}</span>
                        <div v-if="item.button?.isVisible"
                            :class="['sii-item-btn', item.button.addClass]"
                            onclick="event.stopPropagation()"
                            @click="item.button.onClick"
                            >
                            {{ item.button.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MypageSideBar',
    props: {
        sidebarData: Object,
        onTabClick: Function,
        onExpandItem: Function,
    },
}
</script>

<style lang="scss" scoped>
.mypage-teacher-side-bar-wrap {
    width: 100%;
    height: 100%;
    max-width: 191px;
    display: flex;
    flex-direction: column;
    .side-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        .si-head {
            width: 100%;
            display: flex;
            padding: 15px 22px 15px 12px;
            align-items: center;
            cursor: pointer;
            background: #F2F7FF;
            border-top: 1px solid #008ED5;
            border-bottom: 1px solid #008ED5;
            height: 47px;
            .head-arrow {
                width: 13px;
                object-fit: contain;
                transition: all .2s ease-in-out;
                transform: rotate(-90deg);
            }
            h1 {
                margin-left: 11px;
                font-weight: 700;
                font-size: 15px;
                line-height: 17px;
                text-align: center;
                color: #000000;
            }
            h2 {
                font-weight: 700;
                font-size: 15px;
                line-height: 17px;
                text-align: center;
                color: #AAAAAA;
                margin-left: auto;
            }
        }
        .si-items {
            width: 100%;
            display: flex;
            flex-direction: column;
            .sii-item {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #000000;
                border-bottom: 1px solid #CBDFFF;
                height: 0;
                cursor: pointer;
                transition: all .2s ease-in-out;
                overflow: hidden;
                border-bottom-width: 0px;
                padding: 0;
                &.is-select {
                    color: #008ED5;
                    font-weight: bold;
                }
                &.align-start {
                    justify-content: start;
                }
                .sii-item-btn {
                    cursor: pointer;
                    background: #FFFFFF;
                    border: 1px solid #008ED5;
                    border-radius: 5px;
                    padding: 7px 10px;
                    margin-left: auto;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 1;
                    text-align: center;
                    color: #000000;
                    &.btn-active {
                        background: #008ED5;
                        color: white;
                    }
                }
            }
        }
        &.item-expand {
            .si-head {
                .head-arrow {
                    transform: rotate(0);
                }
            }
            .si-items {
                .sii-item {
                    height: 38px;
                    border-bottom-width: 1px;
                    padding: 7px 20px;
                }
            }
        }
    }
}
</style>