<template>
  <div class="edit-step-3-wrap">
    <div class="mypage-tab-body-list">
      <div class="mypage-tab-body-wrap round-top enable-scroll">
        <div class="list-item flex-col">
          <p>{{ `${this.targetName} 태그` }}</p>
          <div class="tag-list">
            <CustomButton
                v-for="(item, index) in tagList" :key="index"
                :title="item.title"
                :isChecked="item.isChecked"
                @click="() => {this.onChangeTag(item); this.refreshNameByTag()}"
            />
          </div>
          <div class="custom-tag">
            <CustomButton
                :title="customTag.title"
                :isChecked="customTag.isChecked"
                @click="() => onChangeTag(customTag)"
            />
            <CustomInput
                :value="customTag.value"
                @input="this.onChangeCustomTag"
            />
          </div>
        </div>

        <div class="item-list-wrap mt-big">
          <div class="list-item">
            <p>{{ `${this.targetName}명` }}</p>
            <CustomInput
                :value="name"
                :onChangeValue="(value) => name = value"
            />
          </div>
          <div class="list-item">
            <p>출제자</p>
            <v-select
                class="maker-name-select"
                :loading="isLoadingMakerNames"
                :options="makerNames"
                v-model="this.makerName"
            >
              <template v-slot:no-options>해당되는 선생님이 없습니다</template>
            </v-select>
          </div>
          <div class="list-item">
            <p>{{ `하위 ${this.targetName}명` }}</p>
            <CustomInput
                :value="subName"
                :onChangeValue="(value) => subName = value"
            />
          </div>
          <div class="list-item">
            <p>학년 선택</p>
            <CustomSelect
                :hiddenName="'고등수학(상)'"
                :onChangeValue="onChangeGrade"
                :options="this.gradeList"
            />
          </div>
        </div>

        <div class="list-item mt-big flex-col">
          <p>표시정보</p>
          <div class="cb-list">
            <CustomButtonLabel
                v-for="(item, iIndex) in this.displayList" :key="iIndex"
                :title="item.title"
                :isChecked="item.isChecked"
                @click="() => item.isChecked = !item.isChecked"
                :isReverse="true"
                :isFont15="true"
            />
          </div>
        </div>
      </div>

      <div class="mypage-tab-body-wrap round-top">
        <div class="b-header">
          <h1>{{ `${this.targetName} 미리보기` }}</h1>

          <h2>{{ resultProblems.length }}문제</h2>
        </div>
        <div class="b-prev">
          <LoadingProblemPreviewFirstPage
              :logoUrl="logoUrl"
              :nameTag="selectGradeTitle"
              :name="name"
              :subName="subName"
              :create_dt="create_dt"
              :makerName="makerName"
              :problemCnt="resultProblems.length"
              :problems="resultProblems"
              :isVisibleDifficulty="displayList[0].isChecked"
              :isVisibleSimilar="displayList[1].isChecked"
              :isVisibleDate="displayList[2].isChecked"
              :isVisiblePatternName="displayList[3].isChecked"
              :type="utils.PdfType.worksheet"
          />
        </div>
        <div class="b-footer">
          <CustomButton
              :title="this.createBtnName"
              :addClass="'checked'"
              @click="this.submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from './CustomSelect.vue';
import CustomInput from './CustomInput.vue';
import CustomButtonLabel from './CustomButtonLabel.vue';
import CustomButton from './CustomButton.vue';
import ProblemPreview from './ProblemPreview.vue';
import {mapActions, mapGetters} from 'vuex'
import models from '@/models'
import utils from '../utils';
import logoUrl from '@/assets/images/logo.png'
import WorksheetService from '@/services/worksheet.service'
import WorkbookService from '@/services/workbook.service'
import vSelect from "vue-select";
import academyService from "@/services/academy.service";
import LoadingProblemPreviewFirstPage from "@/components/LoadingProblemPreviewFirstPage.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: "EditStep3",
  components: {
    LoadingDialog,
    LoadingProblemPreviewFirstPage,
    vSelect,
    CustomSelect,
    CustomInput,
    CustomButtonLabel,
    CustomButton,
    ProblemPreview,
  },
  props: {
    isWorkbook: Boolean,
    resultProblems: Array,
    editTarget: Object,
    preTarget: Object,
    wrongStudentWork: Object,
    changeSelectGrade: String,
    selectTagOnly: String,
    onLoading: Function,
  },
  watch: {
    editTarget() {
      this.initDefaultData()
    },
    preTarget() {
      this.initDefaultData()
    },
    wrongStudentWork() {
      this.initDefaultData()
    },
    resultProblems() {
      this.onChangedResultProblems();
    },
    changeSelectGrade(changeGrade) {
      if (this.gradeList.find(grade => grade.name === changeGrade)) {
        this.gradeList.forEach(grade => {
          grade.isSelected = grade.name === changeGrade;
        });
      }
    },
    selectTagOnly(selectTag) {
      if (selectTag) {
        this.tagList.forEach(tag => {
          tag.isChecked = tag.title == selectTag;
        });
        this.refreshNameByTag();
      }
    },
  },
  data() {
    return {
      name: null, // 명
      subName: null, // 하위 명
      isLoadingMakerNames: true, // 출제자 리스트 로딩 여부
      makerNames: [], // 출제자 검색 리스트
      makerName: null, // 출제자
      gradeList: models.gradeList().map((i, index) => {
        return {name: i, isSelected: index == 0}
      }), // 학년 선택
      displayList: [
        {title: '난이도', isChecked: true},
        {title: '유사문제 정보', isChecked: true},
        {title: '날짜', isChecked: true},
        {title: '유형명', isChecked: true},
        {title: '개념영상 넣기', isChecked: false},
        {title: '정답지 QR 코드 포함 여부', isChecked: true},
      ],
      tagList: models.sheetTagList().map(i => {
        const initCheckList = ['연습문제'];

        return {title: i, isChecked: initCheckList.includes(i)}
      }),
      customTag: {
        title: '직접입력',
        isChecked: false,
        value: null,
      },
      logoUrl,
      isWrong: false, // 오답 학습지 여부
      isCreating: false,
      utils,
    };
  },
  mounted() {
    this.initData()
  },
  computed: {
    targetName() {
      return this.isWorkbook ? '교재' : '학습지'
    },
    create_dt() {
      const dt = new Date()
      const year = dt.getFullYear()
      const month = (dt.getMonth() + 1).toString().padStart(2, 0)
      const date = dt.getDate().toString().padStart(2, 0)
      return `${year}.${month}.${date}`
    },
    selectGradeTitle() {
      return this.gradeList.find(i => i.isSelected)?.name ?? ''
    },
    createBtnName() {
      if (this.wrongStudentWork) {
        if (this.isWorkbook) {
          if (this.isCreating) {
            return '오답 교재 생성중';
          } else {
            return '오답 교재 만들기';
          }
        } else {
          if (this.isCreating) {
            return '오답 학습지 생성중';
          } else {
            return '오답 학습지 만들기';
          }
        }
      }

      if (this.isWorkbook) {
        if (this.isCreating) {
          return '교재 생성중';
        } else {
          return '교재 만들기';
        }
      } else {
        if (this.isCreating) {
          return '학습지 생성중';
        } else {
          return '학습지 만들기';
        }
      }
    },
    ...mapGetters('auth', ['getUser', 'isShowQr'])
  },
  methods: {
    refreshNameByTag() {
      const checkedFirstTag = this.tagList.find(i => i.title !== '전체' && i.isChecked);
      if (checkedFirstTag) {
        this.name = checkedFirstTag.title;
      }
    },
    changeSubNameByResultProblems() {
      const middlePatterns = [];
      this.resultProblems.forEach(item => {
        const middlePattern = item.middle_pattern;
        if (!!middlePattern
            && middlePattern.id
            && middlePatterns.findIndex((i) => i.id === middlePattern.id) < 0) {
          middlePatterns.push(middlePattern);
        }
      });

      if (middlePatterns.length > 0) {
        let subNameStart;
        let subNameEnd;

        middlePatterns.forEach((pattern, index) => {
          let subName = '';
          const splitStrList = pattern.name.split(' ');
          if (splitStrList.length > 0) {
            for (let index = 0; index < splitStrList.length; index++) {
              if (index > 0) {
                if (subName.length > 0) {
                  subName += ' ';
                }
                subName += splitStrList[index];
              }
            }
          }

          if (subName.length > 0) {
            if (index === 0) {
              subNameStart = subName;
            } else {
              subNameEnd = subName;
            }
          }
        });

        if (subNameStart && !subNameEnd) {
          this.subName = subNameStart;
        } else if (subNameStart && subNameEnd) {
          this.subName = `${subNameStart}~${subNameEnd}`;
        }
      }
    },
    onChangedResultProblems() {
      if (!!this.editTarget === false && !!this.preTarget === false) {
        this.changeSubNameByResultProblems();
      }
    },
    initDefaultData() {
      let targetWorksheet = null;

      if (this.editTarget) {
        targetWorksheet = this.editTarget;
      } else if (this.preTarget) {
        targetWorksheet = this.preTarget;
      } else if (this.wrongStudentWork) { // 오답 학습지 생성 시
        targetWorksheet = this.wrongStudentWork;
      }

      if (targetWorksheet == null) { return; }

      this.name = targetWorksheet.name;
      this.subName = targetWorksheet.sub_name;
      this.makerName = targetWorksheet.maker_name;

      const grade = this.isWorkbook
          ? utils.getWorkbookGradeByWorkbook(targetWorksheet)
          : utils.getWorksheetGradeByWorksheet(targetWorksheet);
      this.gradeList.forEach(i => i.isSelected = i.name == grade);

      this.displayList[0].isChecked = targetWorksheet.is_display_difficulty;
      this.displayList[1].isChecked = targetWorksheet.is_display_similar;
      this.displayList[2].isChecked = targetWorksheet.is_display_date;
      this.displayList[3].isChecked = targetWorksheet.is_display_pattern_name;
      this.displayList[4].isChecked = targetWorksheet.is_show_concept_vid;
      this.displayList[5].isChecked = targetWorksheet.is_show_qr ?? true;

      if (this.wrongStudentWork) {
        this.tagList.forEach(i => {
          i.isChecked = false;
        })

        this.customTag.isChecked = true;
        this.customTag.value = '오답학습지';

        this.isWrong = true;
      } else {
        this.tagList.forEach(i => {
          i.isChecked = targetWorksheet.tag.findIndex(tag => tag.name == i.title) > -1
        })
        const allTag = this.tagList.find(i => i.title == '전체')
        if (allTag) {
          allTag.isChecked = this.tagList.findIndex(i => i.title != '전체' && !i.isChecked) == -1
        }

        const existTagList = this.tagList.filter(i => i.title != '전체').map(i => i.title)
        const customTagValue = targetWorksheet.tag.find(i => !existTagList.includes(i.name))?.name ?? ''
        this.customTag.isChecked = customTagValue.length > 0
        this.customTag.value = customTagValue

        this.isWrong = targetWorksheet.is_wrong ?? false;
      }
    },
    onChangeGrade(value) {
      this.gradeList.forEach(i => i.isSelected = i.name == value)
    },
    initData() {
      this.name = '연습문제';
      this.subName = `하위 ${this.targetName}명`;
      this.makerName = this.getUser.name;

      academyService.getMyAcademy().then(async academyInfo => {
        const teachers = await academyService.getAcademyUsers({
          academy_info_id: academyInfo.id,
          is_teacher: true,
        });
        this.makerNames = teachers.map(i => {
          return i.name;
        });
        this.isLoadingMakerNames = false;
      });

      this.displayList[5].isChecked = this.isShowQr;
    },
    async submit() {
      try {
        const selectGrade = this.gradeList.find(i => i.isSelected)?.name
        const selectTags = this.tagList.filter(i => i.title != '전체' && i.isChecked).map(i => i.title)
        const customTagValue = this.customTag.value
        if (this.customTag.isChecked && customTagValue && customTagValue.length > 0) {
          selectTags.push(customTagValue)
        }

        if (!this.name) {
          this.$toast.error(`${this.targetName}명을 입력해주세요`)
        } else if (this.name.length > 30) {
          this.$toast.error(`${this.targetName}명은 최대 30자 이내로 입력해주세요`)
        } else if (!this.subName) {
          this.$toast.error(`하위 ${this.targetName}명을 입력해주세요`)
        } else if (this.subName.length > 30) {
          this.$toast.error(`하위 ${this.targetName}명은 최대 30자 이내로 입력해주세요`)
        } else if (!this.makerName) {
          this.$toast.error('출제자를 입력해주세요')
        } else if (this.makerName.length > 30) {
          this.$toast.error('출제자는 최대 30자 이내로 입력해주세요')
        } else if (!selectGrade) {
          this.$toast.error('학년을 선택해주세요')
        } else if (selectTags.length == 0) {
          this.$toast.error('태그를 선택해주세요')
        } else if (this.customTag.isChecked && this.customTag.value.length > 30) {
          this.$toast.error(`${this.targetName} 태그는 최대 30자 이내로 입력해주세요`)
        } else if (this.isCreating) {
          this.$toast.warning('이미 생성 중입니다\n잠시만 기다려주세요')
        } else {
          this.isCreating = true;
          this.onLoading(true);

          const isElementarySchool = models.elementaryGradeList().includes(selectGrade)
          const isMiddleSchool = models.middleGradeList().includes(selectGrade)
          const isHighSchool = models.highGradeList().includes(selectGrade)

          const schoolType = models.schoolTypeList()[isElementarySchool ? 0 : isMiddleSchool ? 1 : 2]
          let schoolYear = null
          if (isElementarySchool || isMiddleSchool || isHighSchool) {
            schoolYear = selectGrade.substr(1, selectGrade.length)
          }

          const isShowQr = this.displayList[5].isChecked;
          const payload = {
            'school_type': schoolType,
            'school_year': schoolYear,
            'subject': null,
            'name': this.name,
            'sub_name': this.subName,
            'maker_name': this.makerName,
            'tag_list': selectTags,
            'problem_id_list': this.resultProblems.map(i => i.id),
            'is_display_difficulty': this.displayList[0].isChecked,
            'is_display_similar': this.displayList[1].isChecked,
            'is_display_date': this.displayList[2].isChecked,
            'is_display_pattern_name': this.displayList[3].isChecked,
            'is_show_concept_vid': this.displayList[4].isChecked,
            'is_wrong': this.isWrong,
            'is_show_qr': isShowQr,
          };

          let res
          if (this.isWorkbook) {
            res = this.editTarget
                ? await WorkbookService.updateWorkbook(this.editTarget.id, payload)
                : await WorkbookService.createWorkbook(payload)
          } else {
            res = this.editTarget
                ? await WorksheetService.updateWorksheet(this.editTarget.id, payload)
                : await WorksheetService.createWorksheet(payload)
          }

          if (!this.editTarget) {
            await this.changeIsShowQr(isShowQr);
          }

          if (res) {
            this.$toast.success(`${this.targetName}를 만들었습니다`)
            this.$router.push({name: this.isWorkbook ? 'MypageTeacherClassPreWorkbook' : 'MypageTeacherClassPreWorksheet'})
          } else {
            this.$toast.error(`${this.targetName}를 만드는 중 오류가 발생하였습니다`)
          }

          this.onLoading(false);
          this.isCreating = false;
        }
      } catch (e) {
        console.log(e);
        this.$toast.error(`${this.targetName}를 만드는 중 오류가 발생하였습니다`)
      }
    },
    onChangeTag(item) {
      if (item.title === '전체') {
        const isChecked = item.isChecked
        this.tagList.forEach(i => i.isChecked = !isChecked)
      } else {
        item.isChecked = !item.isChecked
      }

      const allTag = this.tagList.find(i => i.title === '전체')
      if (allTag) {
        allTag.isChecked = this.tagList.findIndex(i => i.title !== '전체' && !i.isChecked) < 0
      }
    },
    onChangeCustomTag(event) {
      const value = event.target.value
      this.customTag.isChecked = !!value
      this.customTag.value = event.target.value
    },
    ...mapActions('auth', ['changeIsShowQr'])
  },
}
</script>

<style lang="scss" scoped>
.edit-step-3-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  .mypage-tab-body-list {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 26px;
    .mypage-tab-body-wrap {
      width: unset;
      &:nth-child(1) {
        padding: 48px 34px;
        .item-list-wrap {
          width: 100%;
          margin-top: 50px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          .list-item {
            margin-top: 0;
          }
        }
        .list-item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
          &.mt-big {
            margin-top: 50px;
          }
          p {
            display: flex;
            font-weight: 700;
            font-size: 17px;
            line-height: 19px;
            color: #000000;
            border-right: 2px solid #008ED5;
            width: 130px;
            align-self: stretch;
            align-items: center;
            margin-right: 30px;
          }
          .cb-list {
            width: 100%;
            display: flex;
            margin-top: 14px;
            gap: 15px 30px;
            flex-wrap: wrap;
          }
          .tag-list {
            width: 100%;
            display: flex;
            margin-top: 14px;
            gap: 10px 17px;
            flex-wrap: wrap;
            button {
              min-width: 70px;
            }
          }
          .custom-tag {
            width: 100%;
            margin-top: 20px;
            display: flex;
            gap: 10px;
            button {
              width: 70px;
            }
            input {
              flex: 1;
              max-width: 477px;
            }
          }
          &.flex-col {
            flex-direction: column;
            p {
              margin-right: 0;
              border-right: none;
            }
          }
          select {
            max-width: 162px;
            width: 100%;
          }
        }
      }
      &:nth-child(2) {
        max-width: calc(597px + 15px + 20px + 20px + 5px);
        width: 100%;
        padding: 40px 20px;
        .b-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;
          flex-wrap: wrap;
          align-items: center;
          h1 {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
          h2 {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
        .b-prev {
          width: 100%;
          display: flex;
          flex: 1;
          margin-top: 28px;
          overflow: auto;
          border: 1px solid rgba(0,142,213,.5);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;
          }
        }
        .b-footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          button {
            width: 120px;
          }
        }
      }
    }
  }
}

@media (max-width: 1550px) {
  .edit-step-3-wrap {
    .mypage-tab-body-list {
      .mypage-tab-body-wrap {
        &:nth-child(1) {
          .item-list-wrap {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}
</style>
