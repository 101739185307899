<template>
  <div class="mypage-teacher-wrap">
    <MypageList
        :selectTabIndex="this.tabIndex"
        :tabs="this.tabs"
        :onTabClick="onChangeTab"
        :filterData="this.filterData"
        :listData="this.listData"
    />
  </div>
</template>

<script>
import MypageList from '../../../../components/MypageList.vue'
import {mapGetters} from "vuex";
import ProblemService from "@/services/problem.service";
import utils from "@/utils";

export default {
  name: "MyProblemList",
  components: {
    MypageList,
  },
  data() {
    return {
      pdfSize: utils.getPdfSize(),

      tabIndex: 0,
      tabs: [
        {title: '내 문제'},
      ],
      problems: [],
    };
  },
  mounted() {
    this.onChangeTab(0);
  },
  methods: {
    onChangeTab(index) {
      this.tabIndex = index;
      this.reloadList();
    },
    async reloadList() {
      if (this.tabIndex === 0) {
        this.problems = [];

        const userId = this.getUser.id;
        const res = await ProblemService.getMyProblems({
          userId: userId,
        });
        console.log(res);
        if (res) {
          this.problems = res;
        }
      }
    },
  },
  computed: {
    listData() {
      return {
        heads: [
          {title: '학년', type: 'string', addClass: 'w-100px'},
          {title: '문제 타입', type: 'string', addClass: 'w-100px'},
          {title: '문제 풀이형', type: 'string', addClass: 'w-100px'},
          {title: '난이도', type: 'string', addClass: 'w-100px'},
          {title: '문제 이미지', type: 'image', addClass: 'flex-1'},
          {title: '배점', type: 'string', addClass: 'w-100px'},
          {title: '수정일', type: 'string', addClass: 'w-80px'},
          {title: '', type: 'etc-btn', addClass: 'w-50px'},
        ],
        trList: this.problems.map(problem => {
          return [
            {value: problem.grade},
            {value: problem.type},
            {value: problem.solution_type},
            {value: problem.difficulty},
            {value: utils.getServerMediaPath(problem.problem_img)},
            {value: `${problem.score}점`},
            {value: problem.dt},
            {
              value: '',
              customList: [
                {
                  title: '수정',
                  onClick: () => this.$router.push({ name: 'MyProblemEdit', query: {'id': problem.id} }),
                },
                {
                  title: '삭제',
                  onClick: () => {
                    // TODO:
                  },
                },
              ],
            },
          ];
        })
      }
    },
    filterData() {
      return {
        'createBtn': {isVisible: true, name: '새 문제 만들기', onCreate: () => { this.$router.push({ name: 'MyProblemEdit' }); }},
        'searchInput': {isHidden: true},
      };
    },
    ...mapGetters('auth', ['getUser'])
  },
}
</script>
