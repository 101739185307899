import {stringToInt} from './type'
import {getEmailRegex, getPhoneRegex} from './regex'
import {
    addScript,
    isWebview,
} from './element'
import {
    getServerMediaPath, 
    getDateByServer,
    getWorksheetGradeByWorksheet,
    makeProblemPreviewToPdf,
    getSimpleGrade,
    getWorkbookGradeByWorkbook,
    getSchoolTypeByGradeList,
    getSchoolYearByGradeList,
    getSubjectByGradeList,
    numberFormat,
} from './parse'
import {
    onClickWorksheetHomework,
    getPreviewUrl,
    previewLink,
    back,
    downloadUrl,
    debugLog,
} from './event'
import {
    isMobile,
    getPdfSize,
} from './calc'
import {
    PdfType,
    PdfViewType,
    myPageTeacherRouterNames,
    DownloadType,
    v2TeacherRouterItems,
    v2StudentRouterItems,
} from './enums'
import {
    getOrCreateTooltip,
    dailyStudentHoursChartTooltip,
} from './chart'

export default {
    stringToInt,
    getEmailRegex,
    getServerMediaPath,
    getDateByServer,
    addScript,
    isWebview,
    getWorksheetGradeByWorksheet,
    makeProblemPreviewToPdf,
    getSimpleGrade,
    getWorkbookGradeByWorkbook,
    getSchoolTypeByGradeList,
    getSchoolYearByGradeList,
    getSubjectByGradeList,
    onClickWorksheetHomework,
    getPreviewUrl,
    previewLink,
    back,
    downloadUrl,
    numberFormat,
    getPhoneRegex,
    isMobile,
    getPdfSize,
    PdfType,
    PdfViewType,
    myPageTeacherRouterNames,
    DownloadType,
    debugLog,
    getOrCreateTooltip,
    dailyStudentHoursChartTooltip,
    v2TeacherRouterItems,
    v2StudentRouterItems,
}
