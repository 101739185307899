<template>
  <div class="self-learning-mode-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <div class="sec-left-wrap">
            <div class="sec-tab-wrap">
              <p>범위 선택</p>
              <div class="st-divider"></div>
              <div class="select-wrap">
                <select>
                  <option selected>초등학교</option>
                </select>
                <img src="@/assets/images/arrow-right-7.png" alt="">
                <select>
                  <option selected>1-1</option>
                </select>
              </div>
            </div>
            <div class="sec-list-wrap">
              <V2TreeList
                  :nodes="this.treeNodes"
              />
            </div>
          </div>
          <div class="sec-right-wrap">
            <div class="sec-tab-list">
              <template v-for="(tabName, index) in this.tabs" :key="index">
                <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                     @click="() => this.selectTabIndex = index"
                >
                  <p>{{ tabName }}</p>
                  <div></div>
                </div>
              </template>
            </div>
            <div class="sec-body-wrap">
              <template v-for="(unitItem, index) in this.unitItems" :key="index">
                <div class="sb-item">
                  <h5>{{ unitItem.title }}</h5>
                  <div class="sbi-list-wrap">
                    <template v-for="(item, index) in unitItem.items" :key="index">
                      <button :class="['il-item', {'active': item.isSelect}]"
                              @click="() => item.isSelect = !item.isSelect"
                      >
                        <span>{{ item.name }}</span>
                      </button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="sec-button-wrap">
              <button>
                <span>작성 완료</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import V2TreeList from "@/components/V2TreeList.vue";

export default {
  name: 'StudentSelfLearningMode',
  components: {V2TreeList, V2Wrapper},
  data() {
    return {
      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,
      unitItems: [
        {
          title: '학습 프로세스',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험콘텐츠', isSelect: false},
            {name: '실감콘텐츠', isSelect: true},
          ],
        },
      ],
      treeNodes: {
        id1: {
          text: '초 1-1',
          children: ['id1_1', 'id1_2', 'id1_3'],
        },
        id1_1: {
          text: '9까지의 수',
          children: ['id1_1_1', 'id1_1_2', 'id1_1_3'],
        },
        id1_1_1: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_1_2: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_1_3: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_2: {
          text: '6부터 9까지의 수',
        },
        id1_3: {
          text: '몇째',
        },
        id2: {
          text: '여러 가지 모양',
        },
        id3: {
          text: '덧셈과 뺄셈',
        },
        id4: {
          text: '덧셈과 뺄셈',
        },
        id5: {
          text: '덧셈과 뺄셈',
        },
        id6: {
          text: '덧셈과 뺄셈',
        },
        id7: {
          text: '덧셈과 뺄셈',
        },
        id8: {
          text: '덧셈과 뺄셈',
        },
        id9: {
          text: '덧셈과 뺄셈',
        },
        id10: {
          text: '덧셈과 뺄셈',
        },
        id11: {
          text: '덧셈과 뺄셈',
        },
      },
    };
  },
}
</script>

//<style scoped lang="scss">
//.base-container {
//  gap: 15px;
//  .section-1-wrap {
//    flex: 450;
//    border-radius: 5px;
//    border: 1px solid #DBDBDB;
//    padding: 15px;
//    display: flex;
//    flex-direction: column;
//    .sec-tab-wrap {
//      width: 100%;
//      display: flex;
//      align-items: center;
//      padding: 10px;
//      gap: 20px;
//      &>p {
//        font-weight: 600;
//        font-size: 10px;
//        color: black;
//      }
//      .st-divider {
//        width: 1px;
//        height: 100%;
//        background-color: #797979;
//      }
//      .select-wrap {
//        flex: 1;
//        display: flex;
//        gap: 5px;
//        align-items: center;
//        &>select {
//          border-radius: 5px;
//          border: 1px solid #DBDBDB;
//          font-weight: 500;
//          font-size: 10px;
//          color: black;
//          padding: 5px 20px 5px 5px;
//          line-height: 1;
//          appearance: none;
//          -webkit-appearance: none;
//          background: url(~@/assets/images/select.png) no-repeat right 5px center;
//          background-size: 10px 10px;
//        }
//        &>img {
//          width: 15px;
//          height: 15px;
//          object-fit: contain;
//        }
//      }
//    }
//    .sec-list-wrap {
//      width: 100%;
//      flex: 1;
//    }
//  }
//  .section-2-wrap {
//    flex: 250;
//    border-radius: 5px;
//    border: 1px solid #DBDBDB;
//    display: flex;
//    flex-direction: column;
//    padding: 15px;
//    .sec-tab-list {
//      width: 100%;
//      border-bottom: 1px solid #DBDBDB;
//      display: flex;
//      gap: 10px;
//      .tl-item {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//        cursor: pointer;
//        &>p {
//          font-weight: 500;
//          font-size: 10px;
//          color: #797979;
//          text-align: center;
//        }
//        &>div {
//          margin-top: 10px;
//          width: 100%;
//          height: 2px;
//          background-color: transparent;
//        }
//        &.active {
//          &>p {
//            font-weight: 600;
//            color: #F8B633;
//          }
//          &>div {
//            background-color: #F8B633;
//          }
//        }
//      }
//    }
//    .sec-body-wrap {
//      width: 100%;
//      flex: 1;
//      padding: 20px 0;
//      display: flex;
//      flex-direction: column;
//      gap: 20px;
//      .sb-item {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        gap: 10px;
//        &>h5 {
//          width: 100%;
//          font-weight: 600;
//          font-size: 10px;
//          color: black;
//        }
//        .sbi-list-wrap {
//          width: 100%;
//          display: flex;
//          flex-wrap: wrap;
//          gap: 5px;
//          .il-item {
//            border: 1px solid #F9D286;
//            cursor: pointer;
//            border-radius: 30px;
//            padding: 5px 10px;
//            display: flex;
//            &>span {
//              font-weight: 500;
//              font-size: 9px;
//              color: black;
//              text-align: center;
//              line-height: 1.6;
//            }
//            &.active {
//              background-color: #F9D286;
//            }
//          }
//        }
//      }
//    }
//    .sec-button-wrap {
//      width: 100%;
//      display: flex;
//      justify-content: flex-end;
//      &>button {
//        max-width: 110px;
//        width: 100%;
//        display: flex;
//        justify-content: center;
//        border-radius: 5px;
//        background-color: #F9D286;
//        border: 1px solid #F9D286;
//        padding: 5px 10px;
//        &>span {
//          text-align: center;
//          font-weight: 500;
//          font-size: 9px;
//          line-height: 1.6;
//          color: black;
//
//        }
//      }
//    }
//  }
//}
</style>
