<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        10일 무료 체험 신청서 제출
        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <div class="body-section-title">내용 작성</div>

        <div class="body-tr">
          <div class="body-item item-one">
            <p>연락처</p>
            <CustomInput
                addClass="default-border"
                :value="phone"
                :onChangeValue="(value) => this.phone = value"
                placeholder="ex) 010-1234-5678"
            />
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item item-one item-email">
            <p>이메일</p>
            <CustomInput
                addClass="default-border"
                :value="emailFirst"
                :onChangeValue="(value) => this.emailFirst = value"
                placeholder=""
            />
            <span>@</span>
            <template v-if="isCustomEmailLast">
              <CustomInput
                  addClass="default-border"
                  :value="emailLast"
                  :onChangeValue="(value) => this.emailLast = value"
                  placeholder=""
              />
            </template>
            <template v-else>
              <CustomSelect
                  :options="emailOptions"
                  :onChangeValue="onChangeEmailLast"
              />
            </template>
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item item-one">
            <p>이름 or 학원명</p>
            <CustomInput
                addClass="default-border"
                :value="name"
                :onChangeValue="(value) => this.name = value"
                placeholder="ex) 에스덕학원"
            />
          </div>
        </div>

        <div class="body-tr">
          <div class="body-item item-one">
            <p>주소</p>
            <CustomInput
                addClass="default-border"
                :value="address"
                :onChangeValue="(value) => this.address = value"
                placeholder="주소를 입력해주세요"
            />
          </div>
        </div>
      </div>

      <div class="body-contents">
        <div class="body-section-title">언제 시작할까요 ?</div>

        <div class="body-tr">
          <div class="body-item item-one">
            <p>시작희망일자</p>
            <CustomInput
                type="date"
                addClass="default-border"
                :value="startDt"
                :onChangeValue="(value) => startDt = value"
                placeholder="YYYY.MM.DD"
            />
          </div>
        </div>
      </div>

      <div class="btn-list">
        <button  @click="onSubmit">신청하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomInput from "@/components/CustomInput.vue";
import studentWorkService from "@/services/studentWork.service";
import commonService from "@/services/common.service";
import CustomSelect from "@/components/CustomSelect.vue";

export default {
  components: {CustomSelect, CustomInput, CustomTextarea},
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    return {
      phone: null,
      emailFirst: null,
      emailLast: null,
      isCustomEmailLast: false,
      name: null,
      address: null,
      startDt: null,
    }
  },
  watch: {
    isVisible() {
      this.initData();
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.phone = null;
      this.emailFirst = null;
      this.emailLast = null;
      this.isCustomEmailLast = false;
      this.name = null;
      this.address = null;
      this.startDt = null;
    },
    async onSubmit() {
      if ((this.phone ?? '').length === 0) { this.$toast.error('연락처를 입력해주세요'); }
      else if ((this.phone ?? '').length > 15) { this.$toast.error('연락처는 최대 15자까지 입력할 수 있어요'); }
      else if ((this.email ?? '').length === 0) { this.$toast.error('이메일을 입력해주세요'); }
      else if ((this.name ?? '').length === 0) { this.$toast.error('이름/학원명을 입력해주세요'); }
      else if ((this.name ?? '').length > 30) { this.$toast.error('이름/학원명은 최대 30자까지 입력할 수 있어요'); }
      else if ((this.address ?? '').length === 0) { this.$toast.error('주소를 입력해주세요'); }
      else if ((this.address ?? '').length > 50) { this.$toast.error('주소는 최대 50자까지 입력할 수 있어요'); }
      else if ((this.startDt ?? '').length === 0) { this.$toast.error('시작 희망 일자를 입력해주세요'); }
      else {
        try {
          await commonService.createApplyFeeExp({
            'phone': this.phone,
            'email': this.email,
            'name': this.name,
            'address': this.address,
            'start_dt': this.startDt,
          });

          this.$toast.success('신청하였습니다\n기재한 연락처로 연락을 드리겠습니다.');
          this.onClose();
        } catch (e) {
          console.log(e);
          this.$toast.error('신청 중 오류가 발생하였습니다');
        }
      }
    },
    onChangeEmailLast(value) {
      if (value == '직접입력') {
        this.isCustomEmailLast = true;
      } else {
        this.emailLast = value;
      }
    },
  },
  computed: {
    email() {
      if (!this.emailFirst || !this.emailLast) {
        return null;
      } else {
        return `${this.emailFirst}@${this.emailLast}`;
      }
    },
    emailOptions() {
      return [
        'naver.com',
        'gmail.com',
        'hanmail.net',
        'nate.com',
        'kakao.com',
        '직접입력',
      ].map((e) => {
        return {
          value: e,
          name: e,
          isSelected: false,
        };
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: 750px;
    width: 100%;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0px 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 24px;
      overflow-y: auto;
      .body-section-title {
        width: 100%;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(232, 232, 232);
        color: rgb(112, 112, 112);
        text-align: start;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      .body-tr {
        width: 100%;
        display: flex;
        gap: 24px;
        padding: 0 24px;
        .body-item {
          max-width: 50%;
          width: 100%;
          display: flex;
          &.item-one {
            max-width: 100%;
          }
          &.item-email {
            align-items: center;
            span {
              margin: 0 10px;
            }
            select {
              flex: 1;
              border: 1px solid rgb(224, 224, 224);
              border-radius: 5px;
            }
          }
          p {
            display: flex;
            margin-right: 12px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            align-items: center;
            width: 100px;
            text-align: start;
            word-break: keep-all;
            b {
              font-size: 6px;
              color: rgb(255, 0, 0);
            }
          }
          input, textarea {
            flex: 1;
          }
          textarea {
            min-height: 100px;
          }
          button {
            padding: 9px 12px;
          }
          .year-list {
            flex: 1;
            display: flex;
            gap: 10px;
            select {
              height: 100%;
              flex: 1;
              opacity: 0;
              &.visible {
                opacity: 1;
              }
            }
          }
          .include-list {
            flex: 1;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .btn-list {
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
      padding: 24px;
      &>button {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
        background-color: #008ED5;
        color: white;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
  &.size-sm {
    justify-content: center;
    .popup-body {
      margin: auto 15px;
      .popup-title {
        font-size: 14px;
        line-height: 18px;
        height: 50px;
        padding: 0px 15px;
      }
      .body-contents {
        gap: 10px 0;
        padding-bottom: 15px;
        .body-section-title {
          height: 24px;
          font-size: 10px;
          line-height: 14px;
        }
        .body-tr {
          padding: 0 15px;
          gap: 15px;
          .body-item {
            flex-direction: column;
            p {
              margin-bottom: 6px;
              font-size: 12px;
              line-height: 21px;
            }
            textarea {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
      }
      .btn-list {
        padding: 15px;
        button {
          font-size: 12px;
          width: 80px;
          height: 30px;
        }
      }
    }
  }
}

@media (max-width: 790px) {
  .popup {
    .popup-body {
      margin: auto 0;
    }
  }
}

@media (max-width: 470px) {
  .popup {
    .popup-body {
      .popup-title {
        font-size: 15px;
        line-height: 1.2;
        height: auto;
        min-height: 50px;
        padding: 0 10px;
      }
      .body-contents {
        gap: 10px;
        .body-tr {
          padding: 0 10px;
          .body-item {
            p {
              font-size: 12px;
              margin-right: 5px;
            }
            input {
              font-size: 10px;
            }
            &.item-email {
              p {
                min-width: 100px;
              }
              input {
                min-width: 30px;
              }
              select {
                font-size: 10px;
                padding: 5px 15px 5px 10px;
                background-position: right 5px center;
                background-size: 7px 5px;
              }
              span {
                font-size: 10px;
                margin: 0 3px;
              }
            }
          }
        }
      }
      .btn-list {
        padding: 10px;
      }
    }
  }
}
</style>
