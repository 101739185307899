<template>
  <div id="container">
    <div class="price-info-wrap">
      <jumbotron :title="'합리적인 가격으로 학생이 필요로 하는 문제들만 모두 여기에'" :subTitle="'선생님이 원하는 문제들로만 학습지/교재를 만들어 낼 수 있습니다.'"/>

      <div class="base-container">
        <div class="price-box-list">
          <div class="box-item">
            <h1>에스덕 Basic</h1>
            <div>
              <p><strong>98,000원</strong> /월<br/>
                <strong>+</strong><br/>
                <strong>계정등록비 50,000원</strong><br/>
                계정등록비는 계약 시 1회만 납부합니다.</p>
            </div>
            <button @click="() => this.$router.push({ name: 'PriceInfoPayment', query: {'item': 'basic'} })">개설하기</button>
          </div>
          <div class="box-item">
            <!-- <h1>에스덕 1 Year</h1>
            <div>
                <p><strong>980,000원</strong> /년<br/>
Basic 12개월 대비 16% 할인된 가격<br/>
<strong>+</strong><br/>
<strong>계정등록비 50,000원</strong><br/>
계정등록비는 계약 시 1회만 납부합니다.</p>
            </div> -->
            <h1>에스덕 3 Month</h1>
            <div>
              <p><strong>255,000원</strong> /3개월<br/>
                Basic 3개월 대비 13% 할인된 가격<br/>
                <strong>+</strong><br/>
                <strong>계정등록비 50,000원</strong><br/>
                계정등록비는 계약 시 1회만 납부합니다.</p>
            </div>
            <button @click="() => this.$router.push({ name: 'PriceInfoPayment', query: {'item': '3-month'} })">개설하기</button>
          </div>
        </div>

        <div class="sector-2">
          <div class="sector-item">
            <h1>에스덕플랜 상세내용</h1>
            <p>학생계정 30명까지 추가요금 없음<br/><br/>
              에스덕의 모든 컨텐츠 무제한 사용가능<br/><br/>
              매달 컨텐츠/기능 무료제공<br/><br/>
              학생이름 각인 프린트케이스 지원</p>
          </div>
          <div class="sector-item">
            <h1>제공하는 프로그램</h1>
            <div class="item-icon-list">
              <h5>선생님</h5>
              <img src="@/assets/images/price-info-web.png" alt=""/>
            </div>
            <div class="item-icon-list">
              <h4>학생</h4>
              <img src="@/assets/images/price-info-web.png" alt=""/>
              <img src="@/assets/images/price-info-app.png" alt=""/>
            </div>
          </div>
          <div class="sector-item">
            <h1>에스덕 Custom</h1>
            <span><strong>5,000원</strong> /인당<br/>
    학생계정 31명부터 1인당 추가요금</span>
            <p>학생 수 100명 이상이라면?<br/>
              <strong>고객센터</strong>로 문의해 합리적인 요금제를 확인하세요!</p>
          </div>
          <div class="sector-item">
            <h1>호환가능한 기기</h1>
            <div class="item-icon-list">
              <h5>선생님</h5>
              <img src="@/assets/images/price-info-notebook.png" alt=""/>
              <img src="@/assets/images/price-info-tablet.png" alt=""/>
            </div>
            <div class="item-icon-list">
              <h4>학생</h4>
              <img src="@/assets/images/price-info-notebook.png" alt=""/>
              <img src="@/assets/images/price-info-tablet.png" alt=""/>
              <img src="@/assets/images/price-info-mobile.png" alt=""/>
            </div>
          </div>
        </div>

        <div class="sector-3">
          <h1>에스덕과 타사문제은행과의 차이를 꼼꼼하게 비교해보세요.</h1>
          <table>
            <colgroup>
              <col/>
              <col/>
              <col/>
            </colgroup>
            <thead>
            <tr>
              <th></th>
              <th><img class="t-logo" src="@/assets/images/price-info-sector-3-logo.png" alt=""/></th>
              <th><span>타사</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>학습지 제작</td>
              <td>학생 맞춤형 문제은행<br/>+ 테마별 문제은행</td>
              <td>정형화된 문제은행만 제공</td>
            </tr>
            <tr>
              <td>쌍둥이 문제 지원</td>
              <td>2배수 이상(매 달 추가)</td>
              <td><img class="t-x" src="@/assets/images/price-info-sector-3-x.png" alt=""/></td>
            </tr>
            <tr>
              <td>수능/모의고사/학교 기출</td>
              <td>모두지원(매 달 추가)</td>
              <td><img class="t-x" src="@/assets/images/price-info-sector-3-x.png" alt=""/>(부분적 지원)</td>
            </tr>
            <tr>
              <td>시중교재/교과서 연계</td>
              <td>200권 지원</td>
              <td><img class="t-x" src="@/assets/images/price-info-sector-3-x.png" alt=""/></td>
            </tr>
            <tr>
              <td>학생 학업 보고서</td>
              <td>무제한 지원</td>
              <td><img class="t-x" src="@/assets/images/price-info-sector-3-x.png" alt=""/></td>
            </tr>
            <tr>
              <td>호환 가능한 기기</td>
              <td>PC, 태블릿, 모바일(학생)</td>
              <td>PC만 가능</td>
            </tr>
            <tr>
              <td>업데이트</td>
              <td>가입 시 무료 업데이트<br/>기능 확장 및 추가</td>
              <td>컨텐츠가 고정적, 업데이트<img class="t-x" src="@/assets/images/price-info-sector-3-x.png" alt=""/></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="sector-4">
          <h1>핵심 기능</h1>
          <div class="sector-list">
            <div class="list-item">
              <h5>완벽한 교재매칭</h5>
              <p><span>각 출판사의 문제 유형별로 매칭된<br/>
쌍둥이 문제 90%의 높은 문제 유사도!<br/></span></p>
              <button>자세히</button>
            </div>
            <div class="list-item">
              <h5>맞춤형 학습지 출력 시스템</h5>
              <p><span>간편하게 짧은 시간에 맞춤형 학습지 출력 가능<br/>
<br/>
학생을 짧은 시간에 효율적으로<br/>
공부시키는 것이 가능합니다!</span></p>
              <button>자세히</button>
            </div>
            <div class="list-item">
              <h5>전문적인 보고서</h5>
              <p><span>학부모와 학생들을 위한 시험지별,<br/>
누적 데이터별 학업 성취도 보고서 작성가능!<br/>
<br/>
상담 시 전문적인 상담,<br/>
체계적인 고객관리 가능!</span></p>
              <button>자세히</button>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron.vue'
import { Bootpay } from '@bootpay/client-js'

export default {
  components: { Jumbotron },
    name: 'PriceInfo',
    methods: {
        async onRequestPayment(price) {
            try {
                const response = await Bootpay.requestPayment({
                    "application_id": process.env.VUE_APP_BOOTPAY_JAVASCRIPT_KEY,
                    "price": price,
                    "order_name": "테스트결제",
                    "order_id": "TEST_ORDER_ID",
                    "pg": "이니시스",
                    "method": "카드",
                    "tax_free": 0,
                    "user": {
                        // "id": "회원아이디",
                        // "username": "회원이름",
                        // "phone": "01000000000",
                        // "email": "test@test.com"
                    },
                    "items": [
                        {
                            "id": "item_id",
                            "name": "테스트아이템",
                            "qty": 1,
                            "price": price
                        }
                    ],
                    "extra": {
                        "open_type": "iframe",
                        "card_quota": "0,2,3",
                        "escrow": false
                    }
                })

                switch (response.event) {
                    case 'issued':
                        // 가상계좌 입금 완료 처리
                        this.$toast.success('가상계좌 입금 완료 처리 되었습니다');
                        break
                    case 'done':
                        console.log(response)
                        // 결제 완료 처리
                        this.$toast.success('결제 완료 처리 되었습니다');
                        break
                }
            } catch (e) {
                // 결제 진행중 오류 발생
                // e.error_code - 부트페이 오류 코드
                // e.pg_error_code - PG 오류 코드
                // e.message - 오류 내용
                console.log(e)
                switch (e.event) {
                    case 'cancel':
                        // 사용자가 결제창을 닫을때 호출
                        console.log(e.message);
                        break
                    case 'error':
                        // 결제 승인 중 오류 발생시 호출
                        this.$toast.error(`결제 승인 중 오류가 발생하였습니다\n오류 코드: ${e.error_code}`)
                        break
                }
            }
        }
    },
}
</script>

<style lang="scss">
.price-info-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 97px;
    .base-container {
        flex-direction: column;
    }
    .price-box-list {
        width: 100%;
        margin-top: 70px;
        gap: 60px;
        display: flex;
        .box-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            background: #E2F5FF;
            border: 2px solid #008ED5;
            border-radius: 30px;
            padding: 35px 15px 45px;
            align-items: center;
            align-self: stretch;
            h1 {
                font-weight: 800;
                font-size: 32px;
                text-align: center;
                color: #008ED5;
            }
            div {
                flex: 1;
                display: flex;
                justify-content: center;
                margin-top: 41px;
                p {
                    font-weight: 400;
                    font-size: 18px;
                    text-align: center;
                    line-height: 1.5;
                    align-self: center;
                    strong {
                        font-size: 32px;
                        line-height: 1.2;
                    }
                }
            }
            button {
                margin-top: 37px;
                background: #F8B633;
                border-radius: 90px;                
                padding: 13px 39px;
                text-align: center;
                font-weight: 800;
                font-size: 17px;
                color: white;
            }
        }
    }
    .sector-2 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 73px 20px;
        margin-top: 68px;
        padding: 60px 40px;
        border-top: 2px solid #008ED5;
        border-bottom: 2px solid #008ED5;
        .sector-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            h1 {
                color: #008ED5;
                font-weight: 800;
                font-size: 26px;
            }
            p {
                margin-top: 30px;
                font-weight: 700;
                font-size: 18px;
                color: black;
            }
            .item-icon-list {
                width: 100%;
                display: flex;
                margin-top: 30px;
                h4, h5 {
                    width: 85px;
                    border-right: 3px solid black;
                    align-self: center;
                    font-weight: 700;
                    font-size: 18px;
                    color: black;
                    margin-right: 30px;
                }
                h4 {
                    font-size: 20px;
                }
                img {
                    width: 50px;
                    object-fit: contain;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    margin-top: 15px;
                }
            }
        }
    }
    .sector-3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 137px;
        h1 {
            font-weight: 800;
            font-size: 36px;
            text-align: center;
            color: black;
        }
        table {
            margin-top: 70px;
            width: 100%;
            border-collapse: unset;
            border-spacing: 0;
            colgroup {
                col {
                    width: 33.3%;
                }
            }
            th {
                font-weight: 800;
                font-size: 22px;
                text-align: center;
                color: #999999;
                align-self: center;
                padding: 23px 15px;
                .t-logo {
                    width: 98px;
                    object-fit: contain;
                }
                &:nth-child(2) {
                    border-top-left-radius: 30px;
                    background-color: #008ED5;
                    border: 2px solid #008ED5;
                    border-bottom-color: white;
                    border-bottom-width: 4px;
                    border-right-width: 0;
                }
                &:nth-child(3) {
                    border: 2px solid #008ED5;
                    border-top-right-radius: 30px;
                    border-bottom-width: 4px;
                }
            }
            td {
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                color: black;
                height: 86px;
                border-bottom: 1px solid #008ED5;
                align-self: center;
                align-items: center;
                &:nth-child(2) {
                    background-color: #008ED5;
                    border-bottom-color: white;
                    color: white;
                }
                &:nth-child(3) {
                    border-right: 2px solid #008ED5;
                }
                .t-x {
                    width: 20px;
                    object-fit: contain;
                    margin: 0 7px;
                    align-self: center;
                }
            }
            tr:last-child {
                td {
                    &:nth-child(1) {
                        border-bottom-width: 0;
                    }
                    &:nth-child(2) {
                        border-bottom-left-radius: 30px;
                    }
                    &:nth-child(3) {
                        border-bottom-right-radius: 30px;
                    }
                }
            }
        }
    }

    .sector-4 {
        width: 100%;
        padding-top: 121px;
        display: flex;
        flex-direction: column;
        h1 {
            font-weight: 800;
            font-size: 36px;
            text-align: center;
            color: black;
        }
        .sector-list {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 58px;
            gap: 25px;
            .list-item {
                width: 100%;
                padding: 57px 20px 29px;
                display: flex;
                flex-direction: column;
                align-content: center;
                background: #E2F5FF;
                border: 2px solid #008ED5;
                border-radius: 30px;
                h5 {
                    font-weight: 700;
                    font-size: 23px;
                    text-align: center;
                    color: black;
                }
                p {
                    width: 100%;
                    height: 170px;
                    background: #FFFFFF;
                    border: 1px solid #A4E0FF;
                    border-radius: 10px;
                    padding: 5px;
                    font-weight: 700;
                    font-size: 15px;
                    text-align: center;
                    color: black;
                    align-self: center;
                    display: flex;
                    margin-top: 43px;
                    span {
                        width: 100%;
                        align-self: center;
                    }
                }
                button {
                    margin-top: 30px;
                    width: 140px;
                    height: 45px;
                    text-align: center;
                    background: #F8B633;
                    border-radius: 90px;
                    cursor: pointer;
                    align-self: center;
                    font-weight: 800;
                    font-size: 17px;
                    color: #FFFFFF;
                }
            }
        }
    }

}
</style>
