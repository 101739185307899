<template>
    <li 
        :class="[
            'list-tr', 
            this.addClass,
            {
                'c-pointer': this.hasCPointer,
                'checked': this.isChecked,
            }
        ]"
        >
        <template
            v-for="(td, tdIndex) in tdList" 
            :key="tdIndex"
            >
            <ListArrayBodyTd
                :td="this.itemTdValue(td, tdIndex)"
                :addClass="this.tdAddClassList[tdIndex]"
                :type="this.tdTypeList[tdIndex]"
                />
        </template>
    </li>
</template>

<script>
import ListArrayBodyTd from './ListArrayBodyTd.vue';

export default {
    name: "ListArrayBodyTr",
    props: {
        addClass: String,
        hasCPointer: Boolean,

        tdList: Object,
        tdAddClassList: Array,
        tdTypeList: Array,

        firstNumber: Number,
    },
    components: { ListArrayBodyTd },
    methods: {
        itemTdValue(td, tdIndex) {
            if (tdIndex == 0 && this.firstNumber) {
                td.value = this.firstNumber
            }
            return td
        },
    },
    computed: {
        isChecked() {
            try {
                const findTdIndex = Object.values(this.tdList).findIndex(i => i.isChecked)
                return findTdIndex > -1
            } catch (e) {
                console.log(e);
            }
            return false
        },
    },
}
</script>