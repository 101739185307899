<template>
  <div :class="['loading-dialog', {
    'is-show': this.isShow,
  }]">
    <b-spinner
        style="width: 3rem; height: 3rem;"
        variant="primary"
        label="Spinning"
    ></b-spinner>
  </div>
</template>

<style lang="scss" scoped>
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .4);
  z-index: -1;
  opacity: 0;
  &.is-show {
    z-index: 99999999999;
    opacity: 1;
  }
}
</style>

<script>
export default {
    name: "LoadingDialog",
    props: {
        isShow: { type: Boolean, default: false },
    },
}
</script>
