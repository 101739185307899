<template>
  <div :class="['preview-container-wrap', {
    'test-mode': isTestMode === true,
    'isTestOnlyFirstPage': this.isTestOnlyFirstPage === true,
  }]">
    <div class="preview-container">
      <template v-if="this.problemPreviewData">
        <ProblemPreview
            :nameTag="this.problemPreviewData.nameTag"
            :name="this.problemPreviewData.name"
            :subName="this.problemPreviewData.subName"
            :create_dt="this.problemPreviewData.create_dt"
            :makerName="this.problemPreviewData.makerName"
            :problemCnt="this.problemPreviewData.problemCnt"
            :problems="this.problemPreviewData.problems"
            :isVisibleDate="this.problemPreviewData.isVisibleDate"
            :isVisiblePatternName="this.problemPreviewData.isVisiblePatternName"
            :isVisibleDifficulty="this.problemPreviewData.isVisibleDifficulty"
            :isVisibleSimilar="this.problemPreviewData.isVisibleSimilar"
            :onLoaded="onLoadedEvent"
            :type="utils.PdfType.studentWork"
        />
      </template>
    </div>

    <div class="preview-container-front-wrap">
      <template v-if="(isLoading || isMakingPreview || isDownloading)">
        <b-spinner label="Spinning"></b-spinner>
        <span v-if="isLoading">데이터를 불러오는 중입니다</span>
        <span v-else-if="isMakingPreview">미리보기를 위해 준비 중입니다</span>
        <span v-else-if="isDownloading">다운로드를 위해 준비 중입니다</span>
      </template>
      <template v-else-if="this.event === 'download'">
        <span>다운로드가 안 될 시 아래 다운로드 버튼을 눌러주세요</span>
        <button @click="() => this.createdPdf()">다운로드</button>
      </template>
    </div>

    <div class="preview-container-prev-pdf" v-if="src && numPages">
      <div class="pdf-list" :style="[{'max-width': `${srcMaxWidth}px`}]">
        <VuePDF
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProblemPreview from '../components/ProblemPreview.vue'
import ReportPreview from '../components/ReportPreview.vue'
import utils from '../utils'
import VuePDF from 'vue3-pdf'
import { debounce } from 'lodash'
import studentWorkService from "@/services/studentWork.service";

export default {
  components: {
    ProblemPreview,
    ReportPreview,
    VuePDF,
  },
  data() {
    return {
      utils,

      event: null,
      id: null,
      studentWork: null,
      isLoading: true,
      isMakingPreview: false,
      isDownloading: false,

      pdf: null,
      pdfUrl: null,

      src: null,
      srcCalcWidth: null,
      srcMaxWidth: 597,
      numPages: 0,

      difficulties: ['하', '중하', '중', '상', '최상'],

      isTestMode: false,
      isTestOnlyFirstPage: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.initData();
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWindowWidth)
  },
  methods: {
    onLoadedEvent() {
      if (this.isTestMode === false && this.isTestOnlyFirstPage === false) {
        this.createPdf();
      }
    },
    checkWindowWidth() {
      this.reloadSrc(this)
    },
    reloadSrc: debounce(async (appContext) => {
      if (window.innerWidth != appContext.srcCalcWidth && (window.innerWidth < appContext.srcMaxWidth || appContext.srcCalcWidth < appContext.srcMaxWidth)) {
        appContext.src = await VuePDF.createLoadingTask(appContext.pdfUrl)
      }
      appContext.srcCalcWidth = window.innerWidth
    }, 200),
    onErrorDataRedirect() {
      alert('잘못된 요청입니다')
      if (history.length <= 1) {
          window.close()
      } else {
          window.history.back()
      }
    },
    async initData() {
      const { studentWorkId, event } = this.$route.params;

      if (!studentWorkId || !event) {
        this.onErrorDataRedirect();
      }
      else {
        this.event = event;

        try {
          this.id = parseInt(studentWorkId);
          this.studentWork = await studentWorkService.getPreviewData(this.id);
          if (!this.studentWork) {
            this.onErrorDataRedirect();
          }
        } catch (e) {
          console.log(e);
          this.onErrorDataRedirect();
        }
      }
    },
    async createPdf() {
      console.log('Preview - createPdf()');

      this.isLoading = false
      let errorMsg = ''

      switch (this.event) {
        case 'preview':
          this.isMakingPreview = true
          errorMsg = '미리보기를 만드는 중 오류가 발생하였습니다'
          break
        case 'download':
          this.isDownloading = true
          errorMsg = '다운로드 중 오류가 발생하였습니다'
          break
        default:
          this.onErrorDataRedirect()
          return
      }

      const maxTryCnt = 5
      const retryDelay = 500 // ms
      let tryCnt = 1
      let isSuccess = false

      while (tryCnt <= maxTryCnt) {
        try {
          this.pdf = await utils.makeProblemPreviewToPdf(
              null,
              utils.PdfType.studentWork,
              this.event
          );
          isSuccess = true;
          break
        } catch (e) {
          // console.log(e)
        }
        tryCnt++
        await new Promise(res => setTimeout(res, retryDelay))
      }

      if (isSuccess) {
        await this.createdPdf()
      } else {
        this.$toast.error(errorMsg)
      }
      this.isMakingPreview = false
      this.isDownloading = false
    },
    async createdPdf() {
      if (this.event === 'preview') {
        const blobPDF = new Blob([this.pdf.output('blob')])
        this.pdfUrl = URL.createObjectURL(blobPDF)
        this.src = await VuePDF.createLoadingTask(this.pdfUrl)
        this.srcCalcWidth = window.innerWidth
        this.src.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
      } else if (this.event === 'download') {
        this.pdf.save(`${this.downloadName}.pdf`)
        this.$toast.success('다운로드 되었습니다')
      }
    },
  },
  computed: {
    downloadName() {
      if (this.problemPreviewData) {
        return this.problemPreviewData.name
      }
      return '';
    },
    problemPreviewData() {
      if (this.studentWork) {
        return {
          nameTag: this.studentWork.name_tag,
          name: this.studentWork.name,
          subName: this.studentWork.sub_name,
          create_dt: utils.getDateByServer(this.studentWork.create_dt),
          makerName: this.studentWork.maker_name,
          problemCnt: this.studentWork.problem_cnt,
          problems: this.studentWork.student_work_problem,
          isVisibleDate: this.studentWork.is_display_date,
          isVisiblePatternName: this.studentWork.is_display_pattern_name,
          isVisibleDifficulty: this.studentWork.is_display_difficulty,
          isVisibleSimilar: this.studentWork.is_display_similar,
        }
      }

      return null
    },
  },
}
</script>
