<template>
  <div id="container">
    <div class="find-password-wrap">
      <div class="login-container">
        <h1>비밀번호 찾기</h1>

        <input v-model="this.email"
               type="email"
               placeholder="이메일을 입력해주세요."
               :disabled="this.isSendVerifyCode"
               v-if="this.isChangedPassword == false"
        />

        <template v-if="this.isChangedPassword">
          <div class="email-list-wrap">
            <span>비밀번호가 수정되었습니다.<br/>다시 로그인해주세요.</span>
          </div>

          <button class="submit-btn" @click="this.redirectLogin">로그인 페이지로 이동</button>
        </template>
        <template v-else-if="this.isSendVerifyCode == false">
          <button class="submit-btn" @click="this.onSendVerifyCode">인증번호 보내기</button>
        </template>
        <template v-else>
          <input v-model="this.verifyCode"
                 type="text"
                 placeholder="인증코드를 입력해주세요"
          />
          <input v-model="this.password"
                 type="password"
                 placeholder="새로운 비밀번호를 입력해주세요"
          />
          <input v-model="this.passwordConfirm"
                 type="password"
                 placeholder="비밀번호를 다시 입력해주세요"
          />

          <button class="submit-btn" @click="onChangePassword">비밀번호 변경</button>
        </template>
      </div>
    </div>
  </div>
  <LoadingDialog
      :is-show="this.isLoading"
  />
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import AuthService from "@/services/auth.service";

export default {
  components: {LoadingDialog},
  data() {
    return {
      email: null,
      verifyCode: null,
      password: null,
      passwordConfirm: null,
      isLoading: false,
      isSendVerifyCode: false,
      isChangedPassword: false,
    }
  },
  mounted() {
  },
  methods: {
    redirectLogin() {
      this.$router.push({name: 'login'});
    },
    async onChangePassword() {
      if (!this.email) { this.$toast.error('이메일을 입력해주세요'); return; }
      if (!this.verifyCode) { this.$toast.error('인증코드를 입력해주세요'); return; }
      if (!this.password) { this.$toast.error('새로운 비밀번호를 입력해주세요'); return; }
      if (!this.passwordConfirm) { this.$toast.error('비밀번호를 다시 입력해주세요'); return; }
      if (this.password != this.passwordConfirm) { this.$toast.error('비밀번호가 동일하지 않습니다'); return; }

      this.isLoading = true;

      try {
        const res = await AuthService.changePasswordFromFindPasswordView({
          email: this.email,
          code: this.verifyCode,
          password: this.password,
        });
        if (res?.is_success) {
          this.$toast.success('비밀번호가 변경되었습니다');
          this.isChangedPassword = true;
        } else {
          this.$toast.error('비밀번호 변경에 실패하였습니다');
        }
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false;
    },
    async onSendVerifyCode() {
      if (!this.email) {
        this.$toast.error('이메일을 입력해주세요');
        return;
      }

      this.isLoading = true;
      let isExistEmail = false;

      try {
        const res = await AuthService.checkEmail({
          email: this.email,
        });
        if (res?.is_success) {
          isExistEmail = true;
        } else {
          this.$toast.error('동일한 이메일이 없습니다');
        }
      } catch (e) {
        console.log(e);
        this.$toast.error('이메일 확인 중 오류가 발생하였습니다');
      }

      if (isExistEmail) {
        try {
          const res = await AuthService.verifyEmail({
            email: this.email,
          });
          if (res?.is_success) {
            this.isSendVerifyCode = true;
            this.$toast.success('인증 코드가 요청하신 이메일로 발송되었습니다');
          } else {
            this.$toast.error('인증 코드 발송 중 오류가 발생하였습니다');
          }
        } catch (e) {
          console.log(e);
          this.$toast.error('인증 코드 전송 중 오류가 발생하였습니다');
        }
      }

      this.isLoading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.find-password-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 108px 40px 194px;
  .login-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    h1 {
      font-weight: 700;
      font-size: 45px;
      text-align: center;
      color: black;
      margin-bottom: 46px;
    }
    input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 5px;
      padding: 17px 23px;
      font-weight: 700;
      font-size: 14px;
      color: #999999;
      margin-top: 20px;
    }
    .submit-btn {
      width: 100%;
      background: #008ED5;
      border: 1px solid #008ED5;
      margin-top: 44px;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
    }
    .or-border {
      width: 100%;
      display: flex;
      gap: 15px;
      margin-top: 47px;
      align-items: center;
      .border {
        flex: 1;
        height: 1px;
        background: #008ED5;
        border-radius: 5px;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }
    }
    .sns-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 21px;
      align-items: center;
      .sns-btn {
        width: 50px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    p {
      margin-top: 56px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
    }
    .reg-btn {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #008ED5;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #008ED5;
      margin-top: 23px;
    }
    .find-list-wrap {
      width: 100%;
      display: flex;
      gap: 10px;
      margin-top: 20px;
      .divider {
        width: 1px;
        align-self: stretch;
        background-color: #008ED5;
      }
      .find-item {
        flex: 1;
        font-size: 14px;
        color: black;
        &:first-child {
          text-align: right;
        }
        &:last-child {
          text-align: start;
        }
        &>a {
          cursor: pointer;
        }
      }
    }
    .email-list-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      min-height: 150px;
      justify-content: center;
      &>span {
        text-align: center;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .find-password-wrap {
    padding: 20px 40px;
    .login-container {
      h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
      input {
        margin-top: 15px;
        font-size: 12px;
      }
      .submit-btn {
        margin-top: 30px;
      }
    }
  }
}
</style>
