<template>
    <button 
        :class="[
            addClass, 
            {
                'checked': this.isChecked,
                'reserve': this.isReverse,
                'font-15': this.isFont15,
            }
        ]">
        <div class="label"></div>
        <span>{{ title }}</span>
    </button>
</template>

<script>
export default {
    name: 'CustomButtonLabel',
    props: {
        title: String,
        isChecked: Boolean,
        addClass: String,
        isReverse: Boolean,
        isFont15: Boolean,
        isHiddenCheckBox: Boolean,
    },
}
</script>

<style lang="scss" scoped>
button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    .label {
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border: 1px solid #008ED5;
        border-radius: 3px;
        position: relative;
    }
    span {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
    }
    &.reserve {
        .label {
            order: 2;
        }
        span {
            order: 1;
        }
    }
    &.checked {
        .label {
            background: #008ED5;
            border-color: #008ED5;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(0.15em, 0.2em) rotate(-45deg);
                width: 0.5em;
                height: 0.3em;
                border: 0.125em solid #fff;
                border-top-style: none;
                border-right-style: none;
            }
        }
    }
    &.font-15 {
        span {
            font-size: 15px;
            line-height: 17px;
        }
    }
}
</style>