import api from "./api"

class WorkbookService {
  getWorkbooks(params) {
    return api.get('/view_set/workbook/', { params })
  }

  getWorkbookList(params) {
    return api.get('/workbook/get_list', { params })
  }

  getWorkbook(id, params) {
    return api.get(`/workbook/get/${id}/`, { params })
  }

  getUnAuthWorkbooks(params) {
    return api.get('/workbook/un-auth/workbooks/', { params })
  }

  getSampleWorkbooks(params) {
    return api.get('/workbook/get_sample_workbooks', { params })
  }

  getCategories() {
    return api.get('/workbook/categories/')
  }

  getSampleCategories() {
    return api.get('/workbook/sample_categories/')
  }

  getSubCategories(params) {
    return api.get('/workbook/sub_categories/', { params })
  }

  getSampleSubCategories(params) {
    return api.get('/workbook/sample_sub_categories/', { params })
  }

  createWorkbook(payload) {
    return api.post('/view_set/workbook/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  updateWorkbook(workbookId, payload) {
    return api.put(`/view_set/workbook/${workbookId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  deleteWorkbook(payload) {
    return api.post('/workbook/delete_id_list/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
}

export default new WorkbookService()
