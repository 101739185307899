<template>
    <div class="mypage-teacher-wrap">
        <div class="container-1200px">
            <MypageSideBar
                :sidebarData="this.sidebarData"
                :onTabClick="this.onChangeSidebar"
                :onExpandItem="this.onExpandSidebar"
            />
            <template v-if="selectReport === null">
                <div class="mypage-tab-body-wrap round-top">
                    <Filter
                        :filterData="filterData"
                        />

                    <ListArray
                        :listData="listData" 
                        />
                </div>
            </template>
            <template v-else>
                <div class="mypage-tab-body-wrap round-top enable-scroll create-report-form">
                    <div class="list-item">
                        <p><span>보고서 명</span></p>
                        <div class="item-input">
                            <CustomInput
                                :value="selectReport.name"
                                :onChangeValue="(value) => selectReport.name = value"
                                addClass="name-input"
                                />
                        </div>
                    </div>

                    <div class="list-item mb-70px">
                        <p><span>보고서 기간</span></p>
                        <div class="item-input date-wrap">
                            <CustomCheckbox
                                :isChecked="selectReport.is_monthly !== false"
                                :onChange="(checked) => selectReport.is_monthly = true"
                                title="월간 보고서"
                                />

                            <CustomCheckbox
                                :isChecked="selectReport.is_monthly === false"
                                :onChange="(checked) => selectReport.is_monthly = false"
                                title="기간 선택"
                                />

                            <div class="input-date">
                                <template v-if="selectReport.is_monthly !== false">
                                    <CustomInput
                                        type="month"
                                        addClass=""
                                        :value="selectReport.start_dt ? utils.getDateByServer(selectReport.start_dt, 'yyyy-mm') : null"
                                        :onChangeValue="(value) => changeMonthlyStartDt(value)"
                                        placeholder="YYYY.MM"
                                        />
                                </template>
                                <template v-else>
                                    <CustomInput
                                        type="date"
                                        addClass=""
                                        :value="selectReport.start_dt ? utils.getDateByServer(selectReport.start_dt, 'yyyy-mm-dd') : null"
                                        :onChangeValue="(value) => selectReport.start_dt = value"
                                        placeholder="YYYY.MM.DD"
                                        />

                                    <span>~</span>

                                    <CustomInput
                                        type="date"
                                        addClass=""
                                        :value="selectReport.end_dt ? utils.getDateByServer(selectReport.end_dt, 'yyyy-mm-dd') : null"
                                        :onChangeValue="(value) => selectReport.end_dt = value"
                                        placeholder="YYYY.MM.DD"
                                        />
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="list-item mb-45px">
                        <p><span>학습 내용 선택</span></p>
                        <div class="item-input btn-list">
                            <div class="all-btn">
                                <CustomButton
                                    :title="'전체'"
                                    :addClass="'min-w-70px'"
                                    :isChecked="learnCategories.findIndex(i => !i.isChecked) < 0"
                                    @click="() => learnCategories.findIndex(i => !i.isChecked) < 0 ? learnCategories.forEach(i => i.isChecked = false) : learnCategories.forEach(i => i.isChecked = true)"
                                    />
                            </div>
                            <CustomButton
                                v-for="(learnCategory, index) in learnCategories" :key="index"
                                :title="learnCategory.name"
                                :addClass="'min-w-70px'"
                                :isChecked="learnCategory.isChecked"
                                @click="() => learnCategory.isChecked = !learnCategory.isChecked"
                                />                            
                        </div>
                    </div>

                    <div class="list-item">
                        <p><span>보고서 구성 내용</span></p>
                        <div class="item-input btn-list">
                            <div class="all-btn">
                                <CustomButton
                                    :title="'전체'"
                                    :addClass="'min-w-70px'"
                                    :isChecked="contentsCategories.findIndex(i => !i.isChecked) < 0"
                                    @click="() => contentsCategories.findIndex(i => !i.isChecked) < 0 ? contentsCategories.forEach(i => i.isChecked = false) : contentsCategories.forEach(i => i.isChecked = true)"
                                    />
                            </div>
                            <CustomButton
                                v-for="(contentsCategory, index) in contentsCategories" :key="index"
                                :title="contentsCategory.name"
                                :addClass="'min-w-70px'"
                                :isChecked="contentsCategory.isChecked"
                                @click="() => contentsCategory.isChecked = !contentsCategory.isChecked"
                                />                            
                        </div>
                    </div>

                    <div class="list-item">
                        <p><span>가산점</span></p>
                        <div class="item-input add-point-wrap">
                            <CustomSelect
                                :options="addPointOptions"
                                :onChangeValue="(value) => this.selectReport.add_point = value"
                                :hiddenName="'없음'"
                                />
                        </div>
                    </div>

                    <div class="list-item">
                        <p><span>코멘트</span></p>
                        <div class="item-input comment-wrap">
                            <CustomTextarea
                                :onChangeValue="(value) => this.selectReport.comment = value"
                                placeholder="없음"
                                />
                        </div>
                    </div>

                    <button class="list-submit-btn" @click="submitReport">
                      <template v-if="isSubmitReporting">
                        업로드중
                      </template>
                      <template v-else>
                        작성완료
                      </template>
                    </button>
                </div>
            </template>
        </div>

      <template v-if="this.reportInfoPreview">
        <ReportPreview :reportData="this.reportInfoPreview" />
      </template>
    </div>
</template>

<script>
import dateFormat from 'dateformat';
import CustomButton from '../../../../components/CustomButton.vue';
import CustomCheckbox from '../../../../components/CustomCheckbox.vue';
import CustomInput from '../../../../components/CustomInput.vue';
import CustomSelect from '../../../../components/CustomSelect.vue';
import CustomTextarea from '../../../../components/CustomTextarea.vue';
import Filter from '../../../../components/Filter.vue';
import ListArray from '../../../../components/ListArray.vue';
import MypageSideBar from '../../../../components/MypageSideBar.vue';
import academyService from '../../../../services/academy.service';
import utils from '../../../../utils';
import ReportPreview from "@/components/ReportPreview.vue";

export default {
  name: 'MypageTeacherLessonHistoryReport',
  components: {
    ReportPreview,
    MypageSideBar,
    Filter,
    ListArray,
    CustomInput,
    CustomCheckbox,
    CustomButton,
    CustomSelect,
    CustomTextarea
  },
  data() {
    return {
      selectSideBarTabIndex: 0,
      unexpandSideBar: [],

      students: [],
      selectStudent: null,
      reports: [],
      searchKeyword: null,
      selectReport: null,

      utils,

      learnCategories: [],
      contentsCategories: [],

      reportInfoPreview: null,
      isSubmitReporting: false,
    };
  },
  mounted() {
    this.initData()
  },
  methods: {
    changeMonthlyStartDt(value) {
      this.selectReport.start_dt = value
      const endDt = new Date(value)
      endDt.setDate(1)
      endDt.setMonth(endDt.getMonth() + 1)
      endDt.setDate(0)
      this.selectReport.end_dt = dateFormat(endDt, 'yyyy-mm-dd')
    },
    async submitReport() {
      if (this.isSubmitReporting) {
        this.$toast.error('제출 중입니다.\n잠시만 기다려주세요.');
        return;
      }
      this.isSubmitReporting = true;

      const isMonthly = this.selectReport.is_monthly !== false
      const start_dt = this.selectReport.start_dt
      let end_dt = this.selectReport.end_dt
      const checkedLearnCategories = this.learnCategories.filter(i => i.isChecked)
      const checkedContentsCategories = this.contentsCategories.filter(i => i.isChecked)

      if (!this.selectReport.name) { this.$toast.error('보고서 명을 입력해주세요') }
      else if (isMonthly && !start_dt) { this.$toast.error('보고서 기간(월)을 선택해주세요') }
      else if (!isMonthly && !start_dt) { this.$toast.error('보고서 시작 일자를 선택해주세요') }
      else if (!isMonthly && !end_dt) { this.$toast.error('보고서 종료 일자를 선택해주세요') }
      else if (checkedLearnCategories.length === 0) { this.$toast.error('학습 내용을 최소 한 개 이상 선택해주세요') }
      else if (checkedContentsCategories.length === 0) { this.$toast.error('보고서 구성 내용을 최소 한 개 이상 선택해주세요') }
      else {
        if (isMonthly) {
          end_dt = start_dt
        }

        let reportId = this.selectReport?.id ?? 0;

        let res = null
        const isUpdate = reportId;
        const payload = {
          academy_user_id: this.selectStudent.id,
          name: this.selectReport.name,
          is_monthly: isMonthly,
          start_dt: dateFormat(start_dt, 'yyyy-mm-dd'),
          end_dt: dateFormat(end_dt, 'yyyy-mm-dd'),
          learn_category_id_list: checkedLearnCategories.map(i => i.id),
          contents_category_id_list: checkedContentsCategories.map(i => i.id),
          add_point: this.selectReport.add_point ?? 0,
          comment: this.selectReport.comment ?? '',
        }

        if (isUpdate) {
          res = await academyService.updateAcademyReport(reportId, payload)
        } else {
          res = await academyService.createAcademyReport(payload)
        }
        reportId = res.id;

        // 파일 업로드
        this.reportInfoPreview = await academyService.getAcademyReportInfo(reportId);
        const pdfBlob = await this.createPdf();

        if (pdfBlob == null) {
          this.$toast.error('PDF 변환 중 오류가 발생하였습니다');
        } else {
          res = await academyService.updateAcademyReport(reportId, {
            'pdf_file': pdfBlob,
          })

          if (res) {
            this.$toast.success(`보고서가 ${isUpdate ? '수정' : '생성'}되었습니다`);
            await this.initReports();
          }
        }
      }

      this.isSubmitReporting = false;
    },
    async createPdf() {
      console.log('createPdf() start');

      const maxTryCnt = 5
      const retryDelay = 500 // ms
      let tryCnt = 1

      let pdf = null;

      while (tryCnt <= maxTryCnt) {
        try {
          pdf = await utils.makeProblemPreviewToPdf(
              null,
              'report',
              utils.PdfViewType.preview,
          );
          break
        } catch (e) {
          console.log(e)
        }
        tryCnt++
        await new Promise(res => setTimeout(res, retryDelay))
      }

      console.log('createPdf() end');
      if (pdf) {
        return new Blob([pdf.output('blob')]);
      } else {
        return null;
      }
    },
    async deleteReport(report) {
      if (confirm('정말 삭제하시겠습니까?')) {
        await academyService.deleteAcademyReport(report.id)
        this.$toast.success('삭제되었습니다')
        await this.initReports()
      }
    },
    onChangeSidebar(index) {
      this.selectSideBarTabIndex = index
    },
    onExpandSidebar(index, isExpand) {
      const foundIndex = this.unexpandSideBar.findIndex(i => i == index)

      if (isExpand) {
        if (foundIndex > -1) {
          this.unexpandSideBar.splice(foundIndex, 1)
        }
      } else {
        if (foundIndex < 0) {
          this.unexpandSideBar.push(index)
        }
      }
    },
    async initData() {
      this.students = await academyService.getAcademyUsers({
        get_my_students: true,
      })

      this.learnCategories = await academyService.getReportLearnCategories()
      this.contentsCategories = await academyService.getReportContentsCategories()

      this.initReports()
    },
    async initReports() {
      this.reports = []
      this.searchKeyword = null
      this.changeSelectReport(null)

      this.reports = await academyService.getAcademyReports({
        is_my_academy: true,
        academy_user_id: this.selectStudent?.id ?? null,
      })
    },
    changeSelectReport(report) {
      this.selectReport = report
      this.learnCategories.forEach(i => i.isChecked = false)
      this.contentsCategories.forEach(i => i.isChecked = false)

      if (report && report.id && report.id > 0) {
        if (report.learn_category_id_list) {
          this.learnCategories.forEach(i => i.isChecked = report.learn_category_id_list.includes(i.id))
        }
        if (report.contents_category_id_list) {
          this.contentsCategories.forEach(i => i.isChecked = report.contents_category_id_list.includes(i.id))
        }
      }
    },
  },
  computed: {
    addPointOptions() {
      const values = []
      for (let idx = -30; idx <= 30; idx += 10) {
        values.push(idx)
      }

      return values.map(value => {
        return {
          value: value,
          name: value !== 0 ? `${value}%` : '가산점 없음',
          isSelected: this.selectReport?.add_point == value,
        }
      })
    },
    listData() {
      return {
        title: '보고서',
        heads: [
          {title: '작성일', type: 'string', addClass: 'w-120px'},
          {title: '학생명', type: 'string', addClass: 'w-120px'},
          {title: '보고서명', type: 'string', addClass: 'flex-1'},
          {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
          {title: '다운로드', type: 'prev-btn', addClass: 'w-80px'},
          {title: '공유하기', type: 'prev-btn', addClass: 'w-80px'},
          {title: '', type: 'etc-btn', addClass: 'w-50px'},
        ],
        trList: this.reports.filter(i => {
          if (this.searchKeyword) {
            return i.name.includes(this.searchKeyword)
          } else {
            return true
          }
        }).map(report => {
          return [
            {value: dateFormat(report.create_dt, 'yy.mm.dd')},
            {value: report.academy_user.name},
            {value: report.name},
            {value: '', onClickPrevBtn: () => utils.previewLink(this, utils.PdfViewType.preview, utils.PdfType.academyReport, report.id)},
            {value: '', onClickPrevBtn: () => utils.previewLink(this, utils.PdfViewType.download, utils.PdfType.academyReport, report.id)},
            {value: '', onClickPrevBtn: () => {
              const shareHref = utils.getPreviewUrl(this, 'preview', utils.PdfType.academyReport, report.id);
              const shareFullLink = `${window.location.origin}${shareHref}`;
              window.navigator.clipboard.writeText(shareFullLink);
              this.$toast.success('공유 링크가 클립보드에 복사되었습니다.');
            }},
            {
              value: '',
              customList: [
                {
                  title: '수정',
                  onClick: () => {
                    this.selectStudent = report.academy_user;
                    this.changeSelectReport(report);
                  },
                },
                {
                  title: '다운로드',
                  onClick: () => utils.previewLink(this, 'download', utils.PdfType.academyReport, report.id),
                },
                {
                  title: '삭제',
                  onClick: () => this.deleteReport(report),
                },
              ],
            },
          ]
        }),
      }
    },
    filterData() {
      return {
        isHiddenList: true,
        searchInput: {placeholder: '보고서명 검색', value: this.searchKeyword, onChange: (keyword) => this.searchKeyword = keyword},
      }
    },
    computedStudents() {
      const result = []

      this.students
          .forEach(student => {
            if (this.selectSideBarTabIndex == 0) {
              const schoolType = student.school_type?.substr(0, 1)
              const schoolYear = student.school_year?.substr(0, 1)

              const findIndex = result.findIndex(i => i.schoolType == schoolType && i.schoolYear == schoolYear)
              if (findIndex > -1) {
                result[findIndex].students.push(student)
              } else {
                result.push({
                  schoolType: schoolType,
                  schoolYear: schoolYear,
                  title: `${schoolType}${schoolYear}`,
                  students: [student],
                })
              }
            } else {
              const academyClasses = student.academy_class_list;
              for (let index = 0; index < academyClasses.length; index++) {
                const academyClass = academyClasses[index];

                const findIndex = result.findIndex(i => i.class.id == academyClass.id)
                if (findIndex > -1) {
                  result[findIndex].students.push(student)
                } else {
                  result.push({
                    class: academyClass,
                    title: academyClass.name,
                    students: [student],
                  })
                }
              }
            }

          })

      return result
    },
    sidebarData() {
      return {
        selectTabIndex: this.selectSideBarTabIndex,
        tabAddClass: 'd-flex',
        tabs: [
          {title: '학년'},
          {title: '반'},
        ],
        tabBodyList: this.computedStudents.map((i, index) => {
          return {
            headTitle: `${i.title}`,
            headSubTitle: `${i.students.length}명`,
            isExpand: !this.unexpandSideBar.includes(index),
            items: [
              {
                value: '전체',
                isSelect: this.selectStudent == null,
                addClass: 'align-start',
                onClick: () => {
                  this.selectStudent = null
                  this.initReports()
                }
              },
              ...i.students.map(student => {
                return {
                  value: student.name,
                  isSelect: student.id == this.selectStudent?.id,
                  addClass: 'align-start',
                  button: {
                    isVisible: true,
                    name: '보고서 작성',
                    onClick: () => {
                      this.selectStudent = student
                      this.changeSelectReport({})
                    },
                    addClass: this.selectReport !== null && student.id == this.selectStudent?.id ? 'btn-active' : '',
                  },
                  onClick: () => {
                    this.selectStudent = student
                    this.initReports()
                  },
                }
              })
            ]
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-report-form {
    padding: 55px 40px !important;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .list-item {
        width: 100%;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        &.mb-70px {
            margin-bottom: 70px;
        }
        &.mb-45px {
            margin-bottom: 45px;
        }
        p {
            width: 135px;
            min-height: 31px;
            border-right: 1px solid #008ED5;
            margin-bottom: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 700;
            font-size: 15px;
            color: black;
        }
        .item-input {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .name-input {
                max-width: 210px;
                width: 100%;
            }
            &.date-wrap {
                gap: 30px;
            }
            &.btn-list {
                gap: 10px 20px;
                .all-btn {
                    width: 100%;
                    display: flex;
                }
            }
            &.add-point-wrap {
                select {
                    min-width: 136px;
                }
            }
            &.comment-wrap {
                textarea {
                    width: 100%;
                    height: 82px;
                    padding: 10px 16px;
                }
            }
        }
    }
    .list-submit-btn {
        margin-left: auto;
        padding: 11px 33px;
        background: #008ED5;
        border: 1px solid #008ED5;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        color: #FFFFFF;
    }
}
.report-preview-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
</style>
