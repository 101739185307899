<template>
  <div id="container">
    <div class="workbook-list-wrap">
      <jumbotron :title="'과학 문제 콘텐츠는 언제나 에스덕과 함께하겠습니다.'" :subTitle="'에스덕이 시중교재와 제휴로 만들어진 콘텐츠 언제, 어디서든, 무제한 이용하세요.'"/>

      <div class="base-container">
        <h1>교재목록</h1>

        <div class="tab-slider">
          <button
              v-for="(item, index) in categories"
              :class="{'active': selectCategory && item == selectCategory}"
              :key="index"
              :onClick="() => this.selectCategory = item">
            {{ item }}
          </button>
        </div>
        <div class="tab-slider slider-btm" v-if="subCategories.length > 0">
          <button
              v-for="(subCategory, index) in subCategories"
              :class="{'active': selectSubCategory && subCategory == selectSubCategory}"
              :key="index"
              :onClick="() => this.selectSubCategory = subCategory">
            {{ subCategory }}
          </button>
        </div>

        <div class="workbook-list">
          <div
              v-for="item, key in listItems"
              :key="key"
              class="list-item">
            <Image :imageWidth="270" :imageHeight="348.75" :src="utils.getServerMediaPath(item.prev_img)"/>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron.vue'
import Image from '../components/Image.vue'
import WorkbookService from '../services/workbook.service'
import utils from '../utils'

export default {
  components: { Jumbotron, Image },
  name: 'WorkbookList',
  data() {
    return {
      selectCategory: null,
      selectSubCategory: null,
      categories: [],
      subCategories: [],
      listItems: [],
      utils,
    }
  },
  watch: {
    selectCategory() {
      this.onChangeCategory()
    },
    selectSubCategory() {
      this.loadDataList()
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const res = await WorkbookService.getSampleCategories()
      if (res) {
        this.categories = res
        if (this.categories.length > 0) {
          this.selectCategory = this.categories[0]
        }
      }
    },
    async onChangeCategory() {
      if (this.selectCategory) {
        const res = await WorkbookService.getSampleSubCategories({
          category: this.selectCategory,
        })
        this.subCategories = [];
        if (res) {
          this.subCategories = res;
        }
        this.subCategories.unshift('전체');
        this.selectSubCategory = this.subCategories[0];

        this.loadDataList();
      }
    },
    async loadDataList() {
      this.listItems = []

      const category = this.selectCategory
      const subCategory = this.selectSubCategory
      let schoolYear = null
      let term = null
      let subject = null

      if (['초등교재', '중등교재'].includes(category)) {
        schoolYear = subCategory?.substr(0, 1)
        term = subCategory?.substr(2, 3)
      } else {
        subject = subCategory
      }

      const res = await WorkbookService.getSampleWorkbooks({
        'category': category,
        'school_year': schoolYear,
        'term': term,
        'subject': subject,
        'is_all': subCategory === '전체',
      })
      if (res) {
        this.listItems = res
      }
    },
  },
  computed: {
    isActiveCategory2All: function() {
      return this.subjects.findIndex(item => item.isActive) < 0
    },
  },
}
</script>

<style scoped lang="scss">
.workbook-list-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .base-container {
    margin-top: 104px;
    margin-bottom: 50px;
    flex-direction: column;
    h1 {
      font-weight: 700;
      font-size: 32px;
      color: black;
    }
    .tab-slider {
      margin-top: 53px;
      display: flex;
      justify-content: flex-start;
      background-color: #A4E0FF;
      padding: 30px 52px;
      overflow-x: auto;
      gap: 52px;
      button {
        color: #666666;
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: calc(100% + 2px);
          left: 50%;
          width: 0;
          height: 0;
          background-color: #F8B633;
          transition: all .3s ease-in-out;
        }
        &.active {
          color: black;
          &::after {
            left: 0;
            width: 100%;
            height: 3px;
          }
        }
      }
      &.slider-btm {
        margin-top: 0;
        background-color: white;
        border-bottom: 3px solid #A4E0FF;
        gap: 50px;
        button {
          font-weight: 400;
          &.active {
            font-weight: 700;
          }
        }
      }
    }
    .workbook-list {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 55px 40px;
      min-height: 50vh;
      .list-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
          margin-top: 20px;
          align-self: center;
          font-weight: 700;
          font-size: 18px;
          color: black;
        }
      }
    }
  }
}
</style>
