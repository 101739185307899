export const EditType = {
    preWorksheet: 'pre_ws',
    preWorkbook: 'pre_wb',
    wrongStudentWork: 'wsw',
};

export const PdfType = {
    worksheet: 'worksheet',
    workbook: 'workbook',
    academyReport: 'academy_report',
    studentWork: 'student_work',
};

export const PdfViewType = {
    preview: 'preview',
    download: 'download',
};

export const PdfTargetType = {
    all: 'all', // default
    only_problem: 'only_problem',
    only_solution: 'only_solution',
};

export const v2TeacherRouterItems = [
    {'title': '대시보드', 'iconUrl': require('@/assets/images/side-nav-class.png'), 'subItems': [
        {'title': '대시보드', 'routerName': 'TeacherDashboard', 'path': '/v2/teacher/dashboard'},
    ]},
    {'title': '수업모드', 'iconUrl': require('@/assets/images/side-nav-class.png'), 'subItems': [
        {'title': '수업모드', 'routerName': 'TeacherLesson', 'path': '/v2/teacher/lesson'},
    ]},
    {'title': '자가학습', 'iconUrl': require('@/assets/images/side-nav-self-study.png'), 'subItems': [
        {'title': '자가학습', 'routerName': 'TeacherSelfLearningMode', 'path': '/v2/teacher/self_learning_mode'},
    ]},
    // {'title': '학습지', 'iconUrl': require('@/assets/images/side-nav-workbook.png'), 'subItems': [
    //     {'title': '학습지', 'routerName': 'MypageTeacherClassPreWorksheet', 'path': '/mypage/teacher/class-pre/worksheet'},
    //     {'title': '교재', 'routerName': 'MypageTeacherClassPreWorkbook', 'path': '/mypage/teacher/class-pre/workbook'},
    //     {'title': '테마별 추천학습지', 'routerName': 'MypageTeacherClassPreRecommendByTheme', 'path': '/mypage/teacher/class-pre/recommend-by-theme'},
    //     {'title': '나만의 문제', 'routerName': 'MyProblemList', 'path': '/mypage/teacher/class-pre/my-problem'},
    // ]},
    // {'title': '보고서', 'iconUrl': require('@/assets/images/side-nav-worksheet.png'), 'subItems': [
    //     {'title': '학습내역', 'routerName': 'MypageTeacherLessonHistoryLearningHistory', 'path': '/mypage/teacher/lesson-history/learning-history'},
    //     {'title': '보고서', 'routerName': 'MypageTeacherLessonHistoryReport', 'path': '/mypage/teacher/lesson-history/report'},
    // ]},
    {'title': '설정', 'iconUrl': require('@/assets/images/side-nav-setting.png'), 'subItems': [
        // {'title': '운영 관리', 'routerName': 'MypageTeacherManageOperation', 'path': '/mypage/teacher/manage/operation'},
        {'title': '설정', 'routerName': 'TeacherSetting', 'path': '/v2/teacher/setting'},
    ]},
];

export const v2StudentRouterItems = [
    {'title': '대시보드', 'iconUrl': require('@/assets/images/side-nav-class.png'), 'subItems': [
        {'title': '대시보드', 'routerName': 'StudentDashboard', 'path': '/v2/student/dashboard'},
    ]},
    {'title': '자가학습', 'iconUrl': require('@/assets/images/side-nav-self-study.png'), 'subItems': [
        {'title': '자가 학습모드', 'routerName': 'StudentSelfLearningMode', 'path': '/v2/student/self_learning_mode'},
        {'title': '자가 학습모드 결과', 'routerName': 'StudentSelfLearningModeResult', 'path': '/v2/student/self_learning_mode/result'},
    ]},
];

export const myPageTeacherRouterNames = [
    {
        'title': '수업준비',
        'subHeader': [
            {
                'title': '학습지',
                'routeName': 'MypageTeacherClassPreWorksheet',
                'path': '/mypage/teacher/class-pre/worksheet',
            },
            {
                'title': '교재',
                'routeName': 'MypageTeacherClassPreWorkbook',
                'path': '/mypage/teacher/class-pre/workbook',
            },
            {
                'title': '테마별 추천학습지',
                'routeName': 'MypageTeacherClassPreRecommendByTheme',
                'path': '/mypage/teacher/class-pre/recommend-by-theme',
            },
            {
                'title': '나만의 문제',
                'routeName': 'MyProblemList',
                'path': '/mypage/teacher/class-pre/my-problem',
            },
        ],
    },
    {
        'title': '수업 및 보고서',
        'subHeader': [
            {
                'title': '학습내역',
                'routeName': 'MypageTeacherLessonHistoryLearningHistory',
                'path': '/mypage/teacher/lesson-history/learning-history',
            },
            {
                'title': '보고서',
                'routeName': 'MypageTeacherLessonHistoryReport',
                'path': '/mypage/teacher/lesson-history/report',
            },
        ],
    },
    {
        'title': '관리',
        'subHeader': [
            {
                'title': '운영 관리',
                'routeName': 'MypageTeacherManageOperation',
                'path': '/mypage/teacher/manage/operation',
            },
        ],
    },
];

export const DownloadType = {
    merge: 'merge', // 하나의 PDF로 받기
    individual: 'individual', // 개별 PDF로 받기
};
