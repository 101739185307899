<template>
  <div class="mypage-list-wrap">
    <div :class="['mypage-tab-wrap', tabAddClass]">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab-item', {'active': selectTabIndex == index}]"
          @click="() => { onTabClick(index) }">
        {{ tab.title }}
      </div>
    </div>
    <div :class="['mypage-tab-body-wrap', addTabBodyClass]">
      <Filter
          :filterData="this.filterData"
      />

      <ListArray
          :listData="this.listData"
          :listDatas="this.listDatas"
          :paginateData="this.paginateData"
          :onPageChange="this.onPageChange"
          :isLoading="this.isLoading"
      >
        <template v-slot:listBody>
          <slot name="listBody"></slot>
        </template>
      </ListArray>

      <slot name="tabBody"></slot>
    </div>
  </div>
</template>

<script>
import Filter from './Filter.vue'
import ListArray from './ListArray.vue'

export default {
  name: 'MypageList',
  components: {
    Filter,
    ListArray,
  },
  props: {
    selectTabIndex: Number,
    tabAddClass: String,
    tabs: Array,
    filterData: Object,
    onTabClick: Function,
    listDatas: Array,
    listData: Object,
    addTabBodyClass: String,
    paginateData: Object,
    onPageChange: Function,
    isLoading: Boolean,
  },
}
</script>
