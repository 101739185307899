<template>
  <div :class="['popup-wrap', 'difficulty-setting-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>난이도 설정</h1>
        <table class="i-table-wrap">
          <thead>
            <tr>
              <td></td>
              <td>최상</td>
              <td>상</td>
              <td>중</td>
              <td>중하</td>
              <td>하</td>
            </tr>
          </thead>
          <tbody>
            <template v-for="(rateItem, index) in this.rateItems" :key="index">
              <tr>
                <td>{{ rateItem.title }}</td>
                <template v-for="(rate, index) in rateItem.rates" :key="index">
                  <td>
                    <select @change="(event) => rate = event.target.value">
                      <template v-for="(rt, index) in this.defaultRates" :key="index">
                        <option :selected="rate === rt">{{ rt }}%</option>
                      </template>
                    </select>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
  },
  components: {
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    return {
      defaultRates: Array.from({length: 11}, (_, i) => i).map(i => i * 10),
      rateItems: [
        {
          title: '빠른 학습자',
          rates: [30, 10, 10, 10, 10],
        },
        {
          title: '일반 학습자',
          rates: [30, 10, 10, 10, 10],
        },
        {
          title: '느린 학습자',
          rates: [30, 10, 10, 10, 10],
        },
      ],
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>
