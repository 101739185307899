<template>
  <div class="edit-step-1-wrap">
    <div class="mypage-tab-wrap">
      <div
          v-for="(tab, index) in this.tabData.tabs"
          :key="index"
          :class="['tab-item', {'active': this.tabData.selectTabIndex == index}]"
          @click="() => { onChangeTab(index) }">
        {{ tab.title }}
      </div>
    </div>
    <div class="mypage-tab-body-list">
      <EditStep1Unit
          v-if="this.tabData.selectTabIndex == 0"
          :onChangeProblems="(problems) => this.unitProblems = problems"
          :onChangeSearchProblems="(searchProblems) => this.unitSearchProblems = searchProblems"
          :isWorkbook="isWorkbook"
          :limitProblemCnt="this.limitProblemCnt"
      />

      <EditStep1Workbook
          v-if="this.tabData.selectTabIndex == 1"
          :onChangeProblems="(problems) => this.workbookProblems = problems"
          :onChangeSearchProblems="(searchProblems) => this.workbookSearchProblems = searchProblems"
          :isWorkbook="isWorkbook"
      />

      <EditStep1Mock
          v-if="this.tabData.selectTabIndex == 2"
          :onChangeProblems="(problems) => this.mockProblems = problems"
          :onChangeSearchProblems="(searchProblems) => this.mockSearchProblems = searchProblems"
          :isWorkbook="isWorkbook"
      />
    </div>
  </div>
</template>

<script>
import EditStep1Unit from './editStep1Unit.vue'
import EditStep1Workbook from './editStep1Workbook.vue'
import EditStep1Mock from './editStep1Mock.vue'

export default {
  name: 'EditStep1',
  components: {
    EditStep1Unit,
    EditStep1Workbook,
    EditStep1Mock,
  },
  props: {
    isWorkbook: Boolean,
    onChangeStep: Function,
    problemTypes: Array,
    problemSolutionTypes: Array,
    problemDifficulties: Array,

    onChangeProblems: Function,
    onChangeSearchProblems: Function,

    onChangeTabIndex: Function,
    limitProblemCnt: { type: Number, default: null },
  },
  watch: {
    unitProblems() {
      this.onChangeProblems(this.unitProblems)
    },
    unitSearchProblems() {
      this.onChangeSearchProblems(this.unitSearchProblems)
    },
    workbookProblems() {
      this.onChangeProblems(this.workbookProblems)
    },
    workbookSearchProblems() {
      this.onChangeSearchProblems(this.workbookSearchProblems)
    },
    mockProblems() {
      this.onChangeProblems(this.mockProblems)
    },
    mockSearchProblems() {
      this.onChangeSearchProblems(this.mockSearchProblems)
    },
  },
  data() {
    return {
      tabData: {
        selectTabIndex: 0,
        tabs: [
          {title: '단원·유형별'},
          {title: '시중교재'},
          {title: '모의고사'},
        ],
      },

      unitProblems: [],
      unitSearchProblems: [],

      workbookProblems: [],
      workbookSearchProblems: [],

      mockProblems: [],
      mockSearchProblems: [],
    }
  },
  mounted() {
    this.onChangeTab(this.tabData.selectTabIndex)
  },
  methods: {
    onChangeTab(index) {
      this.tabData.selectTabIndex = index

      switch (index) {
        case 0:
          this.onChangeProblems(this.unitProblems)
          this.onChangeSearchProblems(this.unitSearchProblems)
          break
        case 1:
          this.onChangeProblems(this.workbookProblems)
          this.onChangeSearchProblems(this.workbookSearchProblems)
          break
        case 2:
          this.onChangeProblems(this.mockProblems)
          this.onChangeSearchProblems(this.mockSearchProblems)
          break
      }

      this.onChangeTabIndex(index);
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
.edit-step-1-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .mypage-tab-body-list {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    gap: 38px;
  }
}
</style>
