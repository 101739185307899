<template>
  <div id="container">
    <div class="support-wrap">
      <div class="base-container">
        <h1>고객센터</h1>

        <div class="tab-list">
          <div
              v-for="(item, index) in tabList" :key="index"
              :class="['tab-item', {'active': item === selectTab}]"
              @click="() => selectTab = item"
          >
            {{item}}
          </div>
        </div>

        <div class="body-list">
          <div v-if="selectTab === '공지사항'" class="notice-wrap">
            <div class="notice-item item-header">
              <p>제목</p>
              <p>작성자</p>
              <p>작성일</p>
            </div>
            <div
                v-for="(item, index) in notices" :key="index"
                :class="['notice-item', {'active': selectNotice?.id === item.id}]"
                @click="() => selectNotice = selectNotice?.id === item.id ? null : item"
            >
              <p class="text-line-1">{{item.title}}</p>
              <p class="text-line-1">{{item.writer}}</p>
              <p class="text-line-1">{{utils.getDateByServer(item.create_dt)}}</p>

              <div
                  v-if="selectNotice?.id == item.id"
                  class="item-content"
              >{{item.content}}</div>
            </div>
          </div>

          <div v-if="selectTab === '자주묻는 질문'" class="faq-wrap">
            <div
                v-for="(item, index) in faqs" :key="index"
                :class="['faq-item', {'active': selectFaq?.id === item.id}]"
                @click="() => selectFaq = selectFaq?.id === item.id ? null : item"
            >
              <div class="item-header">
                <p>{{item.title}}</p>
                <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1668 1.0605C20.7526 1.64629 20.7526 2.59604 20.1668 3.18182L11.6815 11.6671C11.0957 12.2529 10.146 12.2529 9.56021 11.6671C8.97442 11.0813 8.97442 10.1316 9.56021 9.54578L18.0455 1.0605C18.6313 0.474718 19.581 0.474717 20.1668 1.0605Z" fill="black"/><path d="M1.06043 1.07336C1.64622 0.487569 2.59597 0.487569 3.18175 1.07336L11.667 9.55864C12.2528 10.1444 12.2528 11.0942 11.667 11.68C11.0812 12.2657 10.1315 12.2657 9.54571 11.68L1.06043 3.19468C0.474647 2.60889 0.474647 1.65914 1.06043 1.07336Z" fill="black"/></svg>
              </div>
              <template v-if="selectFaq?.id === item.id">
                <div class="item-border"></div>
                <div class="item-content"
                >{{item.content}}</div>
                <a v-if="item.link_url !== null"
                   class="item-attach-file"
                   :href="item.link_url"
                   target="_blank"
                >첨부링크 이동</a>
                <a v-if="item.attach_file !== null"
                   class="item-attach-file"
                   :href="item.attach_file"
                   target="_blank"
                >첨부파일 확인</a>
              </template>
            </div>
          </div>

          <div v-if="selectTab === '1:1 문의'" class="contact-wrap">
            <p>하단의 챗봇을 통해 문의해주시면 성심껏 답변해드리겠습니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from '@/services/common.service'
import utils from '../utils'

export default {
    name: 'Support',
    data() {
        return {
            tabList: [
                '공지사항',
                '자주묻는 질문',
                '1:1 문의',
            ],
            selectTab: '공지사항',
            notices: [],
            selectNotice: null,
            faqs: [],
            selectFaq: null,
            utils,
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            CommonService.getNotices().then(res => this.notices = res);
            CommonService.getFaqs().then(res => this.faqs = res);
        },
    },
}
</script>

<style lang="scss" scoped>
.support-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 104px 0 150px;
  .base-container {
    flex-direction: column;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 35px;
      color: #000000;
    }
    .tab-list {
      width: 100%;
      margin-top: 60px;
      background: #E2F5FF;
      padding: 25px 50px;
      display: flex;
      gap: 50px;
      border-bottom: 2px solid #008ED5;
      .tab-item {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #666666;
        position: relative;
        cursor: pointer;
        &.active {
          color: #000000;
          &::after {
            content: '';
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            width: 100%;
            height: 3px;
            background: #F8B633;
            border-radius: 3px;
          }
        }
      }
    }
    .body-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      .notice-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        .notice-item {
          display: flex;
          padding: 20px 40px;
          border-bottom: 1px solid #999999;
          flex-wrap: wrap;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, .02);
          }
          p {
            &:nth-child(1) {
              flex: 1;
            }
            &:nth-child(2) {
              width: 150px;
              text-align: center;
            }
            &:nth-child(3) {
              width: 100px;
              text-align: center;
            }
          }
          &.item-header {
            background-color: #E2F5FF;
          }
          .item-content {
            width: 100%;
            margin-top: 10px;
            border-top: 1px solid #E2F5FF;
            padding-top: 10px;
            white-space: pre-line;
          }
        }
      }
      .faq-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        .faq-item {
          display: flex;
          padding: 20px 40px;
          border-bottom: 1px solid #999999;
          flex-direction: column;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, .02);
          }
          .item-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              color: #000000;
            }
            svg {
              width: 20px;
              height: 20px;
              transition: all .3s ease-in-out;
            }
          }
          .item-border {
            width: 100%;
            height: 1px;
            margin: 20px 0;
            background-color: #E2F5FF;
          }
          .item-attach-file,
          .item-link-url {
            cursor: pointer;
            padding: 5px 15px;
            margin: 5px 0 5px auto;
            background-color: #008ED5;
            border-radius: 7px;
            color: white;
            display: flex;
            font-size: 14px;
            font-weight: bold;
          }
          .item-content {
            width: 100%;
            white-space: pre-line;
          }
          &.active {
            .item-header {
              svg {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
      .contact-wrap {
        min-height: 320px;
        border-bottom: 1px solid #008ED5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
}
</style>
