<template>
  <div :class="['popup-wrap', 'learning-statistics-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <div class="pi-tab-list-wrap">
          <div class="tab-item">
            <span>AI 학습 페이지</span>
            <div></div>
          </div>
          <div class="tab-item active">
            <span>학생 학습 현황</span>
            <div></div>
          </div>
        </div>
        <div class="pi-body-wrap">
          <div class="sec-wrap sec-1-wrap">
            <h1 class="sec-title">1</h1>
            <h2 class="sec-sub-title">학습 패턴</h2>
            <h3 class="sec-desc">개인의 학습 방식과 선호도를 분석하여, 가장 효과적인 학습전략을 개발하는데 도움을 줍니다.</h3>
            <div class="sec-body-wrap">
              <div class="sb-title-wrap">
                <p>연간 학습 패턴</p>
                <div class="sbt-btns-wrap">
                  <button>
                    <img src="@/assets/images/export.png" alt="">
                    <span>데이터 추출하기</span>
                  </button>
                  <button>
                    <img src="@/assets/images/download.png" alt="">
                    <span>이미지로 다운로드</span>
                  </button>
                </div>
              </div>
              <YearlyLearnPattern/>
              <div class="sb-divider"></div>
              <div class="sb-focus-info-wrap">
                <h5>2023년 10월  2주차</h5>
                <div class="fi-list-wrap">
                  <template v-for="(focusInfoItem, index) in this.focusInfoItems" :key="index">
                    <div class="fil-item"
                         :style="[{'background-color': focusInfoItem.backgroundColor}]"
                    >
                      <p>{{ focusInfoItem.title }}</p>
                      <div class="fil-list-wrap">
                        <div class="fill-item">
                          <p>문제풀이</p>
                          <span>{{ focusInfoItem.problemCnt }}개</span>
                        </div>
                        <div class="fill-item">
                          <p>정답률</p>
                          <span>{{ focusInfoItem.answerRate }}%</span>
                        </div>
                        <div class="fill-item">
                          <p>동영상 학습</p>
                          <span>{{ focusInfoItem.videoMinute }}분</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="sec-desc-wrap">
              <div class="sd-box">
                <img src="@/assets/images/sduck-mascot.png" alt="">
                <p><strong>연초가 지나면서 학습량이 줄어들고 있어요.</strong><br>꾸준히 학습하도록 격려해 주세요!</p>
              </div>
            </div>
          </div>
          <div class="sec-wrap sec-2-wrap">
            <h1 class="sec-title">2</h1>
            <h2 class="sec-sub-title">단원별 성취도 분석</h2>
            <h3 class="sec-desc">각 단원에서의 성취도를 평가하여, 학습자의 강점과 개선이 필요한 부분을 파악합니다. 이를 통해 맞춤형 학습계획을 수립 할수 있습니다.</h3>
            <div class="sec-body-wrap">
              <div class="sb-title-wrap">
                <p>단원별 성취도 분석</p>
                <div class="sbt-btns-wrap">
                  <button>
                    <img src="@/assets/images/export.png" alt="">
                    <span>데이터 추출하기</span>
                  </button>
                  <button>
                    <img src="@/assets/images/download.png" alt="">
                    <span>이미지로 다운로드</span>
                  </button>
                </div>
                <div class="sbt-year-wrap">
                  <img class="y-arrow a-left" src="@/assets/images/arrow-left-3.png" alt="">
                  <p>2023년</p>
                  <img class="y-arrow a-right" src="@/assets/images/arrow-right-6.png" alt="">
                </div>
              </div>
              <div class="sb-tab-wrap">
                <template v-for="(tabItem, index) in this.unitPerformanceTabItems" :key="index">
                  <div :class="['t-item', {'active': index === 0}]">
                    <p>{{ tabItem }}</p>
                    <div></div>
                  </div>
                </template>
              </div>
              <div class="sb-graph-wrap">
                <LineChart
                    :chartData="this.unitPerformanceChartData"
                    :options="this.unitPerformanceChartOption"
                    :style="[{'max-height': '100%', 'aspect-ratio': '3'}]"
                />
                <div class="sbg-desc-wrap">
                  <div class="gd-item">
                    <div></div>
                    <p>학습량 (문항)</p>
                  </div>
                  <div class="gd-item">
                    <div></div>
                    <p>정답률</p>
                  </div>
                  <div class="gd-item">
                    <div></div>
                    <p>정답률 (학년 평균)</p>
                  </div>
                </div>
              </div>
              <div class="sb-change-btn">
                <button>
                  <span>단원별 성취도 변화</span>
                  <img src="@/assets/images/arrow-bottom-4.png" alt="">
                </button>
              </div>
              <div class="sb-pb-cnt-as-rate-wrap">
                <div class="class-list-wrap">
                  <template v-for="(className, index) in ['1-1','1-2','1-3','1-3','1-3','1-3','1-3','1-3','1-3','1-3']" :key="index">
                    <div :class="['cl-item', {'active': index === 0}]">
                      <p>{{ className }}</p>
                    </div>
                  </template>
                </div>
                <h5>문제수 100개 / 전체 정답률 80%</h5>
              </div>
              <div class="sb-ar-graph-wrap">
                <template v-if="this.isVisible">
                  <LineChart
                      :chartData="this.answerRateChartData"
                      :options="this.answerRateChartOption"
                      :style="[{'max-height': '100%', 'aspect-ratio': '3.1666'}]"
                  />
                </template>
                <div class="sbg-desc-wrap">
                  <div class="gd-item">
                    <div></div>
                    <p>정답률</p>
                  </div>
                  <div class="gd-item">
                    <div></div>
                    <p>정답률 (학년 평균)</p>
                  </div>
                </div>
                <p>*각 지표를 클릭하시면 해당 영역의 문제 오답노트를 확인해보실 수 있습니다.</p>
              </div>
            </div>
          </div>
          <div class="sec-wrap sec-3-wrap">
            <div class="sec-body-wrap">
              <h1 class="sec-title">3</h1>
              <h2 class="sec-sub-title">일일 학습 시간</h2>
              <h3 class="sec-desc">매일 학습에 투자한 시간을 기록하고 분석하여, 목표 시간에 맞춰 학습 습관을 최적화합니다.</h3>
              <div class="sb-title-wrap">
                <p>일일 학습 시간</p>
                <div class="sbt-btns-wrap">
                  <button>
                    <img src="@/assets/images/export.png" alt="">
                    <span>데이터 추출하기</span>
                  </button>
                  <button>
                    <img src="@/assets/images/download.png" alt="">
                    <span>이미지로 다운로드</span>
                  </button>
                </div>
                <div class="sbt-year-wrap">
                  <img class="y-arrow a-left" src="@/assets/images/arrow-left-3.png" alt="">
                  <p>2023.01.01 - 2023.01.14</p>
                  <img class="y-arrow a-right" src="@/assets/images/arrow-right-6.png" alt="">
                </div>
              </div>
              <div class="sb-tab-wrap">
                <template v-for="(tabItem, index) in this.dailyStudyHoursTabItems" :key="index">
                  <div :class="['t-item', {'active': index === 0}]">
                    <p>{{ tabItem }}</p>
                    <div></div>
                  </div>
                </template>
              </div>
              <div class="daily-graph-wrap">
                <template v-if="this.isVisible">
                  <BarChart
                      :chartData="this.dailyStudyHoursChartData"
                      :options="this.dailyStudyHoursChartOption"
                      :style="[{'max-height': '100%', 'aspect-ratio': '3'}]"
                  />
                </template>
                <div class="sbg-desc-wrap">
                  <template v-for="(dailyStudyHoursItem, index) in this.dailyStudyHoursItems" :key="index">
                    <div class="gd-item">
                      <div :style="[{'background-color': dailyStudyHoursItem.color}]"></div>
                      <p>{{ dailyStudyHoursItem.title }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="sec-wrap sec-4-wrap">
            <div class="sec-body-wrap">
              <h1 class="sec-title">4</h1>
              <h2 class="sec-sub-title">요일별 학습 패턴</h2>
              <h3 class="sec-desc">요일별 학습 경향을 분석하여, 특정 요일에 집중 학습을 유도합니다. 이를 통해 학습 리듬을 조정합니다.</h3>
              <div class="sb-title-wrap">
                <p>요일별 학습 패턴</p>
                <div class="sbt-btns-wrap">
                  <button>
                    <img src="@/assets/images/export.png" alt="">
                    <span>데이터 추출하기</span>
                  </button>
                  <button>
                    <img src="@/assets/images/download.png" alt="">
                    <span>이미지로 다운로드</span>
                  </button>
                </div>
                <div class="sbt-year-wrap">
                  <img class="y-arrow a-left" src="@/assets/images/arrow-left-3.png" alt="">
                  <p>1월</p>
                  <img class="y-arrow a-right" src="@/assets/images/arrow-right-6.png" alt="">
                </div>
              </div>
              <div class="sb-week-list-wrap">
                <template v-for="(dayItem, dayIndex) in [17, 18, 19, 20, 21, 22, 23]" :key="dayIndex">
                  <div class="wl-item">
                    <h1>{{ ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'][dayIndex] }}</h1>
                    <h2>{{ dayItem }}</h2>
                    <div class="wl-schedule-list-wrap">
                      <template v-for="(scheduleItem, index) in Array.from({length: 15}, (_) => Math.floor(Math.random() * 3))" :key="index">
                        <div :class="['sl-item', {
                          'type-none': scheduleItem === 0,
                          'type-common': scheduleItem === 1,
                          'type-active': scheduleItem === 2,
                        }]">
                          <div class="sli-bg"></div>
                          <div :class="['sli-hover-wrap', {'is-right': dayIndex > 3}]">
                            <div class="slih-item">
                              <p>접속시간</p>
                              <span>19:40 ~ 20:20</span>
                            </div>
                            <div class="slih-item">
                              <p>문항</p>
                              <span>120개</span>
                            </div>
                            <div class="slih-item">
                              <p>동영상</p>
                              <span>30분</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
              <div class="sb-desc-wrap">
                <div class="d-button-wrap">
                  <img src="@/assets/images/sduck-mascot.png" alt="">
                  <p><strong>이른 아침보다 점심 식사 전후 집중력이 높네요!</strong><br/>학습 습관을 잘 형성하고 있어요.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LineChart, BarChart } from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import utils from "@/utils";
import YearlyLearnPattern from "@/components/YearlyLearnPattern.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  computed: {
    unitPerformanceChartData() {
      const dataCnt = 12;

      return {
        labels: Array.from({length: dataCnt}, (_) => '1단원'),
        datasets: [
          {
            label: '정답률',
            data: Array.from({length: dataCnt}, (_) => Math.floor(Math.random() * 101)),
            borderColor: '#008ED5',
            backgroundColor: '#008ED5',
            stack: 'combined',
            pointBorderColor: '#ffffff',
            pointBorderWidth: 1.5,
          },
          {
            label: '정답률 (학년 평균)',
            data: Array.from({length: dataCnt}, (_) => Math.floor(Math.random() * 101)),
            borderColor: '#65CF55',
            backgroundColor: '#65CF55',
            pointBorderColor: '#ffffff',
            pointBorderWidth: 1.5,
          },
          {
            label: '학습량 (문항)',
            data: Array.from({length: dataCnt}, (_) => Math.floor(Math.random() * 101)),
            backgroundColor: '#F8B633',
            type: 'bar',
          },
        ],
      };
    },
    unitPerformanceChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          y: {
            display: true,
            position: 'left',
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value}%`;
              },
              color: '#333333',
            },
          },
          y1: {
            display: true,
            position: 'right',
            min: 0,
            max: 300,
            ticks: {
              stepSize: 60,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value}개`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
    answerRateChartData() {
      const dataCnt = 12;

      return {
        labels: Array.from({length: dataCnt}, (_) => '1단원'),
        datasets: [
          {
            label: '정답률',
            data: Array.from({length: dataCnt}, (_) => Math.floor(Math.random() * 101)),
            borderColor: '#008ED5',
            pointBackgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            pointStyle: false,
            tension: 0.3,
            borderWidth: 2,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0,25,0,300);

              gradient.addColorStop(0, 'rgba(69, 196, 251, .5)');
              gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

              return gradient;
            },
          },
          {
            label: '정답률 (학년 평균)',
            data: Array.from({length: dataCnt}, (_) => Math.floor(Math.random() * 101)),
            borderColor: '#F8B633',
            pointBackgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            tension: 0.3,
            borderWidth: 2,
            borderDash: [3, 3],
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0,25,0,300);

              gradient.addColorStop(0, '#F9D286');
              gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

              return gradient;
            },
          },
        ],
      };
    },
    answerRateChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 10,
                family: 'Abhaya Libre',
              },
              callback: function(value, index, ticks) {
                if (value === 0) return '시작 09/10';
                if (value === ticks.length - 1) return '최근 10/10';
                return '';
              },
              color: '#797979',
            },
          },
          y: {
            display: true,
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value) {
                if (value === 0) return '';

                return `${value}%`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
    dailyStudyHoursChartData() {
      const dailyStudyHoursItems = this.dailyStudyHoursItems;
      let maxDataCnt = 0;
      dailyStudyHoursItems.forEach(item => {
        const dataCnt = item.datas.length;
        if (dataCnt > maxDataCnt) {
          maxDataCnt = dataCnt;
        }
      });

      return {
        labels: Array.from({length: maxDataCnt}, (_) => ''),
        datasets: dailyStudyHoursItems.map(item => {
          return {
            label: item.title,
            data: item.datas.map(i => i.problemCnt),
            backgroundColor: item.color,
          };
        }),
      };
    },
    dailyStudyHoursChartOption() {
      const dailyStudyHoursItems = this.dailyStudyHoursItems;

      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: function (context) {
              utils.dailyStudentHoursChartTooltip(context, dailyStudyHoursItems);
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            display: true,
            stacked: true,
            min: 0,
            ticks: {
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value) {
                return `${value}개`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
  },
  components: {
    YearlyLearnPattern,
    LineChart,
    BarChart,
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    return {
      focusInfoItems: [
        {backgroundColor: '#008ED5', title: '물리', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101 * 100) / 100, videoMinute: Math.floor(Math.random() * 101)},
        {backgroundColor: '#FF6854', title: '화학', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101 * 100) / 100, videoMinute: Math.floor(Math.random() * 101)},
        {backgroundColor: '#65CF55', title: '생명과학', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101 * 100) / 100, videoMinute: Math.floor(Math.random() * 101)},
        {backgroundColor: '#FF9E45', title: '지구과학', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101 * 100) / 100, videoMinute: Math.floor(Math.random() * 101)},
      ],
      unitPerformanceTabItems: [
        '과목 전체',
        '수학',
        '과학',
        '사회1',
        '사회2',
        '중등 기초수학',
      ],
      dailyStudyHoursTabItems: [
        '문제',
        '동영상',
      ],
      dailyStudyHoursItems: [
        {title: '물리', color: '#008ED5', datas: Array.from({length: 10}, (_) => {
          return {
            dtStr: '07/30 10:20',
            problemCnt: Math.floor(Math.random() * 101),
            answerRate: Math.floor(Math.random() * 101),
            sec: Math.floor(Math.random() * 601),
          };
        })},
        {title: '화학', color: '#FF6854', datas: Array.from({length: 10}, (_) => {
          return {
            dtStr: '07/30 10:20',
            problemCnt: Math.floor(Math.random() * 101),
            answerRate: Math.floor(Math.random() * 101),
            sec: Math.floor(Math.random() * 601),
          };
        })},
        {title: '생명과학', color: '#65CF55', datas: Array.from({length: 10}, (_) => {
          return {
            dtStr: '07/30 10:20',
            problemCnt: Math.floor(Math.random() * 101),
            answerRate: Math.floor(Math.random() * 101),
            sec: Math.floor(Math.random() * 601),
          };
        })},
        {title: '지구과학', color: '#F8B633', datas: Array.from({length: 10}, (_) => {
          return {
            dtStr: '07/30 10:20',
            problemCnt: Math.floor(Math.random() * 101),
            answerRate: Math.floor(Math.random() * 101),
            sec: Math.floor(Math.random() * 601),
          };
        })},
      ],
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>
