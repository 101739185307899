<template>
  <div class="teacher-setting-wrap">
    <V2Wrapper>
      <div class="tsw-inner">
        <div class="v2-base-container">
          <div class="top-bg-wrap">
            <div class="diff-set-btn-wrap">
              <button @click="() => this.isVisibleDifficultySettingPopup = true">
                <img src="@/assets/images/filter.png" alt="">
                <span>난이도 설정</span>
              </button>
            </div>
            <div class="sec-list-wrap">
              <div class="sec-wrap">
                <h1>진단평가 설정</h1>
                <div class="sw-list-wrap">
                  <template v-for="(settingItem, index) in this.diagnosticSettings" :key="index">
                    <div class="swl-item">
                      <h5>{{ settingItem.title }}</h5>
                      <div class="i-btn-wrap">
                        <template v-for="(item, index) in settingItem.items" :key="index">
                          <button :class="[{'active': item.isSelect}]"
                                  @click="() => item.isSelect = !item.isSelect">
                            <span>{{ item.name }}</span>
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="sec-wrap">
                <h1>단원평가 설정</h1>
                <div class="sw-list-wrap">
                  <template v-for="(settingItem, index) in this.unitSettings" :key="index">
                    <div class="swl-item">
                      <h5>{{ settingItem.title }}</h5>
                      <div v-if="settingItem.ref || settingItem.percent"
                           class="i-sel-wrap"
                      >
                        <template v-if="settingItem.ref">
                          <select class="i-sel">
                            <option selected>상위</option>
                          </select>
                        </template>
                        <template v-if="settingItem.percent">
                          <select class="i-sel">
                            <option selected>10%</option>
                          </select>
                        </template>
                      </div>
                      <div class="i-btn-wrap">
                        <template v-for="(item, index) in settingItem.items" :key="index">
                          <button :class="[{'active': item.isSelect}]"
                                  @click="() => item.isSelect = !item.isSelect">
                            <span>{{ item.name }}</span>
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <DifficultySettingPopup
      :is-visible="this.isVisibleDifficultySettingPopup"
      :on-close="() => this.isVisibleDifficultySettingPopup = false"
  />
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import DifficultySettingPopup from "@/components/DifficultySettingPopup.vue";

export default {
  name: 'TeacherSetting',
  components: {
    DifficultySettingPopup,
    V2Wrapper,
  },
  data() {
    return {
      isVisibleDifficultySettingPopup: false,
      diagnosticSettings: [
        {
          title: '빠른 학습자',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
        {
          title: '일반 학습자',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
        {
          title: '느린 학습자',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
      ],
      unitSettings: [
        {
          title: '빠른 학습자',
          ref: '상위',
          percent: '10%',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
        {
          title: '일반 학습자',
          ref: '상위',
          percent: '10%',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
        {
          title: '느린 학습자',
          ref: '상위',
          percent: '10%',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험 콘텐츠', isSelect: false},
            {name: '실감 콘텐츠', isSelect: false},
            {name: '평가만', isSelect: false},
          ],
        },
      ],
    };
  },
}
</script>

<style scoped lang="scss">
</style>
