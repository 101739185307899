<template>
    <div class="jumbotron-wrap">
        <div class="base-container">
            <h1>{{ title }}</h1>
            <h2>{{ subTitle }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Jumbotron',
    props: ['title', 'subTitle'],
}
</script>

<style scoped lang="scss">
    .jumbotron-wrap {
        widows: 100%;
        display: flex;
        flex-direction: column;
        padding: 76px 0 65px;
        background: url(~@/assets/images/jumbotron-bg.png) no-repeat;
        background-size: cover;
        .base-container {
            flex-direction: column;
        }
        h1 {
            font-weight: 700;
            font-size: 34px;
            color: white;
        }
        h2 {
            margin-top: 35px;
            font-weight: 700;
            font-size: 20px;
            color: white;
        }
    }
</style>