<template>
  <div :class="['popup-wrap', 'ai-assistant-popup-wrap', { hide: !this.isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <div class="pb-tab-list">
          <div class="tab-item">
            <img src="@/assets/images/diagnosis-result.png" alt="">
            <span>학습 진단</span>
          </div>
          <div class="tab-item">
            <img src="@/assets/images/learning-statistics.png" alt="">
            <span>학급 통계</span>
          </div>
          <div class="tab-item">
            <img src="@/assets/images/questions-notifications.png" alt="">
            <span>토론&채팅</span>
          </div>
        </div>
        <div class="pb-divider"></div>
        <div class="pb-chat-list">
          <div class="cl-item">
            <div class="mascot-img-wrap">
              <img src="@/assets/images/sduck-mascot.png" alt="">
              <span>AI 에스덕</span>
            </div>
            <div class="chat-box">
              <p>AI 에스덕  : <br>선생님, 과제 미제출 학생을 확인해주세요.</p>
            </div>
          </div>
          <div class="cl-item">
            <div class="chat-box">
              <p>미제출 학생에게 메시지를 보낼까요?</p>
            </div>
          </div>
          <div class="cl-item">
            <div class="chat-box">
              <p>학습이 부족한 학생들이 있습니다. 확인해주세요.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
  },
  components: {
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    return {
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
}
</script>
