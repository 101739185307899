import dateFormat from 'dateformat'

export function getServerMediaPath(path) {
    let serverUrl = process.env.VUE_APP_IS_LOCAL === 'true' ? process.env.VUE_APP_LOCAL_SERVER_URL : process.env.VUE_APP_RELEASE_SERVER_URL;
    let mediaUrl = path;
    if (serverUrl.substr(serverUrl.length - 1) == '/' && mediaUrl.substr(0, 1) == '/') {
        mediaUrl = mediaUrl.substr(1, mediaUrl.length);
    }
    return `${serverUrl}/${mediaUrl}`;
}

export function getDateByServer(dateStr, format = 'yyyy-mm-dd') {
    return dateFormat(dateStr, format)
}

export function getWorksheetGradeByWorksheet(worksheet) {
    let grade = ''

    if (worksheet) {
        const schoolType = worksheet.school_type
        const schoolYear = worksheet.school_year
        const subject = worksheet.subject
        if (schoolType.includes('초')) {
            grade = `초${schoolYear}`
        } else if (schoolType.includes('중')) {
            grade = `중${schoolYear}`
        } else {
            grade = subject
        }
    }

    return grade
}

export function getSimpleGrade(schoolType, schoolYear) {
    let parseSchoolType = '';
    if (schoolType !== null) {
        if (schoolType.includes('초')) {
            parseSchoolType = '초';
        } else if (schoolType.includes('중')) {
            parseSchoolType = '중';
        } else {
            parseSchoolType = '고';
        }
    }

    if (schoolYear === null) {
        return parseSchoolType;
    }
    let parseSchoolYear = schoolYear;
    if (Number.isInteger(parseSchoolYear)) {
        parseSchoolYear = parseSchoolYear.toString();
    }
    parseSchoolYear = parseSchoolYear.replace(/학년/g, '');

    return `${parseSchoolType}${parseSchoolYear}`;
}

export function getWorkbookGradeByWorkbook(workbook) {
    let grade = ''

    if (workbook) {
        const schoolType = workbook.school_type
        const schoolYear = workbook.school_year
        const subject = workbook.subject
        if (schoolType.includes('초')) {
            grade = `초${schoolYear}`
        } else if (schoolType.includes('중')) {
            grade = `중${schoolYear}`
        } else {
            grade = subject
        }
    }

    return grade
}

export function getSchoolTypeByGradeList(selectGrade) {
    const schoolTypes = ['초등학교', '중학교', '고등학교']

    switch (selectGrade) {
        case '초1':
        case '초2':
        case '초3':
        case '초4':
        case '초5':
        case '초6':
            return schoolTypes[0]
        case '중1':
        case '중2':
        case '중3':
            return schoolTypes[1]
            default:
            return schoolTypes[2]
    }
}

export function getSchoolYearByGradeList(selectGrade) {
    const schoolYears = [
        '1학년',
        '2학년',
        '3학년',
        '4학년',
        '5학년',
        '6학년',
    ]

    switch (selectGrade) {
        case '초1':
            return schoolYears[0]
        case '초2':
            return schoolYears[1]
        case '초3':
            return schoolYears[2]
        case '초4':
            return schoolYears[3]
        case '초5':
            return schoolYears[4]
        case '초6':
            return schoolYears[5]
        case '중1':
            return schoolYears[0]
        case '중2':
            return schoolYears[1]
        case '중3':
            return schoolYears[2]
        default:
            return null
    }
}

export function getSubjectByGradeList(selectGrade) {
    switch (selectGrade) {
        case '초1':
        case '초2':
        case '초3':
        case '초4':
        case '초5':
        case '초6':
        case '중1':
        case '중2':
        case '중3':
            return null
        default:
            return selectGrade
    }
}

export async function makeProblemPreviewToPdf(action=null, type, event) {
    console.log(`makeProblemPreviewToPdf() start - action: ${action}, type: ${type}, event: ${event}`);

    const pageDivs = document.querySelectorAll('.wrap-abs .wrap-scroll')
    const pages = []
    
    for (let pageIdx = 0; pageIdx < pageDivs.length; pageIdx++) {
        const page = pageDivs[pageIdx]

        if (type !== 'report') {
            if (pageIdx === pageDivs.length - 1) {
                pages.unshift(page)
            } else if (pageIdx !== 0) {
                pages.push(page)
            }
        } else {
            pages.push(page)
        }
    }

    const firstPage = pages[0]
    const HTML_Width = parseInt(firstPage.scrollWidth)
    const HTML_Height = parseInt(firstPage.scrollHeight)
    const padding = 5
    const PDF_Width = HTML_Width + padding
    const PDF_Height = HTML_Height + padding
    const pdf = window.jsPDF('p', 'pt', [PDF_Width, PDF_Height])

    console.log(`HTML_Width: ${HTML_Width}`);
    console.log(`HTML_Height: ${HTML_Height}`);
    console.log(`PDF_Width: ${PDF_Width}`);
    console.log(`PDF_Height: ${PDF_Height}`);

    for (let pageIdx = 0; pageIdx < pages.length; pageIdx++) {
        const page = pages[pageIdx]

        const canvas = await window.html2canvas(page, { 
            scale: 2,
            logging: true, 
            useCORS: true,
            width: PDF_Width,
            height: PDF_Height,
        })

        let imgData = canvas.toDataURL("image/jpeg");
        if (pageIdx !== 0) {
            console.log('pdf - addPage()');
            pdf.addPage();
        }
        console.log('pdf - addImage()');
        pdf.addImage(
            imgData,
            'JPEG',
            Math.abs(HTML_Width - PDF_Width),
            Math.abs(HTML_Height - PDF_Height),
            HTML_Width,
            HTML_Height
        );
    }

    console.log('makeProblemPreviewToPdf() end');
    if (action?.isGetBlob) {
        return new Blob([pdf.output('blob')], {type: 'application/pdf'});
    } else if (action?.saveName) {
        pdf.save(`${action.saveName}.pdf`);
    }

    return pdf
}

export function numberFormat(val) {
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
