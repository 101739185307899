<template>
  <div class="mypage-tab-body-wrap">
    <Filter
        :filterData="this.filterData"
    />

    <TreeList
        :nodes="this.nodes"
    />
  </div>

  <State
      :stateData="this.stateData"
  />
</template>

<script>
import ProblemService from '@/services/problem.service'
import { debounce } from 'lodash'
import models from '@/models'
import Filter from "@/components/Filter.vue";
import TreeList from "@/components/TreeList.vue";
import State from "@/components/state.vue";

export default {
  name: 'EditStep1Unit',
  components: {
    Filter,
    TreeList,
    State,
  },
  props: {
    isWorkbook: Boolean,
    onChangeProblems: Function,
    onChangeSearchProblems: Function,
    limitProblemCnt: { type: Number, default: null },
  },
  watch: {
    problems() {
      this.onChangeProblems(this.problems)
    },
    searchProblems() {
      this.onChangeSearchProblems(this.searchProblems)
    },
    limitProblemCnt() {
      const limitProblemCnt = this.limitProblemCnt;
      if (limitProblemCnt != null) {
        this.selectProblemCount = `${limitProblemCnt}`;
      }
    },
  },
  data() {
    return {
      filterData: {
        title: this.isWorkbook ? '교재 선택' : '학습지 선택',
        list: [
          {
            type: 'select',
            addClass: 'w-105px',
            name: '선택',
            options: ['중학교', '고등학교'].map(i => {return {'value': i, 'name': i}}),
            onChange: this.onChangeUnitDataSchoolType
          },
        ],
        searchInput: {isHidden: true},
      },

      nodeKeyStates: [],
      nodes: {},

      problemCounts: models.problemCountList(true),
      selectProblemCount: '250',
      maxProblemSlider: 100,
      types: models.problemTypeList(),
      selectType: ['전체'],
      solutionTypes: models.problemSolutionType(),
      selectSolutionTypes: [],
      difficulties: models.difficultyList(),
      selectDifficulty: [],

      searchProblems: [],
    }
  },
  mounted() {
    if (!this.isWorkbook) {
      this.selectProblemCount = '25'
      this.problemCounts = models.problemCountList()
    }

    this.maxProblemSlider = parseInt(this.problemCounts[this.problemCounts.length - 1])
  },
  methods: {
    onSliderOverMax: debounce(async (appContext, limitProblemCnt) => {
      appContext.$toast.error(`문항 수 ${limitProblemCnt}개까지 제한된 계정입니다`);
      appContext.selectProblemCount = 0;
      appContext.selectProblemCount = limitProblemCnt;
    }, 200),
    reloadUnitProblems: debounce(async (appContext) => {
      appContext.problems = [];
      const checkedKeys = appContext.nodeKeyStates.filter(n => n.state.checked && n.isLower).map(i => i.id);

      if (checkedKeys.length > 0) {
        const res = await ProblemService.getProblems({
          'pattern_id_list': checkedKeys,
          'is_order_by_random': true,
          'include_my_problems': true,
        });
        if (res) {
          appContext.searchProblems = res;
          return;
        }
      }

      appContext.searchProblems = [];
    }, 200),
    onChangeUnitDataSecondSelect(value) {
      this.filterData.list[2].options.forEach(i => i.isSelected = i.value == value)

      this.reloadUnitDataNodes()
    },
    onChangeUnitDataSchoolType(value) {
      const filterList = this.filterData.list
      const firstOptions = filterList[0].options
      firstOptions.forEach(c => c.isSelected = false)
      const schoolType = firstOptions.find(s => s.value == value)
      if (schoolType) {
        schoolType.isSelected = true
      }

      let secondOptions = []
      switch (schoolType.value) {
        case '중학교':
          secondOptions = Array.apply({}, Array(3 * 2)).map((_, index) => {
            const value = `${Math.floor(index / 2 + 1)}-${index % 2 + 1}`
            return {'value': value, 'name': value, 'isSelected': false}
          })
          break
        case '고등학교':
          secondOptions = models.subjectList().map((i, index) => {
            return {'value': index, 'name': i, 'isSelected': false}
          })
          break
      }
      const secondSelect = {
        type: 'select',
        addClass: 'w-105px',
        name: '선택',
        options: secondOptions,
        onChange: this.onChangeUnitDataSecondSelect,
      }

      if (filterList.length == 1) {
        filterList.push({type: 'select-arrow'})
        filterList.push(secondSelect)
      } else {
        filterList[2] = secondSelect
      }

      this.reloadUnitDataNodes()
    },
    onChangeUnitDataSelectNode() {
      setTimeout(() => {
        const keyStates = this.nodeKeyStates
        const nodes = this.nodes

        Object.entries(nodes).forEach(entry => {
          const nodeKey = entry[0]
          const nodeValue = entry[1]

          const foundKeyIndex = keyStates.findIndex(k => k.key == nodeKey)
          const storeKeyState = {
            'state': Object.assign({}, nodeValue.state),
            'key': nodeKey,
            'id': nodeValue.obj.id,
            'isLower': (nodeValue.children?.length ?? 0) == 0,
          }
          if (foundKeyIndex > -1) {
            keyStates[foundKeyIndex] = storeKeyState
          } else {
            keyStates.push(storeKeyState)
          }
        })

        this.reloadUnitProblems(this)
      }, 0);
    },
    async reloadUnitDataNodes() {
      const filterList = this.filterData.list

      const schoolType = filterList[0].options.find(s => s.isSelected)
      const secondOption = filterList[2].options.find(s => s.isSelected)

      this.nodes = {}

      if (schoolType && secondOption) {
        let schoolYear, term, subject

        if (schoolType.value == '고등학교') {
          subject = secondOption.name
        } else {
          schoolYear = secondOption.value.split('-')[0]
          term = secondOption.value.split('-')[1]
        }

        let res = await ProblemService.getProblemPatterns({
          'school_type': schoolType?.value,
          'school_year': schoolYear,
          'term': term,
          'problem_subject': subject,
          'is_node_list': true,
        })
        if (res) {
          Object.entries(res).forEach(entry => {
            const key = entry[0]
            const value = entry[1]

            value.onChecked = this.onChangeUnitDataSelectNode
            value.onUnchecked = this.onChangeUnitDataSelectNode

            const foundBeforeState = this.nodeKeyStates.find(k => k.key == key)
            if (foundBeforeState) {
              value.state = Object.assign({}, foundBeforeState.state)
            }
          })
          this.nodes = res
        }
      }
    },
  },
  computed: {
    stateData() {
      return {
        items: [
          {
            id: 0,
            type: 'button',
            title: '문항 수',
            subTitle: `${this.selectProblemCount}`,
            list: this.problemCounts,
            value: this.selectProblemCount,
            slider: {
              isVisible: true,
              minValue: 0,
              maxValue: this.maxProblemSlider,
            },
            onChange: (id, value) => {
              if (this.selectProblemCount != value) {
                const limitProblemCnt = this.limitProblemCnt;
                if (limitProblemCnt != null && value > limitProblemCnt) {
                  this.onSliderOverMax(this, limitProblemCnt);
                  return;
                }

                this.selectProblemCount = value
              }
            },
          },
          {
            id: 1,
            type: 'button',
            title: '문제 타입',
            list: this.types,
            listClass: 'grid-3',
            value: this.selectType,
            onChange: (id, value) => {
              if (value == '전체') {
                this.selectType = ['전체']
              } else {
                const allItemIdx = this.selectType.findIndex(i => i == '전체')
                if (allItemIdx > -1) {
                  this.selectType.splice(allItemIdx, 1)
                }

                const targetItemIdx = this.selectType.findIndex(i => i == value)
                if (targetItemIdx > -1) {
                  if (this.selectType.length == 1) {
                    this.selectType = ['전체']
                  } else {
                    this.selectType.splice(targetItemIdx, 1)
                  }
                } else {
                  this.selectType.push(value)
                }
              }
            },
          },
          {
            id: 2,
            type: 'button',
            title: '풀이형',
            list: this.solutionTypes,
            listClass: 'grid-3',
            value: this.selectSolutionTypes,
            onChange: (id, value) => {
              const targetItemIdx = this.selectSolutionTypes.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.selectSolutionTypes.splice(targetItemIdx, 1)
              } else {
                this.selectSolutionTypes.push(value)
              }
            },
          },
          {
            id: 3,
            type: 'button',
            title: '난이도',
            list: this.difficulties,
            listClass: 'grid-5',
            value: this.selectDifficulty,
            onChange: (id, value) => {
              const targetItemIdx = this.selectDifficulty.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.selectDifficulty.splice(targetItemIdx, 1)
              } else {
                this.selectDifficulty.push(value)
              }
            },
          },
        ],
        resItems: [
          {type: 'text', title: '문제수', value: `${Number(this.problems.length)}개`},
          {type: 'text', title: '적용된 유형수', value: `${Number(this.applyProblemPatterns.length)}개`},
        ],
      }
    },
    applyProblemPatterns() {
      return this.problems.reduce(
          (acc, cur) => {
            if (!acc.includes(cur.pattern_name)) {
              acc.push(cur.pattern_name)
            }
            return acc
          },
          []
      )
    },
    problems() {
      let problems = this.searchProblems.filter(i => {
        let result = true

        const types = this.selectType.filter(i => i != '전체')
        if (result && types.length > 0) {
          result = types.includes(i.type)
        }

        if (result && this.selectSolutionTypes.length > 0) {
          result = this.selectSolutionTypes.includes(i.solution_type)
        }

        if (result && this.selectDifficulty.length > 0) {
          result = this.selectDifficulty.includes(i.difficulty)
        }

        return result
      }).map(i => Object.assign({}, i))

      let limitCount = this.selectProblemCount
      if (limitCount !== null) {
        limitCount = parseInt(limitCount)
        if (problems.length > limitCount) {
          problems.splice(limitCount, problems.length)
        }
      }

      return problems
    },
  },
}
</script>
