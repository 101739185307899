<template>
    <div class="mypage-teacher-wrap">
        <div class="container-1200px">
            <div class="mypage-list-wrap">
                <div :class="['mypage-tab-wrap']">
                    <div 
                        v-for="(tab, index) in tabs" 
                        :key="index"
                        :class="['tab-item', {'active': selectTabIndex == index}]"
                        @click="() => { onChangeTab(index) }">
                        {{ tab.title }}
                    </div>
                </div>

                <OperationTeacher 
                    v-if="selectTabIndex == 0"
                    :academyInfo="academyInfo"
                    />

                <OperationStudent 
                    v-if="selectTabIndex == 1"
                    :academyInfo="academyInfo"
                    />

                <OperationParents 
                    v-if="selectTabIndex == 2"
                    :academyInfo="academyInfo"
                    />

                <OperationClass 
                    v-if="selectTabIndex == 3"
                    :academyInfo="academyInfo"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import OperationClass from '../../../../components/OperationClass.vue'
import OperationStudent from '../../../../components/OperationStudent.vue'
import OperationTeacher from '../../../../components/OperationTeacher.vue'
import OperationParents from '../../../../components/OperationParents.vue'
import academyService from '../../../../services/academy.service'

export default {
    name: 'MypageTeacherManageOperation',
    components: {
    OperationTeacher,
    OperationStudent,
    OperationParents,
    OperationClass,
},
    data() {
        return {
            selectTabIndex: 0,
            tabs: [
                {title: '선생님 관리'},
                {title: '학생 관리'},
                {title: '학부모 관리'},
                {title: '반 관리'},
            ],

            academyInfo: null,
        }
    },
    mounted() {
        this.onChangeTab(this.selectTabIndex)
        this.initData()
    },
    methods: {
        async initData() {
            this.academyInfo = await academyService.getMyAcademy()
        },
        onChangeTab(index) {
            this.selectTabIndex = index
        },
    },
}
</script>