<template>
  <div id="container">
    <div class="info-wrap">
      <img src="@/assets/images/min-window-info-icon.png" alt=""/>
      <h1>모바일 앱 안내</h1>
      <p>해당 앱은 아래 버튼을 통해 각 기기에 맞는 스토어로 이동하여 다운받아 사용할 수 있습니다.</p>
      <br/>
      <a class="redirect-link"
         href="https://play.google.com/store/apps/details?id=com.sduck.app.sduck_mobile"
      >PlayStore</a>
      <a class="redirect-link"
         href="https://apps.apple.com/app/%EC%97%90%EC%8A%A4%EB%8D%95/id6455375105"
      >AppStore</a>

      <button
          class="logout-btn"
          @click="this.signOutEvent"
      >
        로그아웃
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    signOutEvent() {
      this.signout()
      this.$router.push({ name: 'login' })
    },
    ...mapActions('auth', ['signout']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.info-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  img {
    width: 26px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    color: rgb(112, 112, 112);
    text-align: center;
    word-break: keep-all;
  }
  .redirect-link {
    cursor: pointer;
    background-color: #{$defaultColor};
    color: white;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.25);
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 8px;
  }
  .logout-btn {
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: black;
  }
}
</style>
