<template>
  <div class="list-item">
    <div :class="['expand-btn-wrap', {'is-expand': this.isExpand}]">
      <div class="expand-btn"
           @click="() => this.onToggleExpand(!this.isExpand)"
      >
        <div class="expand-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" data-svg-name="dropdown_arrow_up" class="is-animate degree-90 css-he2h7v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(192, 192, 192);"><path fill="#000" d="M16.586 15.5c.89 0 1.337-1.077.707-1.707l-4.586-4.586c-.39-.39-1.024-.39-1.414 0l-4.586 4.586c-.63.63-.184 1.707.707 1.707h9.172z"></path></svg>
        </div>
        <div class="category-name-wrap">
          {{ this.group.groupName }}
        </div>
        <div class="item-cnt-wrap">
          {{ this.group.academyUsers.length }}명
        </div>
      </div>
      <div class="group-add-icon"
           @click="() => this.onClickGroupRightBtn()"
      >
        <template v-if="this.isAddBtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(0, 171, 255);"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
        </template>
        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="remove_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(51, 51, 51);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm3.334 7.334H4.667V7.333h6.667v1.334z"></path></svg>
        </template>
      </div>
    </div>
    <p
        v-for="(academyUser, subIndex) in this.group.academyUsers" :key="subIndex"
        :class="['user-item', {'is-expand': this.isExpand}]"
        @click="() => onClickAcademyUser(academyUser)"
    >
      <span>{{ academyUser.name ?? '' }}</span>
      <template v-if="this.isAddBtn">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(0, 171, 255);"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
      </template>
      <template v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" data-svg-name="remove_fill" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(51, 51, 51);"><path fill="silver" d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm3.334 7.334H4.667V7.333h6.667v1.334z"></path></svg>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TakeTestPopupListItem',
  props: {
    group: Object,
    onToggleExpand: Function,
    onClickGroupRightBtn: Function,
    onClickAcademyUser: Function,
    isAddBtn: {type: Boolean, default: true},
    isExpand: {type: Boolean, default: false},
  },
}
</script>

<style lang="scss" scoped>
.list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-items: flex-start;
  .user-item {
    display: flex;
    width: 100%;
    height: 0;
    padding: 0 10px 0 24px;
    background-color: rgb(255, 255, 255);
    border-bottom-width: 0;
    border-bottom-style: solid;
    border-bottom-color: rgb(232, 232, 232);
    transition: all .2s ease-in-out;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    span {
      display: -webkit-box;
      overflow: hidden;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    svg {
      width: 15px;
      height: 15px;
      transform: rotate(180deg);
      margin-left: auto;
    }
    &:hover {
      background-color: rgb(245, 245, 245);
    }
    &.svg-rotate {
      svg {
        transform: rotate(0);
        margin-left: 0;
        margin-right: 10px;
      }
    }
    &.is-expand {
      height: 48px;
      border-bottom-width: 1px;
    }
  }
  .expand-btn-wrap {
    width: 100%;
    display: flex;
    height: 48px;
    align-items: center;
    background-color: rgb(250, 250, 250);
    .expand-btn {
      flex: 1;
      height: 100%;
      cursor: pointer;
      padding: 0 10px 0 24px;
      display: flex;
      align-items: center;
      overflow: hidden;
      .expand-icon {
        margin-right: 10px;
        svg {
          transition: all .2s ease-in-out;
          transform: rotate(90deg);
        }
      }
      .category-name-wrap {
        flex: 1;
        color: rgb(51, 51, 51);
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-cnt-wrap {
        padding-left: 10px;
        color: rgb(51, 51, 51);
        font-size: 14px;
        white-space: nowrap;
      }
    }
    .group-add-icon {
      align-items: center;
      padding: 0 10px 0 10px;
      height: 100%;
      display: flex;
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &.is-expand {
      .expand-btn {
        .expand-icon {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
