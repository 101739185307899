<template>
  <div :class="['home-popup-wrap', { hide: !isVisible || isIgnore }]">
    <div class="popup-shadow" @click="this.onCloseEvent"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        <button class="close-btn" @click="this.onCloseEvent">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <template v-if="popup.link_url">
          <a class="img-wrap"
             :href="popup.link_url"
             target="_blank">
            <img :src="utils.getServerMediaPath(popup.img)" alt="">
          </a>
        </template>
        <template v-else>
          <div class="img-wrap">
            <img :src="utils.getServerMediaPath(popup.img)" alt="">
          </div>
        </template>
      </div>

      <div class="popup-footer">
        <CustomCheckbox
            :class="['filter-cb']"
            :onChange="(checked) => { this.onClick7daysBtn() }"
            title="일주일간 다시 보지 않기"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/CustomCheckbox.vue";
import Image from "@/components/Image.vue";
import utils from "@/utils";

export default {
  computed: {
    utils() {
      return utils
    }
  },
  components: {Image, CustomCheckbox},
  props: {
    isVisible: Boolean,
    onClose: Function,
    popup: Object,
  },
  data() {
    return {
      isIgnore: false,
    };
  },
  watch: {
    isVisible() {
      this.checkIgnore();
    },
  },
  mounted() {
    this.checkIgnore();
  },
  methods: {
    checkIgnore() {
      const popupIgnore = sessionStorage.getItem(`popup_${this.popup.id}`);
      if (popupIgnore) {
        this.isIgnore = true;
      } else {
        const ignoreDateStr = localStorage.getItem(`popup_${this.popup.id}`);
        if (ignoreDateStr) {
          const ignoreDate = new Date(ignoreDateStr);
          const nowDt = new Date();
          if (nowDt <= ignoreDate) {
            this.isIgnore = true;
          }
        }
      }
    },
    addSession() {
      sessionStorage.setItem(`popup_${this.popup.id}`, '1');
    },
    onCloseEvent() {
      this.addSession();
      this.onClose();
    },
    onClick7daysBtn() {
      const ignoreDate = new Date();
      ignoreDate.setDate(ignoreDate.getDate() + 7);
      const dtStr =
          `${ignoreDate.getFullYear()}-`
          +`${(ignoreDate.getMonth() + 1).toString().padStart(2, '0')}-`
          +`${ignoreDate.getDate().toString().padStart(2, '0')} `
          +`${ignoreDate.getHours().toString().padStart(2, '0')}:`
          +`${ignoreDate.getMinutes().toString().padStart(2, '0')}:`
          +`${ignoreDate.getSeconds().toString().padStart(2, '0')}`;
      localStorage.setItem(`popup_${this.popup.id}`, dtStr);

      this.onClose();
    },
  },
}
</script>
