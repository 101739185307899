import studentWorkService from '../services/studentWork.service';
import {PdfType} from "@/utils/enums";

export async function onClickWorksheetHomework(academyUserId, worksheetId, appContext) {
    if (worksheetId) {
        try {
          await studentWorkService.createStudentWork({
            'academy_user_id_list': [academyUserId],
            'worksheet_id': worksheetId,
            'is_homework': true,
          })

          appContext.$toast.success('숙제를 출제하였습니다')
        } catch (e) {
          console.log(e);
          if (e?.response?.status != 400) {
            appContext.$toast.error('숙제 출제 중 오류가 발생하였습니다')
          }
        }
    } else {
        appContext.$toast.error('학습지 정보를 찾을 수 없습니다')
    }
}

export function getPreviewUrl(
    context,
    event, // preview, download
    type,
    id,
) {
    if (type == PdfType.worksheet || type == PdfType.workbook || type == PdfType.academyReport) {
        return context.$router.resolve({
            name: 'pdfViewer',
            params: {
                event: event,
                type: type,
                id: id,
            },
        }).href;
    } else if (type == PdfType.studentWork) {
        return context.$router.resolve({
            name: 'studentWorkPreview',
            params: {
                studentWorkId: id,
                event: event,
            },
        }).href;
    }

    return null;
}

export function previewLink(context, event, type, id) {
    const url = getPreviewUrl(context, event, type, id);
    if (url) {
        window.open(url, '_blank');
    } else {
        this.$toast.error('URL을 불러오지 못했습니다');
    }
}

export function back() {
    if (history.length <= 1) {
        window.close()
    } else {
        window.history.back()
    }
}

export function downloadUrl(url, downloadFileName) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = downloadFileName;
            link.click();
        })
        .catch(error => {
            console.error("Failed to download file error: ", error);
            this.$toast.error('다운로드 중 오류가 발생하였습니다');
        });
}

export function debugLog(msg) {
    const isDebug = process.env.VUE_APP_IS_LOCAL === 'true';
    if (isDebug) {
        console.log(msg);
    }
}
