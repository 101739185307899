import api from "./api"

class CommonService {
  getNotices() {
    return api.get('/view_set/notice/')
  }

  getFaqs() {
    return api.get('/view_set/faq/')
  }

  getEvents() {
    return api.get('/view_set/event/')
  }

  getFreeRegisterMaxCount() {
    return api.get('/view_set/free_register_max_count/')
  }

  createApplyFeeExp(payload) {
    return api.post('/view_set/apply_fee_exp/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getOpenPopups() {
    return api.get('/common/get_open_popup/')
  }

  getPdfFile(eventType, targetId) {
    return api.get(`/common/pdf_file/${eventType}/${targetId}/`);
  }

  getGlobalSetting() {
    return api.get('/common/global_setting');
  }
}

export default new CommonService()
