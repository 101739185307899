<template>
  <div :class="['file-input', addClass]">
    <input
      ref="fileInput"
      type="file"
      class="hide"
      @change="onFileChange"
      :readonly="isReadOnly"
      :disabled="isDisabled"
      :accept="accept"
      >
    
    <button
      @click="trigger"
      >파일 선택</button>
    
    <span class="text-line-1">
      {{ fileName }}
    </span>
  </div>
</template>
  
<script>
  export default {
    name: 'FileInput',
    props: {
      accept: String,
      addClass: String,
      onChange: Function,
      initFileName: String,
      propFile: null,
      isReadOnly: {type: Boolean, default: false},
      isDisabled: {type: Boolean, default: false},
    },
    data() {
      return {
        file: null,
      }
    },
    methods: {
      onFileChange: function(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (files.length > 0) {
          this.file = files[0];
          this.onChange(this.file);
        }
      },
      trigger: function() {
        const inputEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true
        });
        this.$refs.fileInput.dispatchEvent(inputEvent);
      },
    },
    computed: {
      fileName: function () {
        if (this.file !== null && this.file.name) {
          return this.file.name
        } else if (this.propFile !== undefined && this.propFile !== null && this.propFile.name) {
          return this.propFile.name
        } else {
          return this.initFileName ?? '(선택된 파일 없음)'
        }
      },
    },
  }
</script>
  
<style lang="scss" scoped>
.file-input {
  width: 100%;
  display: flex;
  align-items: center;
  input {
      display: none;
  }
  button {
      background: linear-gradient(180deg, rgba(206, 206, 206, 0) 0%, #CECECE 100%);
      border: 1px solid #A3A3A3;
      border-radius: 5px;
      width: 130px;
      height: 30px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-right: 16px;
  }
  span {
      max-width: 350px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
  }
}
</style>