<template>
  <div :class="['popup-wrap', 'analysis-detail-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>분수의 나눗셈</h1>
        <div class="header-graph-list">
          <div class="gl-item gl-learning-rate-wrap">
            <h5>학습 참여율</h5>
            <div class="graph-line-wrap">
              <div class="graph-wrap">
                <div class="graph-outer-wrap">
                  <div class="graph-inner-wrap">
                    <DoughnutChart
                        :chartData="this.learningRateChartData"
                        :options="this.learningRateOption"
                        :style="[{'aspect-ratio': '1'}]"
                    />
                  </div>
                </div>
              </div>
              <div class="graph-desc-wrap">
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>참여</h5>
                  <p>9명</p>
                  <span>(90%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>미참여</h5>
                  <p>1명</p>
                  <span>(10%)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="gl-item gl-learning-state-wrap">
            <h5>학습 상태</h5>
            <div class="graph-line-wrap">
              <div class="graph-wrap">
                <div class="graph-outer-wrap">
                  <div class="graph-inner-wrap">
                    <DoughnutChart
                        :chartData="this.learningStatusChartData"
                        :options="this.learningStatusChartOption"
                        :style="[{'aspect-ratio': '1'}]"
                    />
                  </div>
                </div>
              </div>
              <div class="graph-desc-wrap">
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>성취 완료</h5>
                  <p>5명</p>
                  <span>(50%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>학습 진행중</h5>
                  <p>2명</p>
                  <span>(20%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>부족한 부분 발견</h5>
                  <p>2명</p>
                  <span>(20%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>학습 이력 없음</h5>
                  <p>1명</p>
                  <span>(10%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vulnerable-wrap">
          <h2>🚨 취약한 학습 요소</h2>
          <div class="vw-list-wrap">
            <template v-for="(vulnerableItem, index) in this.vulnerableItems" :key="index">
              <div class="list-item">
                <p><strong>{{ vulnerableItem.studentCnt }}</strong>명의 학생이 취약해요</p>
                <span>{{ vulnerableItem.title }}</span>
              </div>
            </template>
          </div>
        </div>
        <template v-for="(listItem, index) in [
          this.noLearningHistoryStudents,
          this.discoveredMissingStudents,
          this.learningProgressStudents,
        ]" :key="index">
          <div class="student-list-wrap">
            <div class="sl-title-wrap">
              <div class="slt-icon" :style="[{'background-color': index === 0 ? '#D9D9D9' : index === 1 ? '#FF6854' : '#008ED5'}]"></div>
              <p>{{ index === 0 ? '학습 이력 없음' : index === 1 ? '부족한 부분 발견' : '학습 진행 중' }} ({{ listItem.length }}명)</p>
            </div>
            <div class="sl-list-wrap">
              <template v-for="(studentItem, index) in listItem" :key="index">
                <div class="sll-item">
                  <img class="item-img" src="@/assets/images/smile.png" alt="">
                  <p>{{ studentItem.name }}</p>
                  <span>{{ studentItem.problemCnt }}문항</span>
                  <img class="item-arrow" src="@/assets/images/arrow-right-5.png" alt="">
                </div>
              </template>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import {DoughnutChart} from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    DoughnutChart
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    return {
      vulnerableItems: [
        {studentCnt: Math.floor(Math.random() * 11), title: '(대분수) ÷ (자연수)'},
        {studentCnt: Math.floor(Math.random() * 11), title: '분자가 자연수의 배수인 (분수) ÷ (자연수)'},
        {studentCnt: Math.floor(Math.random() * 11), title: '(분수) ÷ (자연수) 를 분수의 곱셈으로 나타내어 계산'},
      ],
      noLearningHistoryStudents: [
        {name: '권현우', problemCnt: 0},
      ],
      discoveredMissingStudents: [
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
      ],
      learningProgressStudents: [
        {name: '권현우', problemCnt: Math.floor(Math.random() * 101)},
      ],
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
  computed: {
    learningRateChartData() {
      return {
        labels: [
          '참여',
          '미참여',
        ],
        datasets: [
          {
            data: [9, 1],
            backgroundColor: ['#008ED5', '#A4E1FF'],
          },
        ],
      };
    },
    learningRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    learningStatusChartData() {
      return {
        labels: [
          '성취 완료',
          '학습 진행중',
          '부족한 부분 발견',
          '학습 이력 없음',
        ],
        datasets: [
          {
            data: [
              5,
              2,
              2,
              1,
            ],
            backgroundColor: [
              '#006EA5',
              '#008ED5',
              '#FF6854',
              '#797979',
            ],
          },
        ],
      };
    },
    learningStatusChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
  },
}
</script>
