import { createWebHistory, createRouter } from "vue-router"
import Home from "./pages/Home.vue"
import SupportWorkbook from "./pages/SupportWorkbook.vue"
import PriceInfo from "./pages/PriceInfo.vue"
import PriceInfoPayment from "./pages/PriceInfoPayment.vue"
import Login from "./pages/Login.vue"
import CallbackNaver from "./pages/CallbackNaver.vue"
import Support from "./pages/Support.vue"
import Event from "./pages/Event.vue"
import Signup from "./pages/Signup.vue"
import SignupTeacher from "./pages/SignupTeacher.vue"
import SignupStudent from "./pages/SignupStudent.vue"
import SignupParents from "./pages/SignupParents.vue"
import MypageTeacherClassPreWorksheet from "./pages/mypage/teacher/class-pre/Worksheet.vue"
import WorksheetEdit from "./pages/mypage/teacher/class-pre/WorksheetEdit.vue"
import MypageTeacherClassPreWorkbook from "./pages/mypage/teacher/class-pre/Workbook.vue"
import MypageTeacherClassPreRecommendByTheme from "./pages/mypage/teacher/class-pre/RecommendByTheme.vue"
import MypageTeacherLessonHistoryLearningHistory from "./pages/mypage/teacher/lesson-report/LearningHistory.vue"
import MypageTeacherLessonHistoryReport from "./pages/mypage/teacher/lesson-report/Report.vue"
import MypageTeacherManageOperation from "./pages/mypage/teacher/manage/Operation.vue"
import MemberRequest from "./pages/MemberRequest.vue"
import store from "./store"
import app from "./main"
import PersonalTerms from "./pages/PersonalTerms.vue"
import ServiceTerms from "./pages/ServiceTerms.vue"
import RequireAcademyVerifyInfo from "./components/RequireAcademyVerifyInfo.vue"
import PdfViewer from "@/pages/PdfViewer.vue";
import MyProblemEdit from "@/pages/mypage/teacher/class-pre/MyProblemEdit.vue";
import MyProblemList from "@/pages/mypage/teacher/class-pre/MyProblemList.vue";
import StudentWorkPreview from "@/pages/StudentWorkPreview.vue";
import FindEmail from "@/pages/FindEmail.vue";
import FindPassword from "@/pages/FindPassword.vue";
import RedirectStore from "@/pages/mypage/RedirectStore.vue";
import TeacherDashboard from "@/pages/teacher/TeacherDashboard.vue";
import TeacherLesson from "@/pages/teacher/TeacherLesson.vue";
import StudentDashboard from "@/pages/student/StudentDashboard.vue";
import V2PublishingList from "@/pages/V2PublishingList.vue";
import TeacherSelfLearningMode from "@/pages/teacher/TeacherSelfLearningMode.vue";
import StudentSelfLearningMode from "@/pages/student/StudentSelfLearningMode.vue";
import TeacherSetting from "@/pages/teacher/TeacherSetting.vue";
import StudentSelfLearningModeResult from "@/pages/student/StudentSelfLearningModeResult.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: { requiredAuth: false, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/support-workbook",
        name: "support-workbook",
        component: SupportWorkbook,
        meta: { requiredAuth: false, includeHomePublishing: true, },
    },
    {
        path: "/personal-terms",
        name: "personal-terms",
        component: PersonalTerms,
        meta: { requiredAuth: false, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/service-terms",
        name: "service-terms",
        component: ServiceTerms,
        meta: { requiredAuth: false, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/price-info",
        name: "price-info",
        component: PriceInfo,
        meta: { requiredAuth: false, includeHomePublishing: true, },
    },
    {
        path: "/price-info/payment",
        name: "PriceInfoPayment",
        component: PriceInfoPayment,
        meta: { requiredAuth: true, includeHomePublishing: true, },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/find-email",
        name: "find-email",
        component: FindEmail,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/find-password",
        name: "find-password",
        component: FindPassword,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/callback/naver",
        name: "callback-naver",
        component: CallbackNaver,
        meta: { isIgnoreWindowWidth: true, isHiddenHeader: true, isHiddenFooter: true },
    },
    {
        path: "/signup",
        name: "signup",
        component: Signup,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/signup-teacher",
        name: "signup-teacher",
        component: SignupTeacher,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/signup-student",
        name: "signup-student",
        component: SignupStudent,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/signup-parents",
        name: "signup-parents",
        component: SignupParents,
        meta: { requiredAuth: false, requiredNotAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },
    {
        path: "/member/request/:academyId",
        name: "MemberRequest",
        component: MemberRequest,
        meta: { requiredAuth: true, includeHomePublishing: true, },
    },
    {
        path: "/support",
        name: "support",
        component: Support,
        meta: { requiredAuth: false, includeHomePublishing: true, },
    },
    {
        path: "/event",
        name: "event",
        component: Event,
        meta: { requiredAuth: false, includeHomePublishing: true, },
    },
    {
        path: "/require-academy-verify-info",
        name: "RequireAcademyVerifyInfo",
        component: RequireAcademyVerifyInfo,
        meta: { requiredAuth: true, requiredMobile: true, includeHomePublishing: true, },
    },

    // mypage - teacher
    {
        path: "/mypage/teacher/class-pre/worksheet",
        name: "MypageTeacherClassPreWorksheet",
        component: MypageTeacherClassPreWorksheet,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/worksheet/edit",
        name: "WorksheetEdit",
        component: WorksheetEdit,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/workbook",
        name: "MypageTeacherClassPreWorkbook",
        component: MypageTeacherClassPreWorkbook,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/workbook/edit",
        name: "WorkbookEdit",
        component: WorksheetEdit,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/recommend-by-theme",
        name: "MypageTeacherClassPreRecommendByTheme",
        component: MypageTeacherClassPreRecommendByTheme,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/my-problem",
        name: "MyProblemList",
        component: MyProblemList,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/class-pre/my-problem/edit",
        name: "MyProblemEdit",
        component: MyProblemEdit,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/lesson-history/learning-history",
        name: "MypageTeacherLessonHistoryLearningHistory",
        component: MypageTeacherLessonHistoryLearningHistory,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/lesson-history/report",
        name: "MypageTeacherLessonHistoryReport",
        component: MypageTeacherLessonHistoryReport,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/mypage/teacher/manage/operation",
        name: "MypageTeacherManageOperation",
        component: MypageTeacherManageOperation,
        meta: { requiredAuth: true, requiredTeacher: true },
    },
    {
        path: "/student_work_preview/:studentWorkId/:event",
        name: "studentWorkPreview",
        component: StudentWorkPreview,
        meta: { isIgnoreWindowWidth: true, isHiddenHeader: true, isHiddenFooter: true },
    },

    // others
    {
        path: "/mypage/redirect_store",
        name: "RedirectStore",
        component: RedirectStore,
    },
    {
        path: "/pdf/:event/:type/:id/",
        name: "pdfViewer",
        component: PdfViewer,
        meta: { isIgnoreWindowWidth: true, isHiddenHeader: true, isHiddenFooter: true },
    },

    // publishing
    {
        path: "/v2/publishing/list",
        name: "V2PublishingList",
        component: V2PublishingList,
        meta: { isV2: true },
    },
    {
        path: "/v2/teacher/dashboard",
        name: "TeacherDashboard",
        component: TeacherDashboard,
        meta: { isV2: true },
    },
    {
        path: "/v2/teacher/lesson",
        name: "TeacherLesson",
        component: TeacherLesson,
        meta: { isV2: true },
    },
    {
        path: "/v2/teacher/self_learning_mode",
        name: "TeacherSelfLearningMode",
        component: TeacherSelfLearningMode,
        meta: { isV2: true },
    },
    {
        path: "/v2/teacher/setting",
        name: "TeacherSetting",
        component: TeacherSetting,
        meta: { isV2: true },
    },
    {
        path: "/v2/student/dashboard",
        name: "StudentDashboard",
        component: StudentDashboard,
        meta: { isV2: true },
    },
    {
        path: "/v2/student/self_learning_mode",
        name: "StudentSelfLearningMode",
        component: StudentSelfLearningMode,
        meta: { isV2: true },
    },
    {
        path: "/v2/student/self_learning_mode/result",
        name: "StudentSelfLearningModeResult",
        component: StudentSelfLearningModeResult,
        meta: { isV2: true },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)

    if (to.matched.some((record) => record.meta.requiredAuth)) {
        const isLogin = store.state.auth.status.loggedIn
        if (!isLogin) {
            app.config.globalProperties.$toast.error('로그인 전용 기능입니다')
            next({
                path: "/login",
                query: { redirect: to.fullPath },
            })
            return
        }
    } else if (to.matched.some((record) => record.meta.requiredNotAuth)) {
        const isLogin = store.state.auth.status.loggedIn
        if (isLogin) {
            app.config.globalProperties.$toast.error('비로그인 전용 기능입니다')
            next({
                path: "/",
            })
            return
        }
    }

    if (to.matched.some((record) => record.meta.requiredTeacher)) {
        const isTeacher = store.state.auth.user?.academy_user?.is_teacher ?? false
        if (!isTeacher) {
            app.config.globalProperties.$toast.error('학원 등록이 되어있지 않습니다.\n체널톡, 소속 학원에 문의 남겨주시기 바랍니다.')
            next({
                path: "/",
            })
            return
        }


        const lastExpireDt = store.getters["auth/getLastExpireDt"];
        const nowDt = new Date();

        if (!lastExpireDt) {
            app.config.globalProperties.$toast.error('개설 후 이용 가능한 기능입니다');
            next({name: 'price-info'});
            return
        } else if (lastExpireDt < nowDt) {
            app.config.globalProperties.$toast.error('무료 체험 혹은 사용 기한이 종료되었습니다\n다시 개설해주세요');
            next({name: 'price-info'});
            return
        }
    }

    if (to.matched.some((record) => record.meta.requiredAdmin)) {
        const isTeacher = store.state.auth.user?.academy_user?.is_superuser ?? false
        if (!isTeacher) {
            app.config.globalProperties.$toast.error('관리자 선생님 전용 기능입니다')
            next({
                path: "/",
            })
            return
        }
    }

    if (to.matched.some((record) => record.meta.requiredStudent)) {
        const isStudent = store.state.auth.user?.academy_user?.is_student ?? false
        if (!isStudent) {
            app.config.globalProperties.$toast.error('학생 전용 기능입니다')
            next({
                path: "/",
            })
            return
        }
    }

    if (to.matched.some((record) => record.meta.requiredParents)) {
        const isParents = store.state.auth.user?.academy_user?.is_parents ?? false
        if (!isParents) {
            app.config.globalProperties.$toast.error('학부모 전용 기능입니다')
            next({
                path: "/",
            })
            return
        }
    }

    next()
})

export default router
