<template>
  <div id="container">
    <div class="find-email-wrap">
      <div class="login-container">
        <h1>이메일 찾기</h1>

        <template v-if="!this.foundEmails">
          <input v-model="this.name" type="text" placeholder="이름을 입력해주세요."/>
          <input v-model="this.phone" type="text" placeholder="연락처를 입력해주세요."/>

          <button class="submit-btn" @click="onSubmit">이메일 찾기</button>
        </template>
        <template v-else>
          <div class="email-list-wrap">
            <span v-for="(foundEmail, index) in this.foundEmails"
                  :key="index"
            >{{ foundEmail.email }}</span>
          </div>

          <button class="submit-btn" @click="redirectLogin">로그인 페이지로 이동</button>
        </template>
      </div>
    </div>
  </div>
  <LoadingDialog
      :is-show="this.isLoading"
  />
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import AuthService from "@/services/auth.service";

export default {
  components: {LoadingDialog},
  data() {
    return {
      name: null,
      phone: null,
      isLoading: false,
      foundEmails: null,
    }
  },
  mounted() {
  },
  methods: {
    redirectLogin() {
      this.$router.push({name: 'login'});
    },
    async onSubmit() {
      if (!this.name) {
        this.$toast.error('이름을 입력해주세요');
        return;
      }
      if (!this.phone) {
        this.$toast.error('연락처를 입력해주세요');
        return;
      }

      this.isLoading = true;

      try {
        const res = await AuthService.findEmail({
          name: this.name,
          phone: this.phone,
        });
        if (res && res.length > 0) {
          this.foundEmails = res;
        } else {
          this.$toast.error('일치하는 이메일이 없습니다');
        }
      } catch (e) {
        this.$toast.error('이메일 찾기 중 오류가 발생하였습니다');
        console.log(e);
      }

      this.isLoading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.find-email-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 108px 40px 194px;
  .login-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    h1 {
      font-weight: 700;
      font-size: 45px;
      text-align: center;
      color: black;
      margin-bottom: 46px;
    }
    input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 5px;
      padding: 17px 23px;
      font-weight: 700;
      font-size: 14px;
      color: #999999;
      margin-top: 20px;
    }
    .submit-btn {
      width: 100%;
      background: #008ED5;
      border: 1px solid #008ED5;
      margin-top: 44px;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
    }
    .or-border {
      width: 100%;
      display: flex;
      gap: 15px;
      margin-top: 47px;
      align-items: center;
      .border {
        flex: 1;
        height: 1px;
        background: #008ED5;
        border-radius: 5px;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }
    }
    .sns-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 21px;
      align-items: center;
      .sns-btn {
        width: 50px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    p {
      margin-top: 56px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
    }
    .reg-btn {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #008ED5;
      border-radius: 5px;
      padding: 14px 10px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #008ED5;
      margin-top: 23px;
    }
    .find-list-wrap {
      width: 100%;
      display: flex;
      gap: 10px;
      margin-top: 20px;
      .divider {
        width: 1px;
        align-self: stretch;
        background-color: #008ED5;
      }
      .find-item {
        flex: 1;
        font-size: 14px;
        color: black;
        &:first-child {
          text-align: right;
        }
        &:last-child {
          text-align: start;
        }
        &>a {
          cursor: pointer;
        }
      }
    }
    .email-list-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      min-height: 150px;
      justify-content: center;
      &>span {
        text-align: center;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .find-email-wrap {
    padding: 20px 40px;
    .login-container {
      h1 {
        font-size: 30px;
        margin-bottom: 20px;
      }
      input {
        margin-top: 15px;
        font-size: 12px;
      }
      .submit-btn {
        margin-top: 30px;
      }
    }
  }
}
</style>
