export function getOrCreateTooltip(chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
}

export function dailyStudentHoursChartTooltip(context, items) {
    const {
        chart,
        tooltip
    } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    const dataPoints = tooltip.dataPoints;
    if (dataPoints.length > 0) {
        const dataPoint = dataPoints[0];
        try {
            const item = items[dataPoint.datasetIndex];
            const itemData = item.datas[dataPoint.dataIndex];
            tooltipEl.innerHTML = `
                <style lang="scss">
                .tooltip-wrap {
                    width: 145px;
                    min-height: 100px;
                    background-color: white;
                    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
                    padding: 10px;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    * {
                        font-family: 'Abhaya Libre', sans-serif;
                    }
                    .t-title-wrap {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        &>p {
                            border-radius: 5px;
                            padding: 5px 10px;
                            font-weight: 600;
                            font-size: 13px;
                            color: white;
                        }
                        &>span {
                            flex: 1;
                            text-align: end;
                            font-weight: 500;
                            font-size: 10px;
                            color: #797979;
                        }
                    }
                    .t-list-wrap {
                        margin-top: 10px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .tl-item {
                            width: 100%;
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            &>p {
                                font-weight: 500;
                                font-size: 10px;
                                color: black;
                            }
                            &>span {
                                flex: 1;
                                font-weight: 600;
                                font-size: 10px;
                                color: black;
                                text-align: end;
                            }
                        }
                    }
                }
                </style>
                <div class="tooltip-wrap">
                    <div class="t-title-wrap">
                        <p style="background-color: ${item.color}">${item.title}</p>
                        <span>${itemData.dtStr}</span>
                    </div>
                    <div class="t-list-wrap">
                        <div class="tl-item">
                            <p>문항수</p>
                            <span>${itemData.problemCnt}개</span>
                        </div>
                        <div class="tl-item">
                            <p>정답률</p>
                            <span>${itemData.answerRate}%</span>
                        </div>
                        <div class="tl-item">
                            <p>소요시간</p>
                            <span>${Math.floor(itemData.sec / 60)}:${itemData.sec % 60}</span>
                        </div>
                    </div>
                </div>
            `;
        } catch (e) {}
    }

    const {
        width: positionWidth,
        offsetLeft: positionX,
        offsetTop: positionY,
    } = chart.canvas;

    tooltipEl.style.backgroundColor = '#00000000';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.left = tooltip.caretX > positionWidth / 2
        ? positionX + tooltip.caretX - tooltipEl.clientWidth + 'px'
        : positionX + tooltip.caretX + tooltipEl.clientWidth + 'px';
}
