<template>
    <template v-if="!selectWorkbook">
        <div class="mypage-tab-body-wrap">
            <Filter
                :filterData="filterData"
                />

            <ListArray 
                :listData="listData" 
                />
        </div>
    </template>
    <template v-else>
        <div class="mypage-tab-body-wrap">
            <Filter
                :filterData="filterData"
                />

            <TreeDetail
                :nodes="nodes"
                :questions="nodeInFocused"
                />
        </div>
        
    
        <State
            :stateData="selectTableStateData"
            />
    </template>
</template>

<script>
import Filter from './Filter.vue'
import State from './state.vue'
import ListArray from './ListArray.vue'
import TreeDetail from './TreeDetail.vue'
import models from '@/models'
import utils from '../utils'
import WorkbookService from '../services/workbook.service'
import { debounce } from 'lodash'
import ProblemService from '@/services/problem.service'

export default {
  name: 'EditStep1Workbook',
  components: {
    Filter,
    State,
    ListArray,
    TreeDetail
  },
  props: {
    isWorkbook: Boolean,
    onChangeProblems: Function,
    onChangeSearchProblems: Function,
  },
  watch: {
    selectWorkbook() {
      this.loadProblemPatterns()
    },
    problems() {
      this.onChangeProblems(this.problems)
    },
    searchProblems() {
      this.onChangeSearchProblems(this.searchProblems)
    },
    selectCategory() {
      this.onChangeCategory()
    },
    selectSubCategory() {
      this.loadDataList(this)
    },
    keyword() {
      this.loadDataList(this)
    },
  },
  data() {
    return {
      selectCategory: null,
      selectSubCategory: null,
      categories: [],
      subCategories: [],
      workbooks: [],
      utils,
      keyword: null,

      selectWorkbook: null,

      selectTb: null,

      types: ['전체'],
      solutionTypes: [],
      difficulties: [],

      problemPatterns: [],

      leftDepth: 3,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async loadProblemPatterns() {
      this.problemPatterns = []
      if (this.selectWorkbook) {
        let res = await ProblemService.getWorkbookProblems({
          'workbook_id': this.selectWorkbook.id,
        })

        if (res) {
          let treeId = -1
          const patterns = []

          for (let i1 = 0; i1 < res.length; i1++) {
            // 대단원
            const p1 = {
              'children': [],
              'depth': 1,
              'id': (++treeId).toString(),
              'obj': res[i1].obj,
              'text': res[i1].obj.name,
            }
            patterns[p1.id] = p1

            const p2s = res[i1].child
            if (p2s?.length > 0) {
              for (let i2 = 0; i2 < p2s.length; i2++) {
                // 중단원
                const p2 = {
                  'children': [],
                  'depth': 2,
                  'id': (++treeId).toString(),
                  'obj': p2s[i2].obj,
                  'text': p2s[i2].obj.workbook_number,
                }
                p1.children.push(p2.id)
                patterns[p2.id] = p2

                const p3s = p2s[i2].child
                if (p3s?.length > 0) {
                  for (let i3 = 0; i3 < p3s.length; i3++) {
                    // 소단원
                    const p3 = {
                      'children': [],
                      'depth': 3,
                      'id': (++treeId).toString(),
                      'obj': p3s[i3].obj,
                      'text': p3s[i3].obj.name,
                      'problemChildren': [],
                    }
                    p2.children.push(p3.id)
                    patterns[p3.id] = p3

                    const problemChildren = p3s[i3].child?.map(p4 => {
                      // 유형
                      return {
                        'title': p4.obj.name,
                        'items': p4.problems?.map(problem => {
                          return {
                            'title': problem.workbook_number ?? '-',
                            'isChecked': false,
                            'onChange': this.onChangeQuestionItem,
                            'problems': [problem],
                          }
                        }) ?? [],
                      }
                    })
                    p3.problemChildren = problemChildren.length > 0 ? problemChildren : [{
                      'title': '등록된 문제가 없습니다',
                      'items': [],
                    }]
                  }
                }
              }
            }
          }

          patterns.forEach(i => {
            i.id = i.id.toString()
            i.onChange = this.onChangeByCommTb
            i.onFocus = this.onFocusByCommTb
            i.state = {}
            i.state.opened = true
          })
          this.problemPatterns = patterns
        }
      }
    },
    async initData() {
      const res = await WorkbookService.getCategories()
      if (res) {
        this.categories = res
        if (this.categories.length > 0) {
          this.selectCategory = this.categories[0]
        }
      }
    },
    async onChangeCategory() {
      if (this.selectCategory) {
        this.selectSubCategory = null;
        this.subCategories = [];

        let res = await WorkbookService.getSubCategories({
          category: this.selectCategory,
        })
        if (!res) {
          res = [];
        }
        res.unshift('전체');

        this.subCategories = res;
        if (this.subCategories.length > 0) {
          this.selectSubCategory = this.subCategories[0];
        }

        this.loadDataList(this);
      }
    },
    loadDataList: debounce(async (appContext) => {
      appContext.selectWorkbook = null
      appContext.workbooks = []

      const category = appContext.selectCategory
      const subCategory = appContext.selectSubCategory
      let schoolYear = null
      let term = null
      let subject = null

      if (subCategory !== '전체') {
        if (['초등교재', '중등교재'].includes(category)) {
          schoolYear = subCategory?.substr(0, 1)
          term = subCategory?.substr(2, 3)
        } else {
          subject = subCategory
        }
      }

      const res = await WorkbookService.getWorkbooks({
        'category': category,
        'school_year': schoolYear,
        'term': term,
        'subject': subject,
        'name': appContext.keyword,
      })
      if (res) {
        appContext.workbooks = res
      }
    }, 200),
    onChangeQuestionItem(item, isChecked) {
      item.isChecked = isChecked
    },
    onChangeByCommTb(node, isChecked) {
      const id = node.id
      if (id) {
        this.onFocusByCommTb(node)

        if (node.depth < this.leftDepth) {
          node.children.forEach(childId => {
            Object.entries(this.problemPatterns)
                .filter(i => i[1].id == childId)
                .forEach(i => {
                  i[1].problemChildren?.forEach(i => {
                    i.items?.forEach(item => {
                      item.isChecked = isChecked
                    })
                  })
                  i[1].state.checked = isChecked
                })
          })
        } else if (node.depth == this.leftDepth) {
          node.problemChildren?.forEach(i => {
            i.items?.forEach(item => {
              item.isChecked = isChecked
            })
          })
        }
      }
    },
    onFocusByCommTb(node) {
      Object.entries(this.problemPatterns).forEach(entry => {
        const id = entry[0]
        const value = entry[1]

        this.problemPatterns[id].focus = node.id == value.id
      })
    },
    onNextCommTbData() {
      this.onChangeStep(1)
    },
    onNextCommTbDataStep2() {
      this.onChangeStep(1)
    },
  },
  computed: {
    nodeInFocused() {
      let focusQuestion

      Object.entries(this.problemPatterns).forEach(entry => {
        const value = entry[1]

        if (value.focus) {
          focusQuestion = value.problemChildren
          return
        }
      })
      return focusQuestion
    },
    selectTableStateData() {
      return {
        items: [
          {
            id: 0,
            type: 'button',
            title: '유형별',
            list: models.problemTypeList(),
            listClass: 'grid-3',
            value: this.types,
            onChange: (id, value) => {
              if (value == '전체') {
                this.types = ['전체']
              } else {
                const allItemIdx = this.types.findIndex(i => i == '전체')
                if (allItemIdx > -1) {
                  this.types.splice(allItemIdx, 1)
                }

                const targetItemIdx = this.types.findIndex(i => i == value)
                if (targetItemIdx > -1) {
                  if (this.types.length == 1) {
                    this.types = ['전체']
                  } else {
                    this.types.splice(targetItemIdx, 1)
                  }
                } else {
                  this.types.push(value)
                }
              }
            },
          },
          {
            id: 1,
            type: 'button',
            title: '풀이형',
            list: models.problemSolutionType(),
            listClass: 'grid-3',
            value: this.solutionTypes,
            onChange: (id, value) => {
              const targetItemIdx = this.solutionTypes.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.solutionTypes.splice(targetItemIdx, 1)
              } else {
                this.solutionTypes.push(value)
              }
            },
          },
          {
            id: 2,
            type: 'button',
            title: '난이도',
            list: models.difficultyList(),
            listClass: 'grid-5',
            value: this.difficulties,
            onChange: (id, value) => {
              const targetItemIdx = this.difficulties.findIndex(i => i == value)
              if (targetItemIdx > -1) {
                this.difficulties.splice(targetItemIdx, 1)
              } else {
                this.difficulties.push(value)
              }
            },
          },
        ],
        resItems: [
          {type: 'text', title: '문제수', value: `${Number(this.problems.length)}개`},
          {type: 'text', title: '적용된 유형수', value: `${Number(this.applyProblemPatterns.length)}개`},
        ],
      }
    },
    listData() {
      const trList = this.workbooks.map(wb => {
        let grade = ''
        if (wb.category.includes('초등')) {
          grade = `초등 ${wb.school_year}-${wb.term}`
        } else if (wb.category.includes('중등')) {
          grade = `중등 ${wb.school_year}-${wb.term}`
        } else {
          grade = wb.subject
        }

        const tdList = [
          {value: grade, isChecked: wb.id == this.selectWorkbook?.id},
          {value: wb.name},
          {value: wb.noc},
          {value: wb.publisher},
        ]
        tdList.forEach(i => i.onClick = () => {this.selectWorkbook = wb})

        return tdList
      })

      return {
        heads: [
          {title: '학교급', type: 'string', addClass: 'w-100px'},
          {title: '교재명', type: 'string', addClass: 'flex-1'},
          {title: '판수', type: 'string', addClass: 'w-80px'},
          {title: '출판사', type: 'string', addClass: 'w-120px'},
        ],
        trList: trList,
      }
    },
    filterData() {
      const list = []

      list.push({
        type: 'select',
        addClass: 'w-125px',
        name: '카테고리 선택',
        options: this.categories.map(i => {
          return {
            value: i,
            name: i,
            isSelected: this.selectCategory == i,
          }
        }),
        onChange: (value) => this.selectCategory = value,
      })

      if (this.selectCategory) {
        const isOverHigh = !this.selectCategory.includes('초등') && !this.selectCategory.includes('중등')
        let name = '없음'
        if (this.subCategories.length > 0) {
          if (isOverHigh) {
            name = '과목'
          } else {
            name = '학년/학기'
          }
        }

        list.push({type: 'select-arrow'})
        list.push({
          type: 'select',
          addClass: isOverHigh ? 'w-130px' : 'w-80px',
          name: name,
          options: this.subCategories.map(i => {
            return {
              value: i,
              name: i,
              isSelected: this.selectSubCategory == i,
            }
          }),
          onChange: (value) => this.selectSubCategory = value,
        })
      }

      return {
        title: this.isWorkbook ? '교재 선택' : '학습지 선택',
        list: list,
        searchInput: {placeholder: '교재검색', value: this.keyword, onChange: (keyword) => this.keyword = keyword},
        createBtn: {isVisible: this.selectWorkbook ? true : false, name: '다른 교재 선택', onCreate: () => { this.selectWorkbook = null }},
      }
    },
    nodes() {
      const nodes = {}
      const entries = Object.entries(this.problemPatterns)
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i]
        const key = entry[0]
        const value = entry[1]

        if (value.depth <= this.leftDepth) {
          const isProblemExistChecked = Object.values(value?.problemChildren ?? {}).findIndex(q => Object.values(q?.items ?? {}).findIndex(i => i.isChecked) > -1) > -1
          if (isProblemExistChecked) {
            const isProblemExistNotChecked = Object.values(value?.problemChildren ?? {}).findIndex(q => Object.values(q?.items ?? {}).findIndex(i => !i.isChecked) > -1) > -1

            value.state.indeterminate = isProblemExistNotChecked
            value.state.checked = !isProblemExistNotChecked
          } else {
            value.state.indeterminate = false
            value.state.checked = false
          }

          nodes[key] = value
        }
      }

      return nodes
    },
    applyProblemPatterns() {
      return this.problems.reduce(
          (acc, cur) => {
            if (!acc.includes(cur.pattern_name)) {
              acc.push(cur.pattern_name)
            }
            return acc
          },
          []
      )
    },
    problems() {
      let problems = []

      const entries = Object.entries(this.problemPatterns)
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i]
        const value = entry[1]

        if (value.depth <= this.leftDepth) {
          Object.values(value?.problemChildren ?? {}).forEach(i =>
              Object.values(i?.items ?? {}).forEach(item => {
                if (item.isChecked) {
                  item.problems.forEach(p => problems.push(Object.assign({}, p)))
                }
              })
          )
        }
      }

      problems = problems.filter(i => {
        let result = true

        const types = this.types.filter(i => i != '전체')
        if (result && types.length > 0) {
          result = types.includes(i.type)
        }

        if (result && this.solutionTypes.length > 0) {
          result = this.solutionTypes.includes(i.solution_type)
        }

        if (result && this.difficulties.length > 0) {
          result = this.difficulties.includes(i.difficulty)
        }

        return result
      })

      return problems
    },
    searchProblems() {
      let problems = []

      const entries = Object.entries(this.problemPatterns)
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i]
        const value = entry[1]

        if (value.depth <= this.leftDepth) {
          Object.values(value?.problemChildren ?? {}).forEach(i =>
              Object.values(i?.items ?? {}).forEach(item => {
                if (item.isChecked) {
                  item.problems.forEach(p => problems.push(Object.assign({}, p)))
                }
              })
          )
        }
      }

      return problems
    },
  },
}
</script>
