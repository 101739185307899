<template>
  <div :class="['mypage-tab-body-wrap']">
    <Filter
        :filterData="filterData"
    />

    <ListArray
        :listData="this.listData"
        :isLoading="this.isLoading"
    >
    </ListArray>

    <div :class="['select-list', {'open': checkedList.length > 0}]">
      <h1>반 {{ checkedList.length }}개 선택됨</h1>

      <div class="btn-list">
        <div class="btn-item" @click="() => isOpenDeletePopup = true">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="delete" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: white;"><path fill="silver" d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zm10.833-12.5h-2.916l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333z"></path></svg>
          <span>선택 삭제</span>
        </div>
        <div class="close-btn" @click="() => checkedList.forEach(i => i.isChecked = false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </div>
      </div>
    </div>
  </div>

  <Popup
      :isTitleExclamationMark="true"
      :popupTitle="'주의'"
      :isVisible="isOpenDeletePopup"
      :onClose="() => {isOpenDeletePopup = false}"
      :onConfirm="onDeleteChecked"
      :onCancel="() => {}"
  >
    <template v-slot:bodyContents>
      <div>삭제하면 다시 복구가 불가능합니다.<br>정말 삭제하시겠습니까?</div>
    </template>
  </Popup>

  <ClassPopup
      :isVisible="!!this.selectAcademyClassId"
      :onClose="() => {
        this.selectAcademyClassId = null;
      }"
      :onUpdated="() => {
        this.selectAcademyClassId = null;
        this.initData();
      }"
      :academyClassId="this.selectAcademyClassId"
      :academyInfoId="this.academyInfo.id"
      :academyUsers="this.academyUsers"
  />

  <ClassCreatePopup
      :isVisible="this.isOpenClassCreatePopup"
      :onClose="() => {
        this.isOpenClassCreatePopup = false;
        this.initData();
      }"
      :academyInfo="this.academyInfo"
  />
</template>

<script>
import Filter from './Filter.vue'
import ListArray from './ListArray.vue'
import academyService from '../services/academy.service'
import ClassPopup from './ClassPopup.vue'
import ClassCreatePopup from './ClassCreatePopup.vue'
import Popup from './Popup.vue'
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  name: 'OperationClass',
  components: {
    LoadingDialog,
    Filter,
    ListArray,
    ClassPopup,
    ClassCreatePopup,
    Popup,
  },
  props: {
    academyInfo: Object,
  },
  watch: {
    academyInfo() {
      this.initData();
    },
  },
  data() {
    return {
      isLoading: true,
      isOpenDeletePopup: false,
      academyClasses: [],
      isOpenClassCreatePopup: false,
      selectAcademyClassId: null,
      academyUsers: [],
    }
  },
  created() {
    this.initData(true);
  },
  methods: {
    async onDeleteChecked() {
      await academyService.deleteAcademyClassIdList(this.checkedList.map(i => i.id))
      this.$toast.success('삭제되었습니다')
      await this.initData();
    },
    async initData(isInitAcademyUsers=false) {
      if (!this.academyInfo) return;

      this.isLoading = true;
      this.academyClasses = [];

      await Promise.all([
        academyService.getMyAcademyClasses().then((e) => this.academyClasses = e),
        isInitAcademyUsers ? academyService.getMyAcademyUsers().then((e) => this.academyUsers = e) : [],
      ]);

      this.isLoading = false;
    },
    onChangeItem(item) {
      const findItem = this.academyClasses.find(i => i.id == item.id)
      if (findItem) {
        findItem.isChecked = !findItem.isChecked
      }
    },
  },
  computed: {
    filterData() {
      return {
        list: [],
        searchInput: {isHidden: true},
        createBtn: {isVisible: true, onCreate: () => this.isOpenClassCreatePopup = true, name: '반 만들기'}
      };
    },
    listData() {
      return {
        title: '반',
        heads: [
          {title: '선택', type: 'checkbox', addClass: 'w-75px'},
          {title: '순서', type: 'string', addClass: 'w-75px'},
          {title: '반 이름', type: 'string', addClass: 'flex-1'},
          {title: '반 학생', type: 'string', addClass: 'w-200px'},
          {title: '담당 선생님', type: 'string', addClass: 'w-100px'},
          {title: '상세', type: 'submit-btn', addClass: 'w-100px'},
        ],
        trList: this.isLoading ? [] : this.academyClasses.map(academyClass => {
          const result = [
            {isChecked: academyClass.isChecked, onChange: () => this.onChangeItem(academyClass)},
            {value: academyClass.order},
            {value: academyClass.name},
            {value: academyClass.student_name},
            {value: academyClass.teacher_name},
            {value: '상세', onClickSubmitBtn: () => this.selectAcademyClassId = academyClass.id},
          ]

          result.forEach(i => {
            i.onClick = () => this.onChangeItem(academyClass)
          })

          return result
        })
      }
    },
    checkedList() {
      return this.academyClasses.filter(i => i.isChecked)
    },
  },
}
</script>
