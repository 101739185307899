<template>
  <div class="teacher-lesson-wrap">
    <V2Wrapper>
      <div class="tlw-inner">
        <div class="tl-sub-header-wrap">
          <div class="v2-base-container">
            <div class="sh-edit-wrap">
              <button>
                <img src="@/assets/images/draw.png" alt="">
              </button>
              <button>
                <img src="@/assets/images/border-color.png" alt="">
              </button>
              <button>
                <img src="@/assets/images/chat.png" alt="">
              </button>
            </div>
            <div class="sh-more-wrap">
              <div class="shm-edit-dt">
                <p>5월 17일 편집</p>
              </div>
              <div class="shm-user-wrap">
                <template v-for="(userThumb, index) in this.userThumbList" :key="index">
                  <div class="mu-thumb-box">
                    <div class="mut-inner">
                    </div>
                  </div>
                </template>
              </div>
              <button class="shm-btn btn-active">
                <span>공유</span>
              </button>
              <button class="shm-btn btn-active">
                <img src="@/assets/images/chat.png" alt="">
              </button>
              <button class="shm-btn btn-active">
                <img src="@/assets/images/schedule.png" alt="">
              </button>
              <button class="shm-btn btn-active">
                <img src="@/assets/images/more.png" alt="">
              </button>
            </div>
          </div>
        </div>
        <div class="top-bg-wrap">
          <div class="v2-base-container">
            <div :class="['side-nav-expand-btn', {'expand': this.isSideNavExpand}]"
                 @click="() => this.isSideNavExpand = true"
            >
              <img class="expand-arrow-btn" src="@/assets/images/arrow-right.png" alt="">
            </div>
            <div :class="['side-nav-wrap', {'expand': this.isSideNavExpand}]">
              <div class="sn-header-wrap"
                   @click="() => this.isSideNavExpand = false"
              >
                <p>공유된 페이지</p>
                <img class="expand-arrow-btn" src="@/assets/images/arrow-left.png" alt="">
              </div>
              <div class="sn-sub-header-wrap">
                <p>📕 에스덕 온보딩 보드</p>
              </div>
              <div class="sn-body-wrap">
                <div class="line-item"
                     oncontextmenu="return false;"
                     @contextmenu="(event) => this.onSideNavRightClick(event)"
                >
                  <p class="li-text">개인 페이지</p>
                </div>
                <div class="line-item"
                     oncontextmenu="return false;"
                     @contextmenu="(event) => this.onSideNavRightClick(event)"
                >
                  <p class="li-text">📕 S-duck</p>
                </div>
                <div class="line-item d-2"
                     oncontextmenu="return false;"
                     @contextmenu="(event) => this.onSideNavRightClick(event)"
                >
                  <p class="li-text">📜 Job Positions</p>
                </div>
                <div class="line-item d-3"
                     oncontextmenu="return false;"
                     @contextmenu="(event) => this.onSideNavRightClick(event)"
                >
                  <div class="li-dot">
                    <p class="li-text">팀별 채용공고</p>
                  </div>
                </div>
                <template v-for="(userItem, index) in this.userItems" :key="index">
                  <div class="line-item"
                       oncontextmenu="return false;"
                       @contextmenu="(event) => this.onSideNavRightClick(event)"
                  >
                    <div class="li-user-box">
                      <div class="liu-thumb"></div>
                      <p>{{ userItem.name }}</p>
                      <div :class="['liu-online', {'active': userItem.isOnline}]"></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";

export default {
  name: 'TeacherLesson',
  components: {
    V2Wrapper,
  },
  data() {
    return {
      isSideNavExpand: false,
      userThumbList: [
        '',
        '',
        '',
      ],
      userItems: [
        {thumb: '', name: '김김김', isOnline: false},
        {thumb: '', name: '김김김', isOnline: false},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
        {thumb: '', name: '김김김', isOnline: true},
      ],
    };
  },
  methods: {
    onSideNavRightClick(event) {
      const posX = event.clientX;
      const posY = event.clientY;

      document.querySelectorAll('.board-menu-wrap').forEach(elem => {
        elem.remove();
      });

      const bodyElem = document.querySelector('body');
      const bodyWidth = bodyElem.clientWidth;
      const bodyHeight = bodyElem.clientHeight;

      const menuElem = document.createElement('div');
      menuElem.classList.add('board-menu-wrap');
      menuElem.onclick = function () {

      };
      menuElem.innerHTML = `
        <div class="">

        </div>
      `;
      bodyElem.append(menuElem);
    },
    removeBoardMenus() {

    },
  },
  computed: {},
}
</script>
