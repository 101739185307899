<template>
  <div class="pdf-viewer-wrap">
    <div class="status-wrap">
      <template v-if="isLoading">
        <b-spinner label="Spinning"></b-spinner>
        <span v-if="isLoading">데이터를 불러오는 중입니다</span>
      </template>
      <template v-else-if="this.event === PdfViewType.download">
        <span>다운로드가 안 될 시 아래 다운로드 버튼을 눌러주세요</span>
        <button @click="() => this.download()">다운로드</button>
      </template>
    </div>

    <div class="pdf-outer-wrap">
      <div class="pdf-list"
           v-if="src && numPages"
      >
        <template v-for="(numPage, index) in this.numPages" :key="index">
          <VuePDF
              :src="src"
              :page="numPage"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VuePDF from "vue3-pdf";
import utils from "@/utils";
import commonService from "@/services/common.service";
import {PdfTargetType, PdfType, PdfViewType} from "@/utils/enums";

export default {
  computed: {
    PdfViewType() {
      return PdfViewType
    }
  },
  components: {
    VuePDF,
  },
  data() {
    return {
      isLoading: true,

      event: null,
      type: null,
      targetFilePath: null,
      fileName: null,

      src: null,
      srcCalcWidth: null,
      srcMaxWidth: utils.getPdfSize().width,
      numPages: 0,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const {
        event,
        type,
        id,
      } = this.$route.params;
      this.event = event;
      this.type = type;

      const {
        pdf_target_type,
      } = this.$route.query;
      const pdfTargetType = pdf_target_type ?? PdfTargetType.all;

      let fileName = '';
      let splitCharacter = ' - ';

      let pdfFile;
      let pdfProblemFile;
      let pdfSolutionFile;

      try {
        const res = await commonService.getPdfFile(type, id);
        fileName = (res['file_name'] ?? '').replace(/(?:\r\n|\r|\n)/g, splitCharacter);
        pdfFile = res['pdf_file'];
        pdfProblemFile = res['pdf_problem_file'];
        pdfSolutionFile = res['pdf_solution_file'];
      } catch (e) {
        console.log(e);
        this.$toast.error('파일 요청 중 오류가 발생하였습니다');
        return;
      }

      let errorMsg;
      if (type == PdfType.worksheet || type == PdfType.workbook) {
        if (pdfTargetType == PdfTargetType.all) {
          if (pdfFile) {
            this.targetFilePath = utils.getServerMediaPath(pdfFile);
            this.fileName = fileName;
          } else {
            errorMsg = 'PDF 파일을 찾을 수 없습니다\n해당 학습지를 수정하거나 새로 생성하여 다시 시도해주세요';
          }
        } else if (pdfTargetType == PdfTargetType.only_problem) {
          if (pdfFile) {
            this.targetFilePath = utils.getServerMediaPath(pdfProblemFile);
            this.fileName = `${fileName}${splitCharacter}문제지`;
          } else {
            errorMsg = 'PDF 파일을 찾을 수 없습니다\n해당 학습지를 수정하거나 새로 생성하여 다시 시도해주세요';
          }
        } else if (pdfTargetType == PdfTargetType.only_solution) {
          if (pdfFile) {
            this.targetFilePath = utils.getServerMediaPath(pdfSolutionFile);
            this.fileName = `${fileName}${splitCharacter}정답해설`;
          } else {
            errorMsg = 'PDF 파일을 찾을 수 없습니다\n해당 학습지를 수정하거나 새로 생성하여 다시 시도해주세요';
          }
        }
      } else if (type == PdfType.academyReport) {
        if (pdfFile) {
          this.targetFilePath = utils.getServerMediaPath(pdfFile);
          this.fileName = fileName;
        } else {
          errorMsg = 'PDF 파일을 찾을 수 없습니다\n해당 보고서를 수정하거나 새로 생성하여 다시 시도해주세요';
        }
      }

      if (errorMsg) {
        this.$toast.error(errorMsg);
        return;
      }

      if (event == PdfViewType.preview) {
        this.src = await VuePDF.createLoadingTask(this.targetFilePath);
        this.srcCalcWidth = window.innerWidth;
        this.src.promise.then(pdf => {
          this.numPages = pdf.numPages;
        });
      } else if (event == PdfViewType.download) {
        this.onDownload();
      } else {
        this.$toast.error('알 수 없는 보기 형식입니다');
        return;
      }

      this.isLoading = false;
    },
    onDownload() {
      utils.downloadUrl(this.targetFilePath, this.fileName);
    },
  },
}
</script>

<style lang="scss" scoped>
.pdf-viewer-wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .status-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 13px;
    .spinner-border {
      border-color: #008ED5;
      border-right-color: transparent;
      width: 60px;
      height: 60px;
    }
    span {
      font-weight: bold;
    }
    button {
      display: flex;
      padding: 10px 20px;
      border: 1px solid #008ED5;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      transition: all .2s ease-in-out;
      cursor: pointer;
      background-color: #008ED5;
      color: white;
    }
  }
  .pdf-outer-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow-y: auto;
    align-items: center;
    .pdf-list {
      max-width: 900px;
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &>span {
        border: 1px solid rgba(0,142,213,.5);
      }
    }
  }
}
</style>
