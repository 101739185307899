<template>
  <div id="footer">
    <div class="wrapper">
      <div class="top">
        <div class="col_l">
          <p>© 2023. S-DUCK All right reserved.</p>
          <ul>
            <li><a :href="this.$router.resolve({name: 'personal-terms'}).href">개인정보처리방침</a></li>
            <li><a :href="this.$router.resolve({name: 'service-terms'}).href">이용약관</a></li>
          </ul>
        </div>
        <div class="col_r">
          <ul>
            <li><a :href="this.$router.resolve({name: 'support-workbook'}).href">지원교재</a></li>
            <li><a :href="this.$router.resolve({name: 'price-info'}).href">요금안내</a></li>
            <li><a href="https://www.notion.so/hi-s-duck/f2027538b9a04f2d84464b42799862ff?v=6bf86985dc3a497fb4629bcc8d0e8692&pvs=4">사용가이드</a></li>
            <li><a :href="this.$router.resolve({name: 'support'}).href">고객센터</a></li>
            <li><a :href="this.$router.resolve({name: 'event'}).href">이벤트</a></li>
          </ul>
        </div>
      </div>
      <div class="btm">
        <span>에스덕 대표자 : 양장엽</span>
        <span>통신판매업신고 : 2022-대구달서-1826</span>
        <span>주소 : 대구시 명덕로 104 </span>
        <span>사업자등록번호 : 785-26-01342</span>
        <br/>
        <span>개인정보보호책임자 : 양장엽(sduck0712@gmail.com)</span>
        <span>Email : sduck0712@gmail.com</span>
        <span>CS : 010-4977-2966</span>
        <span>카카오톡 상담채널 : 에스덕</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
