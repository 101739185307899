<template>
  <div class="tree-wrap">
    <div class="tree-relative-wrap">
      <div class="tree-inner-wrap">
        <Tree
            :nodes="nodes"
            :config="config"
            @nodeChecked="(node) => this.nodeChange(node, true)"
            @nodeUnchecked="(node) => this.nodeChange(node, false)"
            @nodeFocus="(node) => this.nodeFocus(node)"
        >
        </Tree>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "vue3-treeview";
import "vue3-treeview/dist/style.css";
import openIcon from '@/assets/images/tree-open.png'
import closeIcon from '@/assets/images/tree-close.png'

export default {
  name: 'TreeList',
  props: {
    nodes: Object,
    //// [node example] ////
    // id1: {
    //     text: "text1",
    //     children: ["id1_1", "id1_2"],
    // },
    // id1_1: {
    //     text: "text11",
    //     children: ['id1_1_1']
    // },
    // id1_1_1: {
    //     text: "text111",
    // },
    // id1_2: {
    //     text: "text12",
    // },
    // id2: {
    //     text: "text2",
    // },
    ///////////////////////
  },
  components: {
    Tree,
  },
  data() {
    return {
      config: {
        roots: [],
        checkMode: 0,
        checkboxes: true,
        openedIcon: {
          type: "img",
          height: 13,
          width: 13,
          src: openIcon,
          class: 'test',
        },
        closedIcon: {
          type: "img",
          height: 13,
          width: 13,
          src: closeIcon,
        },
        keyboardNavigation: false,
        dragAndDrop: false,
        editable: false,
        disabled: false,
        padding: 25,
      },
    }
  },
  mounted() {
    this.calcNodeRoots()
  },
  watch: {
    nodes: function() {
      this.calcNodeRoots()
    }
  },
  methods: {
    calcNodeRoots() {
      if (this.nodes) {
        const allEntries = Object.entries(this.nodes)

        this.config.roots = allEntries.filter(e => {
          const key = e[0]
          const isIncludeOther = allEntries.findIndex(e2 => e2[1].children?.includes(key)) > -1
          return !isIncludeOther
        }).map(e => e[0])
      }
    },
    nodeChange(node, isChecked) {
      const nodeId = node.id
      if (nodeId) {
        const findNode = Object.values(this.nodes).find(v => v.id == node.id)
        if (findNode?.onChange) {
          findNode.onChange(node, isChecked)
        }
        if (isChecked && findNode?.onChecked) {
          findNode.onChecked(node)
        }
        if (!isChecked && findNode?.onUnchecked) {
          findNode.onUnchecked(node)
        }
      }
    },
    log(text) {
      console.log(text);
    },
    nodeFocus(node) {
      const nodeId = node.id
      if (nodeId) {
        const findNode = Object.values(this.nodes).find(v => v.id == node.id)
        if (findNode?.onFocus) {
          findNode.onFocus(node)
        }
      }
    },
  },
}
</script>
