<template>
    <div :class="['popup', { hide: !isVisible }]">
      <div class="popup-shadow" @click="this.onClose"></div>
      <div :class="['popup-body']">
        <div class="popup-title">
          반 만들기
          <button class="close-btn" @click="this.onClose">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
          </button>
        </div>
  
        <div class="body-contents">
          <div class="body-item">
            <p>반 이름</p>
            <CustomInput
              addClass="default-border"
              :value="name"
              :onChangeValue="(value) => name = value"
              />
          </div>
        </div>
  
        <div class="btn-list">
            <button @click="onCreate">만들기</button>
        </div>
      </div>
    </div>
  </template>
  
<script>
import academyService from '../services/academy.service'
import CustomInput from './CustomInput.vue'

  export default {
    name: 'ClassCreatePopup',
    components: {
      CustomInput
    },
    props: {
      isVisible: Boolean,
      onClose: Function,
      academyInfo: Object,
    },
    watch: {
      isVisible() {
        if (this.isVisible) {
          this.initData()
        }
      },
    },
    data() {
      return {
        name: null,
      }
    },
    mounted() {
      this.initData()
    },
    methods: {
      initData() {
        this.name = null
      },
      async onCreate() {
        if (!this.name) { this.$toast.error("반 이름을 입력해주세요") }
        else {
          try {
            await academyService.createAcademyClass({
              academy_info: this.academyInfo.id,
              name: this.name,
            })

            this.$toast.success('반을 만들었습니다')
            this.onClose()
          } catch (e) {
            console.log(e);
          }
        }
      },
    },
  }
  </script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    min-width: 750px;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0px 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      padding: 24px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .body-item {
        display: flex;
        height: 40px;
        min-width: calc(50% - 12px);
        p {
          display: flex;
          margin-right: 24px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          color: rgb(51, 51, 51);
          align-items: center;
          width: 70px;
        }
        input {
          flex: 1;
        }
        button {
          padding: 9px 12px;
        }
        .year-list {
          flex: 1;
          display: flex;
          gap: 10px;
          select {
            height: 100%;
            flex: 1;
            max-width: 90px;
            opacity: 0;
            &.visible { 
              opacity: 1;
            }
          }
        }
        .include-list {
          flex: 1;
          display: flex;
          gap: 10px;
        }
      }
    }
    .btn-list {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        padding: 24px;
        &>button {
          align-items: center;
          justify-content: center;
          display: flex;
          cursor: pointer;
          width: 150px;
          height: 40px;
          border-radius: 4px;
          transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
          background-color: #008ED5;
          color: white;
          font-weight: 500;
          line-height: 1;
        }
    }
  }
}
</style>
  