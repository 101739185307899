<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        엑셀로 등록하기

        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <div class="body-section-title">엑셀 필요 형식</div>

        <div class="body-img">
          <img src="@/assets/images/add-user-excel-sample.png" alt=""/>
        </div>

        <div class="body-section-title">엑셀 등록 (* 초기 비밀번호 : 123456)</div>

        <div class="body-tr">
          <div class="body-item">
            <p style="white-space: nowrap">파일 등록</p>
            <FileInput
                accept=".xlsx, .xls, .csv"
                :onChange="(file) => this.excelFile = file"
            />
          </div>
        </div>
      </div>

      <div class="btn-list">
          <button @click="onSubmit">엑셀 등록하기</button>
      </div>
    </div>
  </div>

  <LoadingDialog
      :is-show="this.isLoading"
  />
</template>
  
<script>
import CustomButton from './CustomButton.vue'
import CustomInput from './CustomInput.vue'
import CustomTextarea from './CustomTextarea.vue'
import FileInput from "@/components/FileInput.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import authService from "@/services/auth.service";

export default {
  components: {
    LoadingDialog,
    FileInput,
    CustomInput,
    CustomButton,
    CustomTextarea
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
    academyInfoId: Number,
  },
  data() {
    return {
      excelFile: null,
      isLoading: false,
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      if (!this.academyInfoId || this.academyInfoId < 0) { this.$toast.error('학원 정보를 불러올 수 없습니다'); return; }
      if (!this.excelFile) { this.$toast.error('엑셀 파일을 등록해주세요'); return; }

      this.isLoading = true;

      try {
        await authService.addUsersByExcel({
          academyInfoId: this.academyInfoId,
          excelFile: this.excelFile,
        });
        this.$toast.success('등록되었습니다');
        this.onClose();
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: 750px;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 24px;
      overflow-y: auto;
      .body-section-title {
        width: 100%;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(232, 232, 232);
        color: rgb(112, 112, 112);
        text-align: start;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      .body-tr {
        width: 100%;
        display: flex;
        gap: 24px;
        padding: 0 24px;
        .body-item {
          max-width: 50%;
          width: 100%;
          display: flex;
          &.item-one {
            max-width: 100%;
          }
          p {
            display: flex;
            margin-right: 12px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            align-items: center;
            width: 100px;
          }
          input, textarea {
            flex: 1;
          }
          textarea {
            min-height: 100px;
          }
          button {
            padding: 9px 12px;
          }
          .year-list {
            flex: 1;
            display: flex;
            gap: 10px;
            select {
              height: 100%;
              flex: 1;
              max-width: 90px;
              opacity: 0;
              &.visible {
                opacity: 1;
              }
            }
          }
          .include-list {
            flex: 1;
            display: flex;
            gap: 10px;
          }
        }
      }
      .body-img {
        width: 100%;
        padding: 15px 10px;
        img {
          width: 100%;
          max-width: 550px;
          object-fit: contain;
        }
      }
    }
    .btn-list {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        padding: 24px;
        &>button {
          align-items: center;
          justify-content: center;
          display: flex;
          cursor: pointer;
          width: 150px;
          height: 40px;
          border-radius: 4px;
          transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
          background-color: #008ED5;
          color: white;
          font-weight: 500;
          line-height: 1;
        }
    }
  }
}
</style>
