<template>
  <div
      :class="['loading-box', {
        'is-absolute-box': this.isAbsolute,
      }]"
      :style="[{
        'padding': this.padding,
      }]"
  >
    <b-spinner
        :style="[{'width': this.size, 'height': this.size}]"
        :variant="this.variant"
        label="Spinning"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  props: {
    isAbsolute: { type: Boolean, default: false },
    variant: { type: String, default: 'primary' },
    size: { type: String, default: '3rem' },
    padding: { type: String, default: '0' },
  },
}
</script>

<style lang="scss" scoped>
.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  &.is-absolute-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
