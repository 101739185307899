<template>
    <label>
        <input 
            type="checkbox"
            @change="(event) => onChange ? onChange(event.target.checked) : ''"
            :checked="isChecked"
            :class="[addClass]"
            />
        <div></div>
        <span v-if="title">{{ title }}</span>
    </label>
</template>

<script>
export default {
    name: 'CustomCheckbox',
    props: {
        addClass: String,
        title: String,
        isChecked: Boolean,
        onChange: Function,
    },
}
</script>

<style lang="scss" scoped>
label {
    display: flex;
    input {
        display: none;
    }
    input + div {
        width: 15px;
        height: 15px;
        background: #FFFFFF;
        border: 1px solid #008ED5;
        border-radius: 5px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        position: relative;
        &::after {
            position: absolute;
            display: block;
            width: 10px;
            height: 4px;
            border-left: 2px solid rgb(255, 255, 255);
            border-bottom: 2px solid rgb(255, 255, 255);
            border-top-color: rgb(255, 255, 255);
            border-right-color: rgb(255, 255, 255);
            left: 2px;
            top: 3px;
            content: "";
            transform: rotate(-45deg);
        }
    }
    input:checked + div {
        background-color: #008ED5;        
        color: white;
        &::after {
            border-color: white;
        }
    }
    span {
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #000000;
    }
}
</style>