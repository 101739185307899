<template>
  <template v-if="this.$route.meta.isV2">
    <router-view/>
  </template>
  <template v-else>
    <div :class="topClassName">
      <template v-if="isVisibleMinWindowInfo && !isMypageStudent && !isMypageParents && !isMobileRequiredPages && !isRedirectStore">
        <MinWindowInfo/>
      </template>
      <template v-else>
        <template v-if="isVisibleMinWindowInfo === false || isMypageStudent || isMypageParents">
          <template v-if="isVisibleHeader || isMypageStudent || isMypageParents">
            <Header
              :isMypageTeacher="isMypageTeacher"
              :isMypageStudent="isMypageStudent"
              :isMypageParents="isMypageParents"
              />
          </template>
        </template>

        <router-view
          v-if="isVisibleRouterView"
          />

        <Footer
          v-if="isVisibleFooter && !isMypageTeacher && !isMypageStudent && !isMypageParents"
          />
      </template>
    </div>
  </template>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import MinWindowInfo from './components/MinWindowInfo.vue'
import { mapGetters } from 'vuex'
import utils from "@/utils";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    MinWindowInfo,
  },
  data() {
    return {
      isDisableWindowWidth: false,
      windowWidth: 0,
      minWindowWidth: parseInt(process.env.VUE_APP_MIN_WIDTH),
      routeName: null,
      isIgnoreWindowWidth: false,
      isHiddenHeader: false,
      isHiddenFooter: false,
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkWindowWidth);
    this.checkWindowWidth();
    this.checkHomeRoute();
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
  methods: {
    checkHomeRoute() {
      if (this.$route.name === 'home') {
        if (window.innerWidth < this.minWindowWidth) {
          if (this.isLogin) {
            if (this.isAcademyStudent || this.isAcademyParents) {
              this.$router.push({ name: 'RedirectStore' })
            } else {
              this.$router.push({ name: 'RequireAcademyVerifyInfo' })
            }
          } else if (utils.isWebview()) {
            // 모바일이고 로그인 하지 않은 상태면 로그인 페이지로 이동
            this.$router.push({ name: 'login' });
          }
        }
      }
    },
    checkWindowWidth() {
      this.windowWidth = window.innerWidth;
      this.isIgnoreWindowWidth = this.$route.meta.isIgnoreWindowWidth ?? false;
      this.isHiddenHeader = this.$route.meta.isHiddenHeader ?? false;
      this.isHiddenFooter = this.$route.meta.isHiddenFooter ?? false;
      this.isDisableWindowWidth = this.windowWidth < this.minWindowWidth;
    },
  },
  computed: {
    isVisibleRouterView() {
      if (this.isDisableWindowWidth) {
        return true
      }
      if (this.isDisableWindowWidth) {
        return false
      }
      return true
    },
    isVisibleMinWindowInfo() {
      if (this.isIgnoreWindowWidth) {
        return false
      }
      if (this.isDisableWindowWidth) {
        return true
      }
      return false
    },
    isMypageTeacher() {
      return this.$route.path.includes('/mypage/teacher')
    },
    isRedirectStore() {
      return this.$route.path.includes('/mypage/redirect_store')
    },
    isMypageStudent() {
      return this.$route.path.includes('/mypage/student')
    },
    isMypageParents() {
      return this.$route.path.includes('/mypage/parents')
    },
    isMobileRequiredPages() {
      return this.$route.path.includes('login') || this.$route.meta.requiredMobile === true
    },
    topClassName() {
      if (this.$route.meta.includeHomePublishing) {
        return 'home-publishing-wrap';
      } else {
        return '';
      }
    },
    isVisibleHeader() {
      if (this.isRedirectStore) {
        return false
      }
      if (this.isHiddenHeader) {
        return false
      }
      if (this.isIgnoreWindowWidth) {
        return true
      }
      if (!this.isDisableWindowWidth) {
        return true
      }
      return false
    },
    isVisibleFooter() {
      if (this.isRedirectStore) {
        return false;
      }
      if (this.isHiddenFooter) {
        return false
      }
      if (this.isIgnoreWindowWidth) {
        return true
      }
      if (!this.isDisableWindowWidth) {
        return true
      }
      return false
    },
    ...mapGetters('auth', [
      'isLogin', 
      'isStudent',
      'isAcademyStudent', 
      'isParents',
      'isAcademyParents',
    ]),
  },
  watch:{
    $route (to){
      this.routeName = to.name;
      this.checkWindowWidth();
      this.checkHomeRoute();
    }
  } 
}
</script>
