class Models {
    problemCountList(isWorkbook = false) {
        if (isWorkbook) {
            return ['150', '250', '350', '500']
        } else {
            return ['25', '50', '75', '100']
        }
    }

    problemSolutionType(isAddAll = false) {
        let result = ['개념형', '계산형']
        if (isAddAll) {
            result.unshift('전체')
        }
        return result
    }

    problemTypeList(isAddAll = true) {
        let result = ['객관식', '수능형', '단답형', 'OX형', '실험형']
        if (isAddAll) {
            result.unshift('전체')
        }
        return result
    }

    difficultyList() {
        return ['하', '중하', '중', '상', '최상']
    }

    schoolTypeList(isAddAll = false) {
        let result = ['초등학교', '중학교', '고등학교']
        if (isAddAll) {
            result.unshift('전체')
        }
        return result
    }

    subjectList() {
        return ['통합과학', '물리1', '물리2', '화학1', '화학2', '생명과학1', '생명과학2', '지구과학1', '지구과학2']
    }

    elementaryGradeList() {
        return Array.apply({}, Array(6)).map((_, i) => `초${i + 1}`)
    }

    middleGradeList() {
        return Array.apply({}, Array(3)).map((_, i) => `중${i + 1}`)
    }

    highGradeList() {
        return Array.apply({}, Array(3)).map((_, i) => `고${i + 1}`)
    }

    gradeList() {
        let result = this.elementaryGradeList()
        result = Array.concat(result, this.middleGradeList())
        result = Array.concat(result, this.highGradeList())
        return result
    }

    sheetTagList(isAddAll = true) {
        let result = ['신규테스트', '일일테스트', '주간테스트', '단원테스트', '모의고사', '수능대비', '취약유형', '특강', '숙제', '개념문제', '연습문제']
        if (isAddAll) {
            result.unshift('전체')
        }
        return result
    }

    bookTagList(isAddAll = true) {
        let result = ['신규테스트', '일일테스트', '주간테스트', '단원테스트', '모의고사', '수능대비', '취약유형', '특강', '숙제', '개념문제', '연습문제']
        if (isAddAll) {
            result.unshift('전체')
        }
        return result
    }
}

export default new Models()
