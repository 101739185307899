<template>
    <div>
    </div>
</template>

<script>
export default {
    name: 'CallbackNaver',
    mounted() {
        this.onInit()
    },
    methods: {
        onInit() {
            const appContext = this
            const originUrl = window.location.origin
            const naverLogin = new window.naver.LoginWithNaverId(
                {
                    clientId: process.env.VUE_APP_NAVER_CLIENT_KEY,
                    callbackUrl: originUrl + process.env.VUE_APP_NAVER_CALLBACK_URL,
                    isPopup: true,
                    callbackHandle: false
                }
            );
            naverLogin.init();

            naverLogin.getLoginStatus(function (status) {
                if (status) {
                    console.log('naver response profile - ');
                    console.log(naverLogin.user);

                    const userId = naverLogin.user.getId();
                    console.log(userId);

                    console.log(opener);
                    console.log(appContext.$parent);
                    window.opener.onNaverSocialLoginResponse({
                        'id': userId,
                    });
                    // window.close();
                } else {
                    opener.showErrorToast('네이버 로그인 중 오류가 발생했습니다\n다음에 다시 시도해주세요')
                    naverLogin.reprompt();
                    return;
                }
            })
        },
    },
}
</script>