<template>
  <div class="teacher-dashboard-wrap">
    <V2Wrapper>
      <div class="tdw-inner-wrap">
        <div class="section-1-wrap">
          <div class="v2-base-container">
            <div class="sb-left-wrap">
              <div class="ai-chat-wrap"
                   @click="() => this.isVisibleAIAssistantPopup = true"
              >
                <div class="mascot-img-wrap">
                  <img src="@/assets/images/sduck-mascot.png" alt="">
                  <span>AI 에스덕</span>
                </div>
                <div class="chat-body">
                  <img class="cb-arrow" src="@/assets/images/chat-body-arrow.png" alt=""/>
                  <div class="cb-inner-wrap">
                    <p>AI Assistant 의 메시지 :<br>선생님, 우리반 학생들은 분수의 곱셈단원을 어려워 합니다.<br>학생들의 오답상태를 확인해주세요.</p>
                  </div>
                </div>
              </div>
              <div class="sbl-bottom-wrap">
                <div class="sblb-left-wrap">
                  <div class="sblbl-tab-wrap">
                    <div class="tab-item active">이름</div>
                    <div class="tab-item">과제제출</div>
                    <div class="tab-item">학습단원</div>
                  </div>
                  <div class="sblbl-body-wrap">
                    <div class="sblbl-body-inner-wrap">
                      <template v-for="(userStatus, index) in this.userStatusItems" :key="index">
                        <div class="sblbl-body-item">
                          <div class="bl-info">
                            <div class="bli-name">
                              <span>{{ userStatus.name }}</span>
                              <div :class="[`level-${userStatus.level}`]"></div>
                            </div>
                            <div :class="['bli-homework', `level-${userStatus.homeworkLevel}`]">
                              <img v-if="userStatus.homeworkLevel === -1" src="@/assets/images/homework--1.png" alt="">
                              <img v-else-if="userStatus.homeworkLevel === 0" src="@/assets/images/homework-0.png" alt="">
                              <img v-else src="@/assets/images/homework-1.png" alt="">
                            </div>
                            <div class="bli-unit">
                              <img src="@/assets/images/location.png" alt="">
                              <span>{{ userStatus.learnUnit }}</span>
                            </div>
                          </div>
                          <div class="bi-line"></div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="sblb-right-wrap">
                  <h1>학습 참여도</h1>
                  <div class="learner-dist-wrap">
                    <h5>학습자 분포</h5>
                    <div class="ld-rate-wrap">
                      <div :style="[{'width': `${this.learnerDist.fastPer}%`}]">
                        <span>{{ this.learnerDist.fastCnt }}명({{ this.learnerDist.fastPer }}%)</span>
                      </div>
                      <div :style="[{'width': `${this.learnerDist.generalPer}%`}]">
                        <span>{{ this.learnerDist.generalCnt }}명({{ this.learnerDist.generalPer }}%)</span>
                      </div>
                      <div :style="[{'width': `${this.learnerDist.slowPer}%`}]">
                        <span>{{ this.learnerDist.slowCnt }}명({{ this.learnerDist.slowPer }}%)</span>
                      </div>
                    </div>
                    <div class="ld-rate-info">
                      <div class="ri-item">
                        <div></div>
                        <p>빠른 학습자</p>
                      </div>
                      <div class="ri-item">
                        <div></div>
                        <p>일반 학습자</p>
                      </div>
                      <div class="ri-item">
                        <div></div>
                        <p>느린 학습자</p>
                      </div>
                    </div>
                  </div>
                  <div class="rate-wrap">
                    <div class="r-chart-item">
                      <h5>학습 이수율</h5>
                      <div class="rc-body">
                        <div class="rcb-inner-wrap">
                          <div class="rcbi-graph-wrap">
                            <DoughnutChart
                                :chartData="this.studyCompletionRateChartData"
                                :options="this.studyCompletionRateOption"
                            />
                          </div>
                        </div>
                        <p>{{ this.learnerDist.studyCompleteRate }}%</p>
                      </div>
                    </div>
                    <div class="r-chart-item">
                      <h5>과제 제출율</h5>
                      <div class="rc-body">
                        <div class="rcb-inner-wrap">
                          <div class="rcbi-graph-wrap">
                            <DoughnutChart
                                :chartData="this.homeworkSubmitRateChartData"
                                :options="this.homeworkSubmitRateOption"
                            />
                          </div>
                        </div>
                        <p>{{ this.learnerDist.homeworkSubmitRate }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sb-right-wrap">
              <div class="sbr-tab">
                <div class="tab-item">
                  <img src="@/assets/images/diagnosis-result.png" alt="">
                  <span>진단 결과</span>
                </div>
                <div class="tab-item"
                     @click="() => this.isVisibleLearningStatisticsPopup = true"
                >
                  <img src="@/assets/images/learning-statistics.png" alt="">
                  <span>학습 통계</span>
                </div>
                <div class="tab-item">
                  <img src="@/assets/images/questions-notifications.png" alt="">
                  <span>질문&알림</span>
                </div>
              </div>
              <div class="learning-report">
                <h1>학습 리포트</h1>
                <h2>‘강기복’ 학생의 학습 현황</h2>
                <div class="lr-list-wrap">
                  <div class="list-item">
                    <div class="item-pin"></div>
                    <p>학습 이수율 : 79%</p>
                    <span>평균 80%</span>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </div>
                  <div class="list-item">
                    <div class="item-pin"></div>
                    <p>과제 제출 현황 : 10/10</p>
                    <span>평균 0개</span>
                    <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                  </div>
                  <div class="list-item">
                    <div class="item-pin"></div>
                    <p>정답률 : 85%</p>
                    <span>평균 대비 0개</span>
                    <img src="@/assets/images/arrow-top.png" alt="">
                  </div>
                  <div class="list-item">
                    <div class="item-pin"></div>
                    <p>학습 시간 : 200분 (3시간 20분)</p>
                    <span>평균 00분</span>
                    <img src="@/assets/images/arrow-bottom.png" alt="">
                  </div>
                </div>
              </div>
              <div class="learning-chart-wrap">
                <div class="inner-wrap">
                  <RadarChart
                      :chart-data="this.studyReportChartData"
                      :options="this.studyReportOption"
                      :style="[{'max-height': '100%'}]"
                  />
                </div>
                <div class="data-list">
                  <div class="list-item">
                    <img src="@/assets/images/line-1.png" alt="">
                    <span>평균</span>
                  </div>
                  <div class="list-item">
                    <img src="@/assets/images/line-2.png" alt="">
                    <span>강기복</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-2-wrap">
          <div class="v2-base-container">
            <div class="s-body-wrap">
              <TitleTooltip
                  title="평가 리포트"
                  tooltip="툴팁 내용입니다"
              />
              <div class="sb-list-wrap">
                <template v-for="(reportItem, index) in this.reportItems" :key="index">
                  <div class="list-item">
                    <div class="li-title-wrap">
                      <h5>{{ reportItem.title }}</h5>
                      <button>
                        <span>결과 보기</span>
                        <img src="@/assets/images/arrow-right-3.png" alt="">
                      </button>
                    </div>
                    <div class="li-divider"></div>
                    <div class="li-body-wrap">
                      <div class="lib-title">
                        <span>{{ reportItem.subTitle }}</span>
                      </div>
                      <div class="lib-progress-wrap">
                        <span>{{ reportItem.submitCnt }}/{{ reportItem.totalCnt }}명</span>
                        <div class="p-bar">
                          <div class="inner-bar" :style="[{'width': `${(reportItem.submitCnt / reportItem.totalCnt) * 100}%`}]"></div>
                        </div>
                      </div>
                      <div class="lib-complete-rate-wrap">
                        <p>완료율</p>
                        <span>{{ Math.floor((reportItem.submitCnt / reportItem.totalCnt) * 100 * 10) / 10 }}%</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <button :class="['s-button', {'opened': this.isOpenLearningStatus}]"
                    @click="() => this.isOpenLearningStatus = !this.isOpenLearningStatus"
            >
              <span>학생별 학습 현황 열기</span>
              <img src="@/assets/images/arrow-bottom-2.png" alt="">
            </button>
            <template v-for="(rowCnt, index) in [1, 2]" :key="index">
              <div :class="['learning-status-wrap', {'active': this.isOpenLearningStatus}]">
                <div class="ls-title-wrap">
                  <div class="lst-tab-list">
                    <template v-if="rowCnt === 2">
                      <div class="tl-item active">
                        <span>자가학습</span>
                      </div>
                      <div class="tl-item">
                        <span>단원평가</span>
                      </div>
                      <div class="tl-item">
                        <span>진단평가</span>
                      </div>
                    </template>
                  </div>
                  <div class="lst-dt-wrap">
                    <img src="@/assets/images/arrow-left-2.png" alt="">
                    <span>7월 4주차</span>
                  </div>
                </div>
                <div class="ls-table-wrap">
                  <template v-for="(s2, i2) in rowCnt === 1 ? [1] : [1, 2]" :key="i2">
                    <div class="st-item">
                      <div class="ti-tr tr-header">
                        <div class="ti-td"></div>
                        <div class="ti-td">
                          <p>일<br/>07.21</p>
                        </div>
                        <div class="ti-td">
                          <p>월<br/>07.22</p>
                        </div>
                        <div class="ti-td">
                          <p>화<br/>07.23</p>
                        </div>
                        <div class="ti-td">
                          <p>수<br/>07.24</p>
                        </div>
                        <div class="ti-td">
                          <p>목<br/>07.25</p>
                        </div>
                        <div class="ti-td">
                          <p>금<br/>07.26</p>
                        </div>
                        <div class="ti-td">
                          <p>토<br/>07.27</p>
                        </div>
                      </div>
                      <template v-for="(studentName, studentIndex) in ['학생 1', '학생 2', '학생 3']" :key="studentIndex">
                        <div class="ti-tr">
                          <div class="ti-td">
                            <img class="td-smile" src="@/assets/images/smile.png" alt="">
                            <span>{{ studentName }}</span>
                          </div>
                          <template v-for="(dayItem, dayIndex) in [1, 2, 3, 4, 5, 6, 7]" :key="dayIndex">
                            <div class="ti-td">
                              <template v-if="Math.floor(Math.random() * 2) === 0">
                                <img class="td-check-img" src="@/assets/images/close.png" alt="">
                              </template>
                              <template v-else>
                                <img class="td-check-img" src="@/assets/images/check.png" alt="">
                              </template>
                              <div class="td-hover">
                                <h5>2023.10.31</h5>
                                <div class="tdh-item">
                                  <p>문제</p>
                                  <span>14개</span>
                                </div>
                                <div class="tdh-item">
                                  <p>동영상</p>
                                  <span>0분</span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
                <div class="ls-desc-wrap">
                  <div class="d-title-wrap">
                    <div class="dt-item">
                      <img src="@/assets/images/check.png" alt="">
                      <span>학습함</span>
                    </div>
                    <div class="dt-item">
                      <img src="@/assets/images/close.png" alt="">
                      <span>학습 안함</span>
                    </div>
                  </div>
                  <p>학습 활동 기준 : 영상 시청, 단원별 문제 풀이, 단원평가<br>(진단평가는 학습에 포함되지 않습니다.)</p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="section-3-wrap">
          <div class="v2-base-container">
            <TitleTooltip
                title="대단원별 성취도 분석"
                tooltip="툴팁 내용입니다"
            />
            <div class="s-tab-list">
              <template v-for="(analysisTab, index) in this.analysisTabs" :key="index">
                <div :class="['tab-item', {'active': index === this.selectAnalysisTabIndex}]">
                  <p>{{ analysisTab }}</p>
                  <div class="ti-divider"></div>
                </div>
              </template>
            </div>
            <div class="s-body-list">
              <template v-for="(analysisItem, index) in this.analysisItems" :key="index">
                <div class="list-item"
                     @click="() => this.showAnalysisDetailId = analysisItem.id"
                >
                  <div class="li-num">
                    <p>{{ analysisItem.id }}</p>
                  </div>
                  <div class="li-title">
                    <p>{{ analysisItem.title }}</p>
                  </div>
                  <div class="li-progress-wrap">
                    <div class="pb-wrap">
                      <div class="pbw-inner" :style="[{'width': `${analysisItem.learningRate}%`}]"></div>
                    </div>
                    <p>학습 참여율</p>
                    <span>{{ analysisItem.learningRate }}%</span>
                  </div>
                  <div class="li-divider"></div>
                  <div class="li-guid-wrap">
                    <p>지도 필요학생</p>
                    <span>{{ analysisItem.needGuidCnt }}명</span>
                  </div>
                  <img class="li-arrow" src="@/assets/images/arrow-right-2.png" alt="">
                </div>
              </template>
            </div>
            <button class="s-button">
              <img src="@/assets/images/edit.png" alt="">
              <span>학습 유도하기</span>
            </button>
          </div>
        </div>
        <div class="section-3-divider"></div>
        <div class="section-4-wrap">
          <div class="v2-base-container">
            <div class="s-title-wrap">
              <TitleTooltip
                  title="학생 분포도"
                  tooltip="툴팁 내용입니다"
              />
            </div>
            <div class="s-tab-list-wrap">
              <div class="stl-inner-wrap">
                <template v-for="(studentDistChartTab, index) in this.studentDistChartTabs" :key="index">
                  <div :class="['tab-item', {'active': index === this.selectStudentDistChartTabIndex}]"
                       @click="() => this.selectStudentDistChartTabIndex = index"
                  >
                    <p>{{ studentDistChartTab }}</p>
                    <div></div>
                  </div>
                </template>
              </div>
            </div>
            <template v-if="this.selectStudentDistChartTabIndex !== 0">
              <button class="s-category-btn">
                <span>분수의 나눗셈</span>
                <img src="@/assets/images/arrow-bottom-3.png" alt="">
              </button>
            </template>
            <div class="s-chart-wrap">
              <div class="scw-inner">
                <div class="chart-title title-y">
                  <p>정답률</p>
                </div>
                <div class="chart-title title-x">
                  <p>학습량 (문제 풀이)</p>
                </div>
                <div class="chart-body">
                  <BubbleChart
                      :chart-data="this.studentDistChartData"
                      :options="this.studentDistChartOption"
                      :style="[{'aspect-ratio': '2.5375'}]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-5-wrap">
          <div class="v2-base-container">
            <div class="s-title-wrap">
              <TitleTooltip
                  title="학생 리스트"
                  tooltip="툴팁 내용입니다"
              />
              <div class="s-order-list">
                <div class="ol-item">
                  <img src="@/assets/images/order.png" alt="">
                  <span>이름 순</span>
                </div>
                <div class="ol-item">
                  <img src="@/assets/images/order.png" alt="">
                  <span>정답률</span>
                </div>
                <div class="ol-item">
                  <img src="@/assets/images/order.png" alt="">
                  <span>문제풀이 순</span>
                </div>
              </div>
            </div>
            <div class="s-body-wrap">
              <template v-for="(studentListItem, index) in this.studentListItems" :key="index">
                <div class="sb-item">
                  <img class="i-thumb" src="@/assets/images/smile-2.png" alt="">
                  <div class="i-body">
                    <div class="ib-title">
                      <p>{{ studentListItem.name }}</p>
                      <img src="@/assets/images/arrow-right-4.png" alt="">
                    </div>
                    <div class="ib-contents">
                      <div class="c-item">
                        <p>문제 풀이</p>
                        <span>{{ studentListItem.problemCnt }}개</span>
                      </div>
                      <div class="c-item">
                        <p>정답률</p>
                        <span>{{ studentListItem.answerRate }}%</span>
                      </div>
                      <div class="c-item">
                        <p>동영상 학습</p>
                        <span>{{ studentListItem.videoLearnMinute }}분</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <AIAssistantPopup
    :is-visible="this.isVisibleAIAssistantPopup"
    :on-close="() => this.isVisibleAIAssistantPopup = false"
  />
  <AnalysisDetailPopup
    :is-visible="this.showAnalysisDetailId !== null"
    :on-close="() => this.showAnalysisDetailId = null"
  />
  <LearningStatisticsPopup
    :is-visible="this.isVisibleLearningStatisticsPopup"
    :on-close="() => this.isVisibleLearningStatisticsPopup = false"
  />
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, DoughnutChart, RadarChart, BubbleChart } from 'vue-chart-3';
import TitleTooltip from "@/components/TitleTooltip.vue";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import LearningStatisticsPopup from "@/components/LearningStatisticsPopup.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'TeacherDashboard',
  components: {
    LearningStatisticsPopup,
    AnalysisDetailPopup,
    AIAssistantPopup,
    BubbleChart,
    TitleTooltip,
    V2Wrapper,
    BarChart,
    DoughnutChart,
    RadarChart,
  },
  data() {
    return {
      isVisibleLearningStatisticsPopup: false,
      isOpenLearningStatus: false,
      isVisibleAIAssistantPopup: false,
      showAnalysisDetailId: null,
      userStatusItems: [
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 4},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '라분발', level: 0, homeworkLevel: 0, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 3},
        {name: '도레미', level: 1, homeworkLevel: -1, learnUnit: 4},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
        {name: '강기복', level: -1, homeworkLevel: 1, learnUnit: 3},
      ],
      reportItems: [
        {title: '진단평가', subTitle: '소인수 분해 / 정수와 유리수', submitCnt: 1, totalCnt: 2},
        {title: 'AI 평가', subTitle: '소인수 분해 / 정수와 유리수', submitCnt: 2, totalCnt: 2},
      ],
      analysisTabs: [
        '수학',
        '과학',
        '사회1',
        '사회2',
        '중등 기초수학',
      ],
      selectAnalysisTabIndex: 0,
      analysisItems: [
        {id: 1, title: '소인수분해 / 정수와 유리수', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 2, title: '문자와 식', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 3, title: '좌표평면과 그래프', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 4, title: '기본도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 5, title: '평면도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 6, title: '입체도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 7, title: '자료의 정리와 해석', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
      ],
      studentDistChartTabs: [
        '과목 전체',
        '중등국어',
        '수학',
        '과학',
        '사회1',
        '사회2',
        '영어',
        '중등 기초수학',
        '중등 기초수학',
        '중등 기초수학',
      ],
      selectStudentDistChartTabIndex: 0,
      studentDistDatas: [
        {id: 1, name: '권현우1', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 2, name: '권현우2', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 3, name: '권현우3', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 4, name: '권현우4', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
      ],
      studentListItems: [
        {name: '학생 1', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
        {name: '학생 2', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
        {name: '학생 3', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
        {name: '학생 4', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
        {name: '학생 5', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
        {name: '학생 6', problemCnt: Math.floor(Math.random() * 101), answerRate: Math.floor(Math.random() * 101), videoLearnMinute: Math.floor(Math.random() * 101)},
      ],
    };
  },
  computed: {
    studyCompletionRateChartData() {
      const studyCompleteRate = this.learnerDist.studyCompleteRate;

      return {
        labels: [
            '이수완료자',
            '이수미완료자',
        ],
        datasets: [
          {
            data: [studyCompleteRate, 100 - studyCompleteRate],
            backgroundColor: ['#5DF0D6', '#1CD7BB'],
          },
        ],
      };
    },
    studyCompletionRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    homeworkSubmitRateChartData() {
      const homeworkSubmitRate = this.learnerDist.homeworkSubmitRate;

      return {
        labels: [
            '제출완료자',
            '제출미완료자',
        ],
        datasets: [
          {
            data: [homeworkSubmitRate, 100 - homeworkSubmitRate],
            backgroundColor: ['#FEB92E', '#FC6530'],
          },
        ],
      };
    },
    homeworkSubmitRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    studyReportChartData() {
      return {
        labels: [
          '물리',
          '화학',
          '생물',
          '지구과학',
        ],
        datasets: [
          {
            label: '강기복',
            data: [
              30,
              30,
              50,
              80,
            ],
            fill: false,
            borderColor: '#008ED5',
            backgroundColor: '#008ED5',
          },
          {
            label: '평균',
            data: [
              45,
              30,
              70,
              55,
            ],
            fill: false,
            borderColor: '#F8B633',
            backgroundColor: '#F8B633',
          },
        ],
      };
    },
    studyReportOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          r: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 7,
                family: 'Abhaya Libre',
              },
            },
            grid: {
              circular: true,
            },
          },
        },
      };
    },
    learnerDist() {
      const userStatusItems = this.userStatusItems;
      const totalCnt = userStatusItems.length;
      const fastCnt = userStatusItems.filter(e => e.level === 1).length;
      const fastPer = Math.round(((fastCnt / totalCnt) * 100 * 10)) / 10;
      const generalCnt = userStatusItems.filter(e => e.level === 0).length;
      const generalPer = Math.round(((generalCnt / totalCnt) * 100 * 10)) / 10;
      const slowCnt = userStatusItems.filter(e => e.level === -1).length;
      const slowPer = 100 - fastPer - generalPer;

      return {
        totalCnt: totalCnt,
        fastCnt: fastCnt,
        fastPer: fastPer,
        generalCnt: generalCnt,
        generalPer: generalPer,
        slowCnt: slowCnt,
        slowPer: slowPer,
        studyCompleteRate: 45,
        homeworkSubmitRate: 75,
      };
    },
    studentDistChartData() {
      const studentDistDatas = this.studentDistDatas;

      return {
        datasets: studentDistDatas.map((data) => {
          return {
            label: data.id,
            data: [
              {
                y: data.answerRate,
                x: data.problemCnt,
                r: 9,
              },
            ],
            backgroundColor: '#F9D286',
            borderColor: '#F8B633',
          };
        }),
      };
    },
    studentDistChartOption() {
      const studentDistDatas = this.studentDistDatas;

      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: function (context) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                document.body.appendChild(tooltipEl);
              }

              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.top = '50%';
                tooltipEl.style.left = '50%';
                tooltipEl.style.opacity = 0;
                tooltipEl.style.padding = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              const dataPoints = tooltipModel.dataPoints;
              if (dataPoints.length === 0) return;

              const dataPoint = dataPoints[0];
              const dataset = dataPoint.dataset;
              if (!dataset) return;

              const label = dataset.label;
              const datas = dataset.data;
              if (!label || !datas || datas.length === 0) return;

              const data = datas[0];
              if (!data) return;

              const dataId = label;
              const studentDistData = studentDistDatas.find(e => e.id === dataId);
              if (!studentDistData) return;

              tooltipEl.innerHTML = `
<div class="teacher-dashboard-sdc-tooltip-wrap">
    <div class="t-name-wrap">
        <img src="https://firebasestorage.googleapis.com/v0/b/sduck-368307.appspot.com/o/smile.png?alt=media&token=f04d26f4-87d6-4080-9cf8-adb543c3e3e6" alt="">
        <p>${studentDistData.name}</p>
    </div>
    <div class="t-divider"></div>
    <div class="t-body-wrap">
        <div class="tb-item">
            <p>문제 풀이</p>
            <span class="active">${studentDistData.problemCnt}개</span>
        </div>
        <div class="tb-item">
            <p>정답률</p>
            <span>${studentDistData.answerRate}%</span>
        </div>
        <div class="tb-item">
            <p>최근 학습</p>
            <span>${studentDistData.recentLearnDate}</span>
        </div>
    </div>
</div>
              `;

              const position = context.chart.canvas.getBoundingClientRect();
              const resPosTop = position.top + window.pageYOffset + tooltipModel.caretY;
              const resPosLeft = position.left + window.pageXOffset + tooltipModel.caretX;
              tooltipEl.style.top = tooltipModel.caretY > (position.height / 2)
                  ? `${resPosTop - tooltipEl.clientHeight}px`
                  : `${resPosTop}px`;
              tooltipEl.style.left = tooltipModel.caretX > (position.width / 2)
                  ? `${resPosLeft - tooltipEl.clientWidth}px`
                  : `${resPosLeft}px`;
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.padding = '0';
              tooltipEl.style.pointerEvents = 'none';
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value}%`;
              },
              color: '#008ED5',
            },
          },
          x: {
            min: 0,
            ticks: {
              stepSize: 15,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value} 개`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
  },
}
</script>
