<template>
  <div id="container">
    <div class="signup-wrap">
      <div class="select-type-wrap" v-if="this.selectType == null">
        <h1>회원가입</h1>

        <div class="select-list">
          <template v-if="this.isMobile == false">
            <router-link class="list-item" :to="{ name: 'signup-teacher', query: this.$route.query }">
              <img src="@/assets/images/reg-type-teacher.png" alt=""/>
              <p>선생님으로 가입</p>
            </router-link>
          </template>
          <router-link class="list-item" :to="{ name: 'signup-student', query: this.$route.query }">
            <img src="@/assets/images/reg-type-student.png" alt=""/>
            <p>학생으로 가입</p>
          </router-link>
          <router-link class="list-item" :to="{ name: 'signup-parents', query: this.$route.query }">
            <img src="@/assets/images/parents-icon.png" alt=""/>
            <p>학부모로 가입</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Signup',
    data() {
        return {
            selectType: null,
            minWindowWidth: parseInt(process.env.VUE_APP_MIN_WIDTH),
            isMobile: false,
        };
    },    
    mounted() {
        window.addEventListener("resize", this.checkWindowWidth);
        this.checkWindowWidth();
    },
    unmounted() {
        window.removeEventListener("resize", this.checkWindowWidth);
    },
    methods: {
        checkWindowWidth() {
            this.isMobile = window.innerWidth < this.minWindowWidth 
        },
    },
}
</script>

<style lang="scss" scoped>
.signup-wrap {
    width: 100%;
    .select-type-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 129px 40px 389px;
        h1 {
            font-weight: 700;
            font-size: 45px;
            line-height: 51px;
            text-align: center;
            color: #000000;
        }
        .select-list {
            display: flex;
            gap: 50px;
            justify-content: center;
            margin-top: 81px;
            .list-item {
                background: #E2F5FF;
                border: 2px solid #008ED5;
                border-radius: 20px;
                width: 250px;
                height: 250px;
                display: flex;
                gap: 25px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                }
                p {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    color: #000000;
                }
            }
        }
    }
}


@media (max-width: 500px) {
    .signup-wrap {
        .select-type-wrap {
            padding: 30px 20px;
            h1 {
                font-size: 25px;
            }
            .select-list {
                margin-top: 30px;
                flex-direction: column;
                gap: 15px;
                .list-item {
                    width: 150px;
                    height: 150px;
                    gap: 10px;
                    img {
                        width: 50px;
                        height: 50px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
