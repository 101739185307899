<template>
  <div class="student-self-learning-mode-result-wrap">
    <V2Wrapper>
      <div class="sslmrw-inner">
        <div class="v2-base-container">
          <div class="top-bg-wrap">
            <div class="sec-l-wrap">
              <div class="sl-tab-wrap">
                <template v-for="(tab, index) in this.tabs" :key="index">
                  <div :class="['t-item', {'active': this.selectTabIndex === index}]"
                       @click="() => this.selectTabIndex = index"
                  >
                    <p>{{ tab }}</p>
                    <div></div>
                  </div>
                </template>
              </div>
              <div class="sl-list-wrap">
                <template v-for="(tabItem, index) in this.tabItems" :key="index">
                  <div class="l-item">
                    <p>{{ tabItem.title }}</p>
                    <span>{{ tabItem.dt }}</span>
                    <div></div>
                  </div>
                </template>
              </div>
            </div>
            <div class="sec-r-wrap">
              <div class="sw-info-box">
                <h5>000학생</h5>
                <div class="swi-list-wrap">
                  <div class="il-item">
                    <p>단원명</p>
                    <span>소인수분해 / 정수와 유리수</span>
                  </div>
                  <div class="il-item">
                    <p>평가일</p>
                    <span>2024.07.23</span>
                  </div>
                  <div class="il-item">
                    <p>정답수 /문항수</p>
                    <span>17개/24개</span>
                  </div>
                  <div class="il-item">
                    <p>풀이시간/ 평균 풀이시간</p>
                    <span>7분 32초 / 7분 28초</span>
                  </div>
                </div>
              </div>
              <div class="sw-result-wrap">
                <div class="rw-result-box">
                  <p>진단평가 결과</p>
                  <div>
                    <span>소인수 분해 / 정수와 유리수 학습 이전에 12개 개념의 사전학습이 필요해요.</span>
                  </div>
                </div>
                <div class="rw-weak-box">
                  <p>사전 학습이 필요한 개념</p>
                  <div class="wb-list-wrap">
                    <template v-for="(weakItem, index) in this.weakItems" :key="index">
                      <div class="wb-item">
                        <div class="i-contents">
                          <span>{{ weakItem.subTitle }}</span>
                          <p>{{ weakItem.title }}</p>
                        </div>
                        <img src="@/assets/images/weak.png" alt="">
                      </div>
                    </template>
                  </div>
                </div>
                <div class="rw-map-box">
                  <p>단원 학습 맵</p>
                  <div class="mb-body-wrap">
                    <UnitLearningMap/>
                  </div>
                </div>
                <div class="rw-graph-box">
                  <p>부족한 개념 학습 현황</p>
                  <div class="gb-body-wrap">
                    <div class="b-graph-wrap">
                      <div class="gw-inner-wrap">
                        <DoughnutChart
                            :chartData="this.studyCompletionRateChartData"
                            :options="this.studyCompletionRateChartOption"
                            :style="[{'aspect-ratio': '1'}]"
                        />
                      </div>
                      <div class="gw-contents">
                        <div class="gwc-item">
                          <div class="i-icon"
                               :style="[{'background-color': '#008ED5'}]"
                          ></div>
                          <p>학습 완료</p>
                          <span :style="[{'color': '#008ED5'}]">4개</span>
                        </div>
                        <div class="gwc-item">
                          <div class="i-icon"
                               :style="[{'background-color': '#FF6854'}]"
                          ></div>
                          <p>학습 미완료</p>
                          <span :style="[{'color': '#FF6854'}]">5개</span>
                        </div>
                      </div>
                    </div>
                    <div class="b-divider"></div>
                    <div class="b-contents-wrap">
                      <p>미완료 개념</p>
                      <div class="bc-items-wrap">
                        <div class="i-item">
                          <div></div>
                          <p>덧셈과 뺄셈</p>
                        </div>
                        <div class="i-item">
                          <div></div>
                          <p>나눗셈</p>
                        </div>
                        <div class="i-item">
                          <div></div>
                          <p>덧셈과 뺄셈</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import {Chart, registerables} from "chart.js";
import {DoughnutChart} from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import UnitLearningMap from "@/components/UnitLearningMap.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'StudentSelfLearningModeResult',
  components: {
    UnitLearningMap,
    V2Wrapper,
    DoughnutChart,
  },
  data() {
    return {
      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,
      tabItems: [
        {title: '정수와 유리수', dt: '2024.08.15'},
        {title: '정수와 유리수', dt: '2024.08.15'},
      ],
      weakItems: [
        {subTitle: '초등 3학년 1학기', title: '덧셈과 뺄셈'},
        {subTitle: '초등 3학년 1학기', title: '덧셈과 뺄셈'},
        {subTitle: '초등 3학년 1학기', title: '덧셈과 뺄셈'},
        {subTitle: '초등 3학년 1학기', title: '덧셈과 뺄셈'},
      ],
    };
  },
  computed: {
    studyCompletionRateChartData() {
      return {
        labels: [
          '학습완료',
          '학습미완료',
        ],
        datasets: [
          {
            data: [4, 5],
            backgroundColor: ['#008ED5', '#FF6854'],
          },
        ],
      };
    },
    studyCompletionRateChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
  },
}
</script>
