<template>
  <div id="container">
    <div class="price-info-wrap">
      <jumbotron :title="'합리적인 가격으로 학생이 필요로 하는 문제들만 모두 여기에'" :subTitle="'선생님이 원하는 문제들로만 학습지/교재를 만들어 낼 수 있습니다.'"/>

      <div class="remaining-period-container" v-if="freeExpireDtStr || useExpireDtStr">
        <div class="period-item" v-if="freeExpireDtStr">
          <h5>무료 사용 기한 :&nbsp;</h5>
          <p :class="[{'item-over': isOverFreeExpireDtStr}]">{{ freeExpireDtStr }}</p>
        </div>

        <template v-if="useExpireDtStr">
          <div class="period-item">
            <h5>결제 사용 기한 :&nbsp;</h5>
            <p :class="[{'item-over': isOverUseExpireDtStr}]">{{ useExpireDtStr }}</p>
          </div>

          <br/>

          <div class="period-item">
            <h5>※ 추가 결제 시 <strong>사용 기한이 추가</strong>됩니다</h5>
          </div>
        </template>
      </div>

      <div class="order-container" v-if="isEnableItem">
        <h1>{{ title }}</h1>

        <h2>{{ subTitle }}</h2>

        <div class="info-box">
          <div class="box-item">
            <p>{{ productName }}</p>
            <span>{{ productPriceStr }}</span>
          </div>
          <div class="box-item" v-if="item !== 'free' && registerPrice > 0">
            <p>계정등록비</p>
            <span>{{ registerPriceStr }}</span>
          </div>
          <template v-if="isRegisterFree">
            <div class="box-item"></div>
            <div class="box-item">
              <p>얼리버드 등록비 FREE</p>
              <span>-{{ registerPriceStr }}</span>
            </div>
          </template>
        </div>

        <h5>{{ totalPriceStr }}원</h5>

        <div class="agree-form">
          <label>
            <input class="form-cb" v-model="isAgree" type="checkbox"/>
            <span>구매조건 및 결제진행 동의</span>
          </label>

          <p>
            주문할 상품의 상품명, 상품가격, 배송정보를 확인하였으며, 구매에 동의하시겠습니까?
            <br/>
            (전자상거래법 제8조 제2항)
          </p>
        </div>

        <button @click="onSubmit">{{ submitBtnTitle }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron.vue'
import { Bootpay } from '@bootpay/client-js'
import { mapActions, mapGetters } from 'vuex'
import commonService from '../services/common.service'
import utils from '../utils'
import PaymentService from '../services/payment.service'
import dateFormat from 'dateformat'

export default {
  components: { Jumbotron },
  name: 'PriceInfo',
  data() {
    return {
      item: null,
      registerFree: null,
      isAgree: false,

      paidUserCnt: null,
      paidFreeUserMaxCnt: null,
      orderId: null,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { item, register_free } = this.$route.query
      this.item = item
      this.registerFree = register_free

      if (this.item === 'free') {
        if (this.getUser.is_use_free) {
          this.$toast.error('이미 무료 체험 신청을 하였습니다')
          this.$router.push({ name: 'home' })
        }
      } else if (this.item === 'basic' && this.registerFree === '1') {
        const res = await commonService.getFreeRegisterMaxCount()
        if (res) {
          this.paidUserCnt = res.user_cnt
          this.paidFreeUserMaxCnt = res.max_count

          if (this.paidUserCnt >= this.paidFreeUserMaxCnt) {
            this.$toast.error('등록비 FREE 이벤트 선착순이 마감되었습니다')
          }
        } else {
          this.$toast.error('서버에서 등록비 이벤트 정보를 받아올 수 없습니다')
        }
      }
    },
    getOrderId() {
      return `${new Date().getTime()}${this.getUser.id}`
    },
    async requestPayment() {
      const orderId = this.getOrderId()
      this.orderId = orderId
      try {
        const response = await Bootpay.requestPayment({
          "application_id": process.env.VUE_APP_BOOTPAY_JAVASCRIPT_KEY,
          "price": this.totalPrice,
          "order_name": this.productName,
          "order_id": this.orderId,
          "pg": "페이앱",
          "method": "카드",
          "tax_free": 0,
          "user": {
            "id": this.getUser.id,
            "username": this.getUser.name,
            "phone": this.getUser.phone,
            "email": this.getUser.email
          },
          "extra": {
            "open_type": "iframe",
            "card_quota": "0,2,3",
            "escrow": false
          }
        })

        if (response.event === 'done') {

          let useMonth = 1
          if (this.item === '3-month') {
            useMonth = 3
          }

          const res = await PaymentService.requestRegister({
            use_month: useMonth,
            payment_data: {
              res: JSON.stringify(response.data),
              order_id: response.data.order_id,
              order_name: response.data.order_name,
              receipt_url: response.data.receipt_url,
              amount: response.data.price,
            },
          })

          if (res) {
            this.changeUser(res)
            this.$toast.success(`${this.productName}이(가) 개설되었습니다`)
            this.$router.push({ name: 'home' })
          }
        }
      } catch (e) {
        console.log(e)
        switch (e.event) {
          case 'cancel': // 사용자가 결제창을 닫을때 호출
            console.log(e.message);
            break
          case 'error': // 결제 승인 중 오류 발생시 호출
            this.$toast.error(`결제 승인 중 오류가 발생하였습니다\n오류 코드: ${e.error_code}`)
            break
        }
      }
    },
    async onSubmit() {
      if (!this.isAgree) {
        this.$toast.error('먼저 동의 항목에 동의 해주세요')
      } else {
        if (this.item === 'free') {
          const res = await PaymentService.requestUseFree()
          if (res) {
            this.changeUser(res)
            this.$toast.success('무료 체험 신청이 완료되었습니다')
            this.$router.push({ name: 'home' })
          }
        } else {
          this.requestPayment()
        }
      }
    },
    ...mapActions('auth', ['changeUser'])
  },
  computed: {
    isEnableItem() {
      return this.item === 'free'
          || this.item === 'basic'
          || this.item === '3-month'
    },
    title() {
      if (this.item === 'free') {
        return '10일 무료 체험 신청'
      }
      return '결제정보'
    },
    subTitle() {
      if (this.item === 'free') {
        return '신청상품'
      }
      return '주문상품'
    },
    productName() {
      if (this.item === 'free') {
        return '10일 무료 체험'
      } else if (this.item === 'basic') {
        return '에스덕 Basic'
      } else if (this.item === '3-month') {
        return '에스덕 3 Month'
      }
      return ''
    },
    productPrice() {
      if (this.item === 'free') {
        return 0
      } else if (this.item === 'basic') {
        return 98000
      } else if (this.item === '3-month') {
        return 255000
      }
      return 0
    },
    productPriceStr() {
      let monthStr = '월'
      if (this.item === '3-month') {
        monthStr = '3개월'
      }
      return `${utils.numberFormat(this.productPrice)}원 / ${monthStr}`
    },
    registerPrice() {
      if (this.item === 'free' || this.getUser.is_paid_register) {
        return 0
      }
      return 50000
    },
    registerPriceStr() {
      return `${utils.numberFormat(this.registerPrice)}원`
    },
    isRegisterFree() {
      return this.item === 'basic' && this.registerFree === '1'
          && this.paidUserCnt < this.paidFreeUserMaxCnt
          && this.registerPrice > 0
    },
    totalPrice() {
      if (this.isRegisterFree) {
        return this.productPrice
      } else {
        return this.productPrice + this.registerPrice
      }
    },
    totalPriceStr() {
      return utils.numberFormat(this.totalPrice)
    },
    submitBtnTitle() {
      if (this.item === 'free') {
        return '신청하기'
      }
      return '결제하기'
    },
    freeExpireDtStr() {
      const freeExpireDtStr = this.getUser.free_expire_dt
      if (freeExpireDtStr) {
        return dateFormat(this.getUser.free_expire_dt, 'yyyy-mm-dd HH:mm:ss')
      }
      return null
    },
    isOverFreeExpireDtStr() {
      const freeExpireDtStr = this.getUser.free_expire_dt
      const freeExpireDt = new Date(freeExpireDtStr)
      const nowDt = new Date()
      return nowDt > freeExpireDt
    },
    useExpireDtStr() {
      const useExpireDtStr = this.getUser.use_expire_dt
      if (useExpireDtStr) {
        return dateFormat(this.getUser.use_expire_dt, 'yyyy-mm-dd HH:mm:ss')
      }
      return null
    },
    isOverUseExpireDtStr() {
      const useExpireDtStr = this.getUser.use_expire_dt
      const useExpireDt = new Date(useExpireDtStr)
      const nowDt = new Date()
      return nowDt > useExpireDt
    },
    ...mapGetters('auth', ['getUser', 'isUseFree', 'isFree'])
  },
}
</script>

<style lang="scss">
.price-info-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .remaining-period-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #008ED5;
    padding: 10px;
    color: white;
    align-items: center;
    gap: 10px;
    .period-item {
      display: flex;
      align-items: center;
      h5 {
        strong {
          color: red;
          font-weight: bold;
        }
      }
      p {
        &.item-over {
          text-decoration: line-through;
          color: red;
        }
      }
    }
  }
  .order-container {
    max-width: 850px;
    margin: 95px auto 145px;
    display: flex;
    flex-direction: column;
    border: 2px solid #008ED5;
    border-radius: 15px;
    padding: 50px 75px;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 41px;
      text-align: center;
      color: #000000;
    }
    h2 {
      margin-top: 71px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 27px;
      color: #000000;
    }
    .info-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #008ED5;
      border-bottom: 1px solid #008ED5;
      padding: 20px;
      .box-item {
        width: 100%;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
      }
    }
    h5 {
      margin-top: 40px;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      text-align: right;
      color: #000000;
    }
    .agree-form {
      width: 100%;
      margin-top: 72px;
      display: flex;
      flex-direction: column;
      background: #F1F1F1;
      padding: 25px 20px;
      label {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        margin-right: auto;
        .form-cb {
          width: 15px;
          height: 15px;
          cursor: pointer;
          border: 1px solid #008ED5;
          border-radius: 3px;
          &:checked {
            background-color: #008ED5;
          }
        }
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #000000;
        }
      }
      p {
        margin-top: 23px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
    }
    button {
      margin-top: 40px;
      width: 100%;
      display: flex;
      padding: 20px 15px;
      background: #F8B633;
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }

  }
}
</style>
